import {useEffect, useState, useContext} from 'react'

import api from 'services/api/index.js'

import {UserContext} from 'contexts/user.js'

function useEventSource({url, enabled}) {
  const [eventSource, setEventSource] = useState(null)
  const {user} = useContext(UserContext)

  useEffect(() => {
    if (enabled && user) {
      const eventSource = api.eventChannel(url)
      setEventSource(eventSource)
    }
  }, [url, user, enabled])

  return eventSource
}

export default useEventSource
