import {executeRequest} from './util/request.js'

export async function askRegistration({client, body}) {
  return executeRequest({
    client,
    url: '/account/ask-registration',
    method: 'POST',
    body
  })
}

export async function askEmailAuthentication({client, body}) {
  return executeRequest({
    client,
    url: '/account/ask-email-authentication',
    method: 'POST',
    body
  })
}

export async function getAccountProfile({client, dropAuthentication = false}) {
  // Option used to avoid infinite loop when checking if user is authenticated
  if (!dropAuthentication) {
    await client.ensureAuthenticated()
  }

  return executeRequest({client, url: '/account/profile', method: 'GET', ignoreInit: dropAuthentication})
}

export async function getAccountOrganizations({client}) {
  await client.ensureAuthenticated()
  return executeRequest({client, url: '/account/organizations', method: 'GET'})
}

export async function getAccountInvitations({client, userId}) {
  await client.ensureAuthenticated()
  return executeRequest({
    url: '/account/invitations?userId=' + userId,
    method: 'GET',
    client
  })
}

export async function updateAccountProfile({client, body}) {
  await client.ensureAuthenticated()
  return executeRequest({
    client,
    url: '/account/profile',
    method: 'PUT',
    body
  })
}

export async function updateAccountProfileAvatar({client, file}) {
  await client.ensureAuthenticated()
  return executeRequest({
    client,
    url: '/account/profile/avatar',
    method: 'PUT',
    body: file
  })
}

export async function deleteAccountProfileAvatar({client}) {
  await client.ensureAuthenticated()
  return executeRequest({
    client,
    url: '/account/profile/avatar',
    method: 'DELETE'
  })
}

export async function createAccountProject({client, body}) {
  await client.ensureAuthenticated()
  return executeRequest({
    client,
    url: '/account/projects',
    method: 'POST',
    body
  })
}

export async function getAccountProjects({client}) {
  await client.ensureAuthenticated()
  return executeRequest({client, url: '/account/projects', method: 'GET'})
}
