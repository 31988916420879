import {Button} from 'antd'
import {useTranslation} from 'react-i18next'

function RetryButton(props) {
  const {t} = useTranslation()
  return (
    <Button type='primary' {...props}>
      {t('Button.retry')}
    </Button>
  )
}

export default RetryButton
