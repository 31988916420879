import {ConfigProvider, theme as antdTheme} from 'antd'
import PropTypes from 'prop-types'
import {ThemeProvider} from 'styled-components'
import theme from 'theme.js'

function StyledComponentProvider({children}) {
  const {token} = antdTheme.useToken()
  return (
    <ThemeProvider theme={{antd: token, ...theme}}>
      {children}
    </ThemeProvider>
  )
}

StyledComponentProvider.propTypes = {
  children: PropTypes.node.isRequired
}

function StyleConfigProvider({children}) {
  return (
    <ConfigProvider theme={theme}>
      <StyledComponentProvider>
        {children}
      </StyledComponentProvider>
    </ConfigProvider>
  )
}

StyleConfigProvider.propTypes = {
  children: PropTypes.node
}

export default StyleConfigProvider
