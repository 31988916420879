/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import Checkbox from 'containers/form/checkbox.js'
import Input from 'containers/form/input.js'
import PasswordInput from 'containers/form/password-input.js'
import SelectTag from 'containers/form/select-tag.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('distantPath')}
        checks={['connectivity']}
        triggerPreparation={triggerPreparation}
      >
        <Input
          isRequired
          label={t('params.url')}
          name='url'
          placeholder={t('params.urlPlaceholder')}
        />

      </ParamsStep>

      <ParamsStep
        title={t('authentication')}
        checks={['authorization']}
        triggerPreparation={triggerPreparation}
      >
        <Input
          label={t('params.username')}
          name='username'
          placeholder={t('params.username')}
        />

        <PasswordInput
          label={t('common:form.password')}
          name='password'
        />
      </ParamsStep>

      <ParamsStep
        title={t('advancedParams')}
        checks={['statusCode', 'contentType']}
        triggerPreparation={triggerPreparation}
      >
        <Checkbox
          label={t('params.disableHtmlCheck')}
          name='disableHtmlCheck'
        />

        <SelectTag
          label={t('params.allowedStatusCodes')}
          name='allowedStatusCodes'
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
