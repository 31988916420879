import {Button} from 'antd'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

function LinkButton({href, ...props}) {
  return (
    <Link to={href}>
      <Button {...props}/>
    </Link>
  )
}

LinkButton.propTypes = {
  href: PropTypes.string.isRequired
}

export default LinkButton
