import {useState, useCallback, useEffect} from 'react'

import PropTypes from 'prop-types'

function useExecutor({handler, initialData = null, execOnMount = false, onDataUpdate, onError}) {
  const [isPending, setIsPending] = useState(Boolean(execOnMount))
  const [data, setData] = useState(initialData ?? null)
  const [error, setError] = useState(null)

  const cleanup = useCallback(() => {
    setData(undefined)
    setError(null)
    setIsPending(false)
  }, [])

  const execute = useCallback(async props => {
    setIsPending(true)
    setError(null)

    try {
      const result = await handler(props)
      setData(result ?? null)
      setError(null)

      if (onDataUpdate) {
        await onDataUpdate(result)
      }
    } catch (error) {
      setData(null)
      setError(error)

      if (onError) {
        await onError(error)
      }
    }

    setIsPending(false)
  }, [handler, onDataUpdate, onError])

  useEffect(() => {
    if (execOnMount) {
      execute()
    }
  }, [execute, execOnMount])

  return {data, error, isPending, execute, cleanup}
}

useExecutor.propTypes = {
  handler: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  execOnMount: PropTypes.bool,
  onDataUpdate: PropTypes.func,
  onError: PropTypes.func
}

export default useExecutor
