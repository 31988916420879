import {useCallback, useContext, useEffect, useRef, useState} from 'react'

import {useLocation} from 'react-router-dom'

import api from 'services/api/index.js'

import ProjectContext from 'contexts/project.js'
import {UserContext} from 'contexts/user.js'

import useEventSource from 'hooks/use-event-source.js'

const {WORKSPACE_STAY_ALIVE = 10_000} = process.env

function getActiveUserWorkspace(workspaces, userId) {
  return workspaces.find(workspace => workspace.createdBy._id === userId && workspace.status === 'active')
}

export const useWorkspace = ({workspaceId, workflowId, selectWorkspaceId}) => {
  const {user} = useContext(UserContext)
  const {isUserCanEdit} = useContext(ProjectContext)

  const location = useLocation()

  const [workspace, setWorkspace] = useState(null)
  const [operations, setOperations] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isFirstRender = useRef(true)
  const intervalRef = useRef()

  const eventSource = useEventSource({
    url: `/workspaces/${workspace?._id}/events`,
    enabled: isUserCanEdit && Boolean(workspace)
  })

  useEffect(() => {
    if (!workspaceId) {
      setWorkspace(null)
      setOperations(null)
      stopInterval()
    }
  }, [workspaceId])

  useEffect(() => {
    async function handleMessage(message) {
      switch (message.type) {
        case 'ready': {
          setOperations(message.operations)
          setWorkspace(message.workspace)

          break
        }

        case 'status-updated': {
          setWorkspace(workspace => ({...workspace, status: message.status}))
          break
        }

        case 'operation:created': {
          setOperations(operations => [...operations, message.operation])
          break
        }

        case 'operation:updated': {
          const operationId = message.operation
          setOperations(
            operations => operations.map(op => op._id === operationId ? {...op, ...message.changes} : op)
          )

          break
        }

        case 'operation:deleted': {
          const operationId = message.operation
          setOperations(operations => operations.filter(op => op._id !== operationId))

          break
        }
      // No default
      }
    }

    if (eventSource) {
      eventSource.on('message', handleMessage)
    }

    return () => {
      if (eventSource) {
        eventSource.off('message', handleMessage)
      }
    }
  }, [eventSource, workspace?._id])

  const _keepWorkspaceAlive = useCallback(async () => {
    if (intervalRef.current || !isUserCanEdit) {
      return
    }

    intervalRef.current = setInterval(async () => {
      try {
        await api.keepWorkspaceAlive(workspace._id)
      } catch (error) {
        console.error('Failed to keep workspace alive:', error)
      }
    }, WORKSPACE_STAY_ALIVE)
  }, [workspace, isUserCanEdit])

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const fetchWorkspace = useCallback(async () => {
    if (!isUserCanEdit) {
      return
    }

    try {
      let workspace

      if (workspaceId) {
        workspace = await api.getWorkspace(workspaceId)
      }

      if (!workspace) {
        const workspaces = await api.getWorkflowWorkspaces(workflowId)
        workspace = getActiveUserWorkspace(workspaces, user._id)
      }

      if (workspace) {
        setWorkspace(workspace)
        const operations = await api.getOperations(workspace._id)
        setOperations(operations)

        if (workspace.status === 'active' && !workspace.users.some(({_id}) => _id === user._id)) {
          await api.joinWorkspace(workspace._id)
        }
      }
    } catch (error) {
      console.error('Failed fetch workspace:', error)
    }
  }, [workspaceId, workflowId, user, isUserCanEdit])

  const startWorkspace = useCallback(async () => {
    stopInterval()

    try {
      const workspace = await api.createWorkspace(workflowId)
      setWorkspace(workspace)
      selectWorkspaceId(workspace._id)

      const operations = await api.getOperations(workspace._id)
      setOperations(operations)
    } catch (error) {
      console.error('Failed to start workspace:', error)
    }
  }, [workflowId, selectWorkspaceId])

  const stopWorkspace = useCallback(async ({force = false}) => {
    stopInterval()

    await api.stopWorkspace(workspace._id, force)
    setWorkspace(workspace => ({...workspace, status: 'stopped'}))
  }, [workspace?._id])

  const saveAndStopWorkspace = useCallback(async ({force = false}) => {
    stopInterval()

    await api.saveWorkspaceIntoWorkflow(workspace._id)
    await api.stopWorkspace(workspace._id, force)
  }, [workspace?._id])

  const deleteWorkspace = useCallback(async () => {
    stopInterval()

    await api.deleteWorkspace(workspace._id)
    setWorkspace(null)
    setOperations(null)
    selectWorkspaceId(null)
  }, [workspace?._id, selectWorkspaceId])

  const recreateWorkspace = useCallback(async () => {
    stopInterval()

    const createdWorkspace = await api.recreateWorkspace(workspace._id)
    setWorkspace(createdWorkspace)
    selectWorkspaceId(createdWorkspace._id)

    const operations = await api.getOperations(createdWorkspace._id)
    setOperations(operations)

    selectWorkspaceId(createdWorkspace._id)
  }, [workspace?._id, selectWorkspaceId])

  // Update URL with workspaceId
  useEffect(() => {
    const currentUrl = new URL(window.location)
    const {searchParams} = currentUrl

    if (workspace?._id) {
      searchParams.set('workspaceId', workspace?._id)
    } else {
      searchParams.delete('workspaceId')
    }

    window.history.replaceState({}, '', currentUrl)
  }, [workspace?._id, location])

  // Fetch workspace on workspaceId change
  useEffect(() => {
    if (!isFirstRender.current && workspaceId) {
      stopInterval()
      fetchWorkspace()
    }
  }, [workspaceId, fetchWorkspace])

  useEffect(() => {
    if (!workspace && isFirstRender.current) {
      fetchWorkspace()
    }

    isFirstRender.current = false
  }, [workspace, fetchWorkspace, startWorkspace, _keepWorkspaceAlive])

  useEffect(() => {
    if (!workspace) {
      stopInterval()
    } else if (workspace?.status === 'active') {
      _keepWorkspaceAlive()
    }
  }, [workspaceId, workspace, _keepWorkspaceAlive])

  useEffect(() => async () => {
    stopInterval()
  }, [])

  return {
    ...workspace,
    isActive: workspace?.status === 'active',
    operations,
    eventSource,
    startWorkspace,
    saveAndStopWorkspace,
    stopWorkspace,
    deleteWorkspace,
    recreateWorkspace,
    isModalOpen,
    openModal: () => setIsModalOpen(true),
    closeModal: () => setIsModalOpen(false)
  }
}

export default useWorkspace
