import React, {createContext, useCallback, useMemo, useState} from 'react'

import PropTypes from 'prop-types'

import api from 'services/api/index.js'

import useAvailableOperations from 'hooks/use-available-operations.js'
import useEventSource from 'hooks/use-event-source.js'

const ProjectContext = createContext(null)

export function ProjectProvider({initialValue, children}) {
  const isUserCanEdit = useMemo(() => initialValue._permissions.includes('write'), [initialValue._permissions])

  const [project, setProject] = useState(initialValue)
  const availableOperations = useAvailableOperations(project._id)

  const eventSource = useEventSource({
    url: `/projects/${project._id}/events`,
    enabled: isUserCanEdit
  })

  const updateProject = useCallback(async changes => {
    const project = await api.updateProject(initialValue._id, changes)
    setProject(project)
  }, [initialValue._id]
  )

  const value = useMemo(() => ({
    project,
    isUserCanEdit,
    eventSource,
    availableOperations,
    updateProject
  }), [project, isUserCanEdit, eventSource, availableOperations, updateProject])

  return (
    <ProjectContext.Provider value={value}>
      {children}
    </ProjectContext.Provider>
  )
}

ProjectProvider.propTypes = {
  initialValue: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    owner: PropTypes.shape({_id: PropTypes.string.isRequired}).isRequired,
    _permissions: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
}

export default ProjectContext
