import {IdcardOutlined, EnvironmentOutlined} from '@ant-design/icons'
import {Form, Input, Button, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {getValidationMessagesHelp} from 'util/joi-validations.js'

const {Text} = Typography
function UpdateForm({
  initialValues,
  validationMessages,
  isLoading,
  onValuesChange,
  onFinish
}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'OrganizationUpdateForm'

  return (
    <Form
      layout='vertical'
      initialValues={initialValues}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name='displayName'
        label={<Text strong>{t(`${keyPrefix}.displayName`)}</Text>}
        validateStatus={validationMessages.displayName ? 'error' : ''}
        help={getValidationMessagesHelp(validationMessages, 'displayName')}
      >
        <Input
          type='text'
          placeholder={initialValues.displayName}
          prefix={<IdcardOutlined/>}
        />
      </Form.Item>

      <Form.Item
        name='location'
        label={<Text strong>{t(`${keyPrefix}.location`)}</Text>}
        validateStatus={validationMessages.location ? 'error' : ''}
        help={getValidationMessagesHelp(validationMessages, 'location')}
      >
        <Input
          type='text'
          placeholder={initialValues.location}
          prefix={<EnvironmentOutlined/>}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          {t('common:button.save')}
        </Button>
      </Form.Item>
    </Form>
  )
}

UpdateForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationMessages: PropTypes.object,
  isLoading: PropTypes.bool,
  onValuesChange: PropTypes.func,
  onFinish: PropTypes.func
}

export default UpdateForm
