import {useContext} from 'react'

import {Spin} from 'antd'
import PropTypes from 'prop-types'
import {Navigate, useLocation} from 'react-router-dom'

import {getSignInUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import CenteredContent from 'components/layouts/centered-content.js'

function RequireAuth({children}) {
  const {user, isLoading} = useContext(UserContext)

  const location = useLocation()

  if (!user && !isLoading) {
    return <Navigate replace to={getSignInUrl()} state={{from: location}}/>
  }

  if (isLoading) {
    return (
      <CenteredContent>
        <Spin/>
      </CenteredContent>
    )
  }

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired
}

export default RequireAuth
