import React, {Fragment} from 'react'

import {Divider, Flex, Space, Typography} from 'antd'
import {orderBy} from 'lodash-es'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import OperationSelectItem from 'components/display/operation-select-item.js'

const styles = {
  title: {
    margin: 0
  },
  divider: {
    margin: 0
  }
}

function OperationSelect({operations, onSelect}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.OperationsSelect'})

  return (
    <Flex vertical>
      <Space direction='vertical'>
        <Typography.Title level={5} style={styles.title}>{t('title')}</Typography.Title>
        {orderBy(operations, 'type').map(({type}, index) => (
          <Fragment key={type}>
            {index !== 0 && <Divider style={styles.divider}/>}
            <OperationSelectItem
              type={type}
              onClick={onSelect}
            />
          </Fragment>
        ))}
      </Space>
    </Flex>
  )
}

OperationSelect.propTypes = {
  operations: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default React.memo(OperationSelect)
