import React from 'react'

import {List} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import PlaceholderMessage from 'components/display/placeholder-message.js'
import ProjectListItem from 'components/display/project/project-list-item.js'

function ProjectsList({projects, isUserMember = false}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Projects.ProjectsList'})

  return (
    <List
      dataSource={projects}
      renderItem={project => <ProjectListItem key={project._id} {...project}/>}
      locale={{emptyText: (
        <PlaceholderMessage
          message={t(isUserMember ? 'noProjectPlaceholder' : 'noPublicProjectPlaceholder')}
          imgSrc='/images/project-illustration.svg'
          imgAlt={t('noFileImgAlt')}
        />
      )}}
    />
  )
}

ProjectsList.propTypes = {
  projects: PropTypes.array.isRequired,
  isUserMember: PropTypes.bool
}

export default React.memo(ProjectsList)

