import PropTypes from 'prop-types'
import {styled} from 'styled-components'

export const ContainerStyled = styled.div`
    display: flex;
    flex: auto;
    flex-direction: column;
    gap: ${({theme}) => theme.antd.margin}px;
    background: ${({$background, theme}) => $background === 'secondary' ? theme.antd.contentBgWhite : theme.antd.contentBgLight};
    box-shadow: ${({theme, $hasBoxShadow}) => $hasBoxShadow ? theme.antd.boxShadow : 'none'};
    border: ${({$isBordered, theme}) => $isBordered ? `1px solid ${theme.antd.colorBorder}` : 'none'};
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    height: 100%;
    padding: ${({theme}) => `${theme.antd.paddingXL / 2}px ${theme.antd.paddingXL}`}px;

    @media (max-width: ${({theme}) => theme.antd.screenXSMax}px) {
      padding: ${({theme}) => `${theme.antd.paddingXXS / 2}px ${theme.antd.paddingXXS}`}px;
    }

    @media (max-width: ${({theme}) => theme.antd.screenSMMax}px) {
      padding: ${({theme}) => `${theme.antd.paddingSM / 2}px ${theme.antd.paddingSM}`}px;
    }

    @media (max-width: ${({theme}) => theme.antd.screenMDMax}px) {
      padding: ${({theme}) => `${theme.antd.paddingMD / 2}px ${theme.antd.paddingMD}`}px;
    }

    @media (max-width: ${({theme}) => theme.antd.screenLGMax}px) {
      padding: ${({theme}) => `${theme.antd.paddingLG / 2}px ${theme.antd.paddingLG}`}px;
    }
`

export function Container({
  background = 'primary',
  isBordered = false,
  hasBoxShadow = false,
  children
}) {
  return (
    <ContainerStyled
      $background={background}
      $isBordered={isBordered}
      $hasBoxShadow={hasBoxShadow}
    >
      {children}
    </ContainerStyled>
  )
}

Container.propTypes = {
  background: PropTypes.oneOf(['primary', 'secondary']),
  isBordered: PropTypes.bool,
  hasBoxShadow: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Container
