import {useContext, useState} from 'react'

import {ReactFlowProvider} from 'reactflow'

import OperationsContext from 'contexts/operations-context.js'
import WorkflowContext from 'contexts/workflow-context.js'

import ReactFlowContainer from 'containers/react-flow/react-flow-container.js'

function ReactFlowWrapper() {
  const {operations, workspace, currentWorkspaceId} = useContext(WorkflowContext)
  const {selectedOperationId} = useContext(OperationsContext)

  const [isVerticalLayout, setIsVerticalLayout] = useState(true)

  return (
    <ReactFlowProvider>
      <ReactFlowContainer
        operations={operations}
        currentWorkspaceId={currentWorkspaceId}
        isOperationsLoaded={!currentWorkspaceId || Boolean(workspace.operations)}
        selectedOperationId={selectedOperationId}
        isVerticalLayout={isVerticalLayout}
        toggleLayout={() => setIsVerticalLayout(!isVerticalLayout)}
      />
    </ReactFlowProvider>
  )
}

export default ReactFlowWrapper
