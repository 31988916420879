
import {useState} from 'react'

import {DeleteOutlined} from '@ant-design/icons'
import {message, Button, Flex, Divider} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData, useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {getHomeUrl} from 'helpers/url.js'

import {getValidationMessages} from 'util/joi-validations.js'

import UpdateForm from 'containers/organization/update-form.js'
import DangerZone from 'containers/ui/danger-zone.js'
import AvatarUpload from 'containers/user/avatar-upload.js'

export async function organizationSettingsLoader({params}) {
  const [organization, invitations] = await Promise.all([
    api.getOrganization(params.organizationId),
    api.getOrganizationInvitations(params.organizationId)
  ])

  await Promise.all(
    invitations.map(async invitation => {
      const user = await api.getUser(invitation.user)
      invitation.user = user
    })
  )

  return {organization, invitations}
}

function OrganizationSettings() {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'OrganizationSettings'

  const data = useLoaderData()
  const navigate = useNavigate()

  const [messageApi, contextHolder] = message.useMessage()

  const [organization, setOrganization] = useState(data.organization)
  const [isLoading, setIsLoading] = useState(false)
  const [validationMessages, setValidationMessages] = useState({})

  const updateOrganization = async values => {
    setIsLoading(true)
    setValidationMessages({})
    try {
      const updatedOrganization = await api.updateOrganization(organization._id, values)
      setOrganization(updatedOrganization)
      messageApi.success(t(`${keyPrefix}.updateSucceded`))
    } catch (error) {
      const validationMessages = getValidationMessages(error)
      if (Object.keys(validationMessages).length > 0) {
        setValidationMessages(validationMessages)
      } else {
        messageApi.error(t(`${keyPrefix}.updateFailed`, {error}))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const updateAvatar = async avatar => {
    try {
      const updatedOrganization = avatar
        ? await api.updateOrganizationAvatar(organization._id, avatar)
        : await api.deleteOrganizationAvatar(organization._id)
      setOrganization(updatedOrganization)
    } catch (error) {
      messageApi.error(t(`${keyPrefix}.updateFailed`, {error}))
    }
  }

  const deleteOrganization = async () => {
    try {
      await api.deleteOrganization(organization._id)
      navigate(getHomeUrl())
    } catch (error) {
      messageApi.error(t('organizationDeleteError', {error}))
    }
  }

  return (
    <Flex vertical gap='middle'>
      {contextHolder}

      <Flex vertical gap='middle'>
        <AvatarUpload
          avatar={api.getAvatarURL(organization, 'large')}
          uploadAvatar={updateAvatar}
        />
        <div>
          <Button danger icon={<DeleteOutlined/>} onClick={() => updateAvatar()}>
            {t('common:button.remove')}
          </Button>
        </div>
      </Flex>

      <Divider/>

      <UpdateForm
        initialValues={organization}
        validationMessages={validationMessages}
        isLoading={isLoading}
        onValuesChange={() => setValidationMessages({})}
        onFinish={updateOrganization}
      />

      <Divider/>

      <DangerZone
        title={t(`${keyPrefix}.dangerZoneTitle`)}
        description={t(`${keyPrefix}.dangerZoneDescription`)}
        onConfirm={deleteOrganization}
      />
    </Flex>
  )
}

export default OrganizationSettings
