import {UserOutlined} from '@ant-design/icons'
import {Avatar, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Text = styled(Typography.Text)`
  font-size: ${({theme}) => theme.antd.fontSizeLG}px;
`

function UserAvatar({fullName, avatar}) {
  return (
    <Flex
      vertical
      align='center'
      gap='small'
    >
      <Avatar
        src={<img src={avatar || '/default-user-avatar.png'} alt='avatar'/>}
        size={80}
        icon={UserOutlined}
      />
      <Text>{fullName}</Text>
    </Flex>
  )
}

UserAvatar.propTypes = {
  fullName: PropTypes.string.isRequired,
  avatar: PropTypes.string
}

export default UserAvatar

