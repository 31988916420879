import {Descriptions, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {formatCode} from 'util/json.js'

import CodeBlock from 'components/ui/code-block.js'

function ExecutionTab({values}) {
  const {t} = useTranslation(['translation'], {keyPrefix: 'Project.OperationSider.OperationResult.ExecutionTab'})

  if (Object.keys(values).length === 0) {
    return null
  }

  const items = Object.keys(values).map(key => {
    const isDeep = typeof values[key] === 'object'

    return {
      label: key,
      contentStyle: isDeep ? {maxWidth: '1px'} : null, // Trick to prevent the content to overflow
      children: isDeep
        ? (
          <CodeBlock
            code={formatCode(JSON.stringify(values[key]))}
            isCopyable={false}
          />
        )
        : values[key]
    }
  })

  return (
    <Space direction='vertical'>
      <Typography.Title level={5}>{t('title')}</Typography.Title>
      <Descriptions bordered column={1} size='small' items={items}/>
    </Space>
  )
}

ExecutionTab.propTypes = {
  values: PropTypes.object.isRequired
}

export default ExecutionTab
