import React, {useCallback, useContext, useMemo} from 'react'

import {Empty, message} from 'antd'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import {isOperationCompatible} from 'util/operations.js'

import OperationsContext from 'contexts/operations-context.js'
import ProjectContext from 'contexts/project.js'
import WorkflowContext from 'contexts/workflow-context.js'

import FixedFrameLayout from 'components/layouts/fixed-frame-layout.js'

import OperationSelect from 'containers/workflow-editor/operation-select.js'

function OperationsSelect() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.OperationsSelect'})

  const [messageApi, contextHolder] = message.useMessage()

  const {availableOperations} = useContext(ProjectContext)
  const {workspace} = useContext(WorkflowContext)
  const {selectedOperation, handleSelectOperation} = useContext(OperationsContext)

  const addOperation = useCallback(async newOperationType => {
    if (!workspace.isActive) {
      return workspace.openModal()
    }

    try {
      const payload = {
        type: newOperationType,
        input: selectedOperation ? selectedOperation._id : null
      }
      const newOperation = await api.createOperation(workspace._id, payload)
      handleSelectOperation(newOperation._id)
    } catch (error) {
      messageApi.error(error)
    }
  }, [workspace, selectedOperation, handleSelectOperation, messageApi])

  const compatibleOperations = useMemo(() => {
    const previousOperationType = selectedOperation ? availableOperations.find(operation => operation.type === selectedOperation.type) : null

    return availableOperations
      .filter(operation => isOperationCompatible(operation, previousOperationType))
  }, [availableOperations, selectedOperation])

  const noOperationFound = Object.keys(compatibleOperations).length === 0

  return (
    <FixedFrameLayout>
      {contextHolder}

      {noOperationFound ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('empty')}/>
      ) : (
        <OperationSelect operations={compatibleOperations} onSelect={addOperation}/>
      )}
    </FixedFrameLayout>
  )
}

export default OperationsSelect
