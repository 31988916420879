/* eslint-disable react/prop-types */

import Icon from '@ant-design/icons'
import {Tooltip} from 'antd'
import styled from 'styled-components'

const PulsingIcon = styled(Icon)`
  &.active {
    color: ${({theme}) => theme.antd.primaryColor};
    animation: pulse 1.4s infinite;
  }

  @keyframes pulse {
    0% {
      color:${({theme}) => theme.antd.primaryColor};
      transform: scale(1);
    }
    50% {
      color: ${({theme}) => theme.antd.colorPrimaryBlue};
      transform: scale(1.1);
    }
    100% {
      color:${({theme}) => theme.antd.primaryColor};
      transform: scale(1);
    }
  }

`
function UploadIndicator({title, icon, isActive}) {
  return (
    <Tooltip title={title}>
      <PulsingIcon component={icon} className={isActive ? 'active' : ''}/>
    </Tooltip>
  )
}

export default UploadIndicator
