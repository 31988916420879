import Icon, {DownOutlined, RightOutlined} from '@ant-design/icons'
import {Divider, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DropdownContainer = styled.div`
  background-color: ${({theme}) => theme.antd.contentBgLight};
  cursor: pointer;
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingXS}px;

  .header {
    padding: ${({theme}) => theme.antd.paddingXXS}px;
  }

  .icon {
    font-size: ${({theme}) => theme.antd.fontSizeLG}px;
    font-weight: ${({theme}) => theme.antd.fontWeightStrong};
  }

  .divider {
    margin: ${({theme}) => theme.antd.marginXS}px 0;
  }

  .content {
    background-color: ${({theme}) => theme.antd.contentBgWhite};
    padding: ${({theme}) => theme.antd.paddingXS}px;
    border-radius: ${({theme}) => theme.antd.borderRadius}px
  }
`

const Title = styled(Typography.Text)`
  margin: 0;
  padding: 0;
  width: 100%;
`

function DropdownWrapper({title, isOpen, toggleOpen, children}) {
  return (
    <DropdownContainer>
      <Flex
        align='center'
        gap='small'
        className='header'
        onClick={toggleOpen}
      >
        <Icon
          component={isOpen ? DownOutlined : RightOutlined}
          className='icon'
        />
        <Title>
          {title}
        </Title>
      </Flex>

      {isOpen && (
        <>
          <Divider className='divider'/>
          <div className='content'>
            {children}
          </div>
        </>
      )}
    </DropdownContainer>
  )
}

DropdownWrapper.propTypes = {
  title: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default DropdownWrapper
