import {useContext, useEffect, useState} from 'react'

import {FundProjectionScreenOutlined, TeamOutlined} from '@ant-design/icons'
import {Flex, message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import PageContext from 'contexts/page-context.js'
import {UserContext} from 'contexts/user.js'

import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'

import Meta from 'containers/layout/meta.js'
import OrganizationsList from 'containers/organizations/organizations-list.js'
import ProjectsList from 'containers/projects/projects-list.js'
import ProfileSummaryController from 'containers/user/profile-summary-controller.js'

export async function profileLoader({params}) {
  const [profileAccount, userOrganizations, projects] = await Promise.all([
    api.getUser(params.userId),
    api.getUserOrganizations(params.userId),
    api.getUserProjects(params.userId)
  ])

  const organizations = await Promise.all(userOrganizations.map(organization => api.getOrganization(organization._id)))

  return {profileAccount, organizations, projects}
}

function Profile() {
  const {t} = useTranslation('translation', {keyPrefix: 'Profile'})

  const loaderData = useLoaderData()

  const {user} = useContext(UserContext)
  const {setHeaderNavItems} = useContext(PageContext)

  const [messageApi, contextHolder] = message.useMessage()

  const isOwner = user._id === loaderData.profileAccount._id

  // Reset header nav items (to be improved)
  useEffect(() => {
    setHeaderNavItems([])
  }, [setHeaderNavItems, t])

  const [profileAccount, setProfileAccount] = useState(loaderData.profileAccount)
  const [isLoadingProfile, setIsLoadingProfile] = useState(false)

  const updateAccountProfile = async payload => {
    setIsLoadingProfile(true)
    try {
      const updatedProfileAccount = await api.updateAccountProfile(payload)
      setProfileAccount(updatedProfileAccount)
    } catch (error) {
      messageApi.error(t('profileUpdateError', {error}))
    }

    setIsLoadingProfile(false)
  }

  const updateAccountProfileAvatar = async avatar => {
    const updatedProfileAccount = avatar
      ? await api.updateAccountProfileAvatar(avatar)
      : await api.deleteAccountProfileAvatar()

    setProfileAccount(updatedProfileAccount)
  }

  return (
    <>
      <Meta title={profileAccount.fullName} description={profileAccount.bio}/>

      {contextHolder}

      <SidebarLayout
        isSidebarFirst
        main={(
          <Flex vertical gap='large'>
            <Section
              title={t('projectsSectionTitle')}
              icon={FundProjectionScreenOutlined}
            >
              <ProjectsList
                projects={loaderData.projects}
                isUserMember={isOwner}
              />
            </Section>
            <Section
              title={t('organizationsSectionTitle')}
              icon={TeamOutlined}
            >
              <OrganizationsList
                organizations={loaderData.organizations}
                userId={user._id}
              />
            </Section>
          </Flex>
        )}
        sidebar={(
          <Section>
            <ProfileSummaryController
              isEditable={isOwner}
              profileAccount={profileAccount}
              isLoading={isLoadingProfile}
              organizations={loaderData.organizations}
              updateAccountProfile={updateAccountProfile}
              updateAccountProfileAvatar={updateAccountProfileAvatar}
            />
          </Section>
        )}
      />
    </>
  )
}

export default Profile
