import React from 'react'

import {Divider, Flex} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FixedFrameLayoutStyled = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;

  .no-margin {
    margin: 0;
  }

  .content {
    flex: 1;
    padding: ${({theme}) => theme.antd.padding}px;
    overflow-y: auto;
  }

  .section {
    flex-shrink: 0;
    padding: ${({theme}) => theme.antd.padding}px;
  }
`
FixedFrameLayoutStyled.displayName = 'FixedFrameLayout'

function FixedFrameLayout({header, footer, children}) {
  return (
    <FixedFrameLayoutStyled vertical>
      {header && (
        <>
          <div className='section'>
            {header}
          </div>
          <Divider className='no-margin'/>
        </>
      )}
      <div className='content'>
        {children}
      </div>

      {footer && (
        <>
          <Divider className='no-margin'/>
          <div className='section'>
            {footer}
          </div>
        </>
      )}
    </FixedFrameLayoutStyled>
  )
}

FixedFrameLayout.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired
}

export default FixedFrameLayout
