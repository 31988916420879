import {useState, useEffect} from 'react'

import {GithubFilled, GoogleOutlined} from '@ant-design/icons'
import {Alert, Button, Flex} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'

const ThirdPartiesContainer = styled(Flex)`
    & button {
        width: 80%;
    }
`
const errorMessages = {
  'unknown-email': 'unknownEmail',
  'authentication-failed': 'authenticationFailed',
  'existing-email': 'existingEmail',
  'unexpected-error': 'unexpectedError'
}

function ThirdPartiesLogin() {
  const [errorMessage, setErrorMessage] = useState(null)
  const location = useLocation()
  const {t} = useTranslation('translation', {keyPrefix: 'SignIn'})

  useEffect(() => {
    if (location.search.includes('?error')) {
      const error = new URLSearchParams(location.search).get('error')

      setErrorMessage(errorMessages[error] || errorMessages['unexpected-error'])
    }
  }, [location.search])

  const handleClick = provider => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/${provider}/login`
  }

  return (
    <>
      <ThirdPartiesContainer vertical align='center' gap='middle'>
        <Button
          size='large'
          icon={<GoogleOutlined/>}
          onClick={() => handleClick('google')}
        >
          Google
        </Button>

        <Button
          size='large'
          icon={<GithubFilled/>}
          onClick={() => handleClick('github')}
        >
          GitHub
        </Button>
      </ThirdPartiesContainer>

      {errorMessage && (
        <Alert
          showIcon
          type='error'
          message={t(errorMessage)}
        />
      )}
    </>
  )
}

export default ThirdPartiesLogin
