/* eslint-disable react/prop-types */

import Icon from '@ant-design/icons'
import {Flex, Tag, Typography} from 'antd'
import styled from 'styled-components'
import {nodeDimensions} from 'theme.js'

const {Text} = Typography

const NodeIcon = styled(Icon)`
    font-size: ${({theme}) => theme.antd.fontSizeHeading3}px;
    background-color: ${({theme, type}) => theme.antd[`${type}Color`]};
    border-radius: 100%;
    padding: ${({theme}) => theme.antd.paddingXS}px;
    color: ${({theme}) => theme.antd.colorWhite};
`

const styles = {
  tag: {
    maxWidth: `${nodeDimensions.width - 20}px`,
    textOverflow: 'ellipsis',
    margin: 0
  }
}

function OperationInfos({type, icon, title, tags, subtitle}) {
  return (
    <>
      <Flex align='center' gap='small'>
        <NodeIcon
          type={type.split(':')[0]}
          component={icon}
        />
        <Flex vertical>
          <Text strong>{title}</Text>
          <Text type='secondary'>{subtitle}</Text>
        </Flex>
      </Flex>

      <Flex gap={4} wrap='wrap'>
        {tags.map(tag => (
          <Tag key={tag} style={styles.tag}><Text ellipsis>{tag}</Text></Tag>
        ))}
      </Flex>
    </>
  )
}

export default OperationInfos
