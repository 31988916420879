import {Empty, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

const {Paragraph, Text} = Typography

function NoParamNeeded() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Operations.NoParamNeeded'})

  return (
    <Empty description={(
      <>
        <Text strong>{t('title')}</Text>
        <Paragraph>{t('description')}</Paragraph>
      </>
    )}
    />
  )
}

export default NoParamNeeded
