import React from 'react'

import ReactDOM from 'react-dom/client' // eslint-disable-line n/file-extension-in-import

import {UserProvider} from 'contexts/user.js'

import 'i18n.js' // {"allow": ["i18n"]}

import App from './app.js'

import './index.css'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
  <React.StrictMode>
    <UserProvider>
      <App/>
    </UserProvider>
  </React.StrictMode>
)
