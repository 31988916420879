/* eslint-disable react/prop-types  */

import {useCallback, useEffect, useMemo, useState} from 'react'

import {message} from 'antd'
import {useTranslation} from 'react-i18next'

import {filesetToTree} from 'util/file-tree.js'

import PendingUploadsModal from 'components/ui/fileset-explorer/pending-uploads-modal.js'
import FilesetExplorer from 'components/ui/fileset-explorer.js'
import FullscreenModal from 'components/ui/fullscreen-modal.js'

function FilesetModal({fileset, onConfirm, onClose}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetModal'

  const [files, setFiles] = useState([])
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const [isFinalizing, setIsFinalizing] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  const hasUploads = files.some(({status, currentContext}) => status !== 'uploaded' && currentContext)

  const handleClose = useCallback(() => {
    if (hasUploads) {
      setIsWarningModalOpen(true)
    } else {
      onClose()
    }
  }, [hasUploads, onClose])

  const handleConfirmWarningModal = useCallback(() => {
    setIsWarningModalOpen(false)
    onClose()
  }, [onClose])

  const closeFileset = useCallback(async () => {
    setIsFinalizing(true)
    try {
      await onConfirm()
      onClose()
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }

    setIsFinalizing(false)
  }, [onConfirm, onClose, messageApi])

  useEffect(() => {
    function updateFiles() {
      setFiles(fileset.getFiles())
    }

    fileset.on('items:updated', updateFiles)

    fileset.on('error', error => {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    })

    setFiles(fileset.getFiles())

    return () => {
      fileset.off('items:updated', updateFiles)
    }
  }, [messageApi]) // eslint-disable-line react-hooks/exhaustive-deps

  // Prevent the user from closing or refreshing the tab while there are pending uploads
  useEffect(() => {
    function handleBeforeUnload(event) {
      if (hasUploads) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [hasUploads])

  const treeData = useMemo(() => filesetToTree(files, {cancelUpload: () => console.log('CANCEL')}), [files])

  return (
    <>
      {contextHolder}

      {isWarningModalOpen && ( // Ensure that the modal will always be on top of the main modal
        <PendingUploadsModal
          isOpen
          onConfirm={handleConfirmWarningModal}
          onClose={() => setIsWarningModalOpen(false)}
        />
      )}

      <FullscreenModal
        destroyOnClose
        open
        title={t(`${keyPrefix}.title`)}
        okText={t(`${keyPrefix}.confirmButton`)}
        cancelText={t('common:button.close')}
        okButtonProps={{
          disabled: hasUploads || files.length === 0,
          loading: isFinalizing
        }}
        onOk={closeFileset}
        onCancel={handleClose}
      >
        <FilesetExplorer
          treeData={treeData}
          addFile={fileset.addFile}
          addItems={fileset.addItems}
          removeFile={fileset.removeFile}
        />
      </FullscreenModal>
    </>
  )
}

export default FilesetModal
