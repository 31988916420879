import React from 'react'

import {HolderOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import PropTypes from 'prop-types'
import {ResizableBox} from 'react-resizable'
import styled from 'styled-components'

const ResizableBoxStyled = styled(ResizableBox)`
  z-index: 1;
  position: relative;
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  box-shadow: ${({theme}) => theme.antd.boxShadowSiderRight};
`

const SiderToggleStyled = styled.div`
  position: absolute;
  background: ${({theme}) => theme.antd.contentBgLight};
  z-index: 2;
  top: calc(50% - ${({theme}) => theme.antd.siderToggleHeight}px);
  height: ${({theme}) => theme.antd.siderToggleHeight}px;
  width: ${({theme}) => theme.antd.siderToggleHeight / 2}px;
  display: flex;
  align-items: center;
  left: -${({theme}) => theme.antd.siderToggleHeight / 2}px;
  border-radius: ${({theme}) => theme.antd.borderRadius}px 0 0 ${({theme}) => theme.antd.borderRadius}px;
  cursor: col-resize;
  transition: left ${({theme}) => theme.antd.siderAnimationDuration}ms ease-in-out;
  box-shadow: ${({theme}) => theme.antd.boxShadowSiderRight};

  .sider-toggle {
    height: 100%;
    border-radius: inherit;
  }
`

function RightPanel({maxWidth, handleResize, children}) {
  return (
    <ResizableBoxStyled
      width={400}
      height={Number.POSITIVE_INFINITY}
      axis='x'
      minConstraints={[400, Number.POSITIVE_INFINITY]}
      maxConstraints={[maxWidth, Number.POSITIVE_INFINITY]}
      handle={(
        <SiderToggleStyled>
          <Button
            type='text'
            className='sider-toggle'
            icon={<HolderOutlined/>}
          />
        </SiderToggleStyled>
      )}
      className='resizable-box'
      resizeHandles={['w']}
      onResizeStart={handleResize}
    >
      {children}
    </ResizableBoxStyled>
  )
}

RightPanel.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  handleResize: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default RightPanel
