import {union, omitBy, isEmpty, isEqual, isUndefined, keys} from 'lodash-es'

export function getDifferences(initialValues, formValues) {
  const allKeys = union(keys(initialValues), keys(formValues))
  const differences = {}

  for (const key of allKeys) {
    if (!isEqual(initialValues[key], formValues[key])) {
      differences[key] = formValues[key] === undefined ? initialValues[key] : formValues[key]
    }
  }

  return isEmpty(differences) ? null : differences
}

export function removeUnusedFields(obj) {
  return omitBy(obj, value => isUndefined(value) || value === '')
}

export function replaceEmptyStringsWithNull(obj) {
  for (const key of Object.keys(obj)) {
    if (obj[key] === '') {
      obj[key] = null
    }
  }

  return obj
}

export function haveDifferentObjects(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return true
  }

  for (const [i, element] of arr1.entries()) {
    if (!isEqual(element, arr2[i])) {
      return true
    }
  }

  return false
}

export function convertParamsToFields(params) {
  if (!params) {
    return null
  }

  return Object.entries(params).map(([key, value]) => ({name: [key], value}))
}

export function convertFieldsToParams(fields) {
  const params = {}

  for (const field of fields) {
    params[field.name] = field.value
  }

  return params
}

export function getFieldValues(fields, name) {
  const fieldValue = fields.find(field => field.name.includes(name))
  return fieldValue ? fieldValue.value : null
}

export function updateFieldValue(fields, name, value) {
  return fields.map(field => {
    if (field.name.includes(name)) {
      return {...field, value}
    }

    return field
  })
}
