/* eslint-disable react/prop-types */

import {useContext} from 'react'

import {Flex, Typography, Select, Input as InputAntd, Space, Tag} from 'antd'
import {max} from 'lodash'
import {useTranslation} from 'react-i18next'

import {getValidationMessagesForField} from 'util/joi-validations.js'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import useField from 'hooks/use-field.js'

import ValidationMessages from 'components/display/form/validation-messages.js'

const {Option} = Select

const {Text} = Typography

function findLongestStringLength(arr) {
  const lengths = arr.map(item => item.length)
  return max(lengths)
}

function Display({label, name, extensionName, archiveFormatLabel}) {
  const {t} = useTranslation('common')
  const {params, defaultValues} = useContext(OperationParamsContext)
  const archiveName = params[name] || defaultValues[name]
  const extension = params[extensionName] || defaultValues[extensionName]

  return (
    <Flex vertical>
      <Space>
        <Text>{label} :</Text>
        {archiveName
          ? <Text strong>{archiveName}</Text>
          : <Text italic>{t('notSpecified')}</Text>}
      </Space>

      <Space>
        <Text>{archiveFormatLabel} :</Text>
        <Tag>{extension}</Tag>
      </Space>
    </Flex>
  )
}

function Field({label, name, placeholder, extensions, extensionName}) {
  const {initialParams, params, error, updateParam} = useContext(OperationParamsContext)

  const {value, initialValue, hasChanged, onChange, handleSubmit} = useField({
    name,
    initialValue: params ? params[name] : initialParams[name],
    submit: updateParam
  })

  const validationMessages = hasChanged && error ? getValidationMessagesForField(name, error) : null

  const extension = useField({
    name: extensionName,
    initialValue: params ? params[extensionName] : initialParams[extensionName],
    submit: updateParam
  })

  const longestExtension = findLongestStringLength(extensions)

  return (
    <Flex vertical>
      <Text>{label}</Text>
      <InputAntd
        value={value}
        placeholder={initialValue || placeholder}
        status={validationMessages ? 'error' : 'default'}
        addonAfter={
          <Select
            name={extensionName}
            value={extension.value}
            defaultValue={extension.initialValue}
            style={{width: `${longestExtension * 12}px`}}
            onChange={extension.onChangeValue}
            onBlur={extension.handleSubmit}
          >
            {extensions.map(extension => (
              <Option key={extension} value={extension}>
                {extension}
              </Option>
            ))}
          </Select>
        }
        onChange={onChange}
        onBlur={handleSubmit}
      />
      <ValidationMessages validationMessages={validationMessages}/>
    </Flex>
  )
}

export default withEditable(Field, Display)
