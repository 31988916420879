import React, {useCallback, useContext, useMemo} from 'react'

import {ApartmentOutlined} from '@ant-design/icons'
import {Grid, List} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {getProjectWorkflowsUrl, getWorkflowUrl} from 'helpers/url.js'

import ProjectContext from 'contexts/project.js'

import PlaceholderMessage from 'components/display/placeholder-message.js'
import Section from 'components/layouts/section.js'

import WorkflowItemWrapper from 'containers/workflow/workflow-item-wrapper.js'

const MAX_WORKFLOWS = 5

function WorkflowsList({workflows, isOwner, deleteWorkflow}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkflowsList'})

  const {project} = useContext(ProjectContext)

  const navigate = useNavigate()
  const screens = Grid.useBreakpoint()

  const workflowItems = useMemo(() => workflows.slice(0, MAX_WORKFLOWS).map(workflow => (
    <WorkflowItemWrapper
      key={workflow._id}
      workflow={workflow}
      onDelete={deleteWorkflow}
    />
  )
  ), [workflows, deleteWorkflow])

  const link = workflowItems.length < workflows.length ? {
    href: getProjectWorkflowsUrl(project),
    label: t('workflowsLinkLabel')
  } : null

  const createNewWorkflow = useCallback(async () => {
    const createdWorkflow = await api.createWorkflow(project._id)

    if (createdWorkflow) {
      navigate(getWorkflowUrl(project, createdWorkflow))
    }
  }, [project, navigate])

  const addButtonProps = useMemo(() => {
    if (isOwner) {
      return {
        onAdd: createNewWorkflow,
        buttonLabel: t('add')
      }
    }

    return {}
  }, [createNewWorkflow, isOwner, t])

  return (
    <Section
      link={link}
      icon={ApartmentOutlined}
      title={t('sectionTitle')}
      {...addButtonProps}
    >
      {workflowItems.length > 0 ? (
        <List itemLayout={screens.sm ? 'horizontal' : 'vertical'}>
          {workflowItems}
        </List>
      ) : (
        <PlaceholderMessage
          message={t('noWorkflowPlaceholder')}
          imgSrc='/images/workflow-illustration.svg'
          imgAlt={t('noWorkflowImgAlt')}
          {...addButtonProps}
        />
      )}
    </Section>
  )
}

WorkflowsList.propTypes = {
  workflows: PropTypes.array.isRequired,
  isOwner: PropTypes.bool.isRequired,
  deleteWorkflow: PropTypes.func.isRequired
}

export default React.memo(WorkflowsList)
