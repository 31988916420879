import {List} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import Member from 'containers/user/organizations/member.js'

function MembersList({
  members,
  setMembers,
  isUserMember,
  organization,
  setOrganization,
  message
}) {
  const {t} = useTranslation('translation', {keyPrefix: 'OrganizationMemberList'})

  return (
    <List
      dataSource={members}
      renderItem={member => (
        <Member
          key={member._id}
          isUserMember={isUserMember}
          member={member}
          organization={organization}
          setOrganization={setOrganization}
          setMembers={setMembers}
          message={message}
          t={t}
        />
      )}
    />
  )
}

MembersList.propTypes = {
  members: PropTypes.array.isRequired,
  setMembers: PropTypes.func.isRequired,
  isUserMember: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  setOrganization: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired
}

export default MembersList
