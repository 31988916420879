/* eslint-disable react/prop-types */

import React, {useContext, useEffect, useState} from 'react'

import PropTypes from 'prop-types'

import {getValidationMessagesForField} from 'util/joi-validations.js'

import OperationParamsContext from 'contexts/operation-params-context.js'

const withEditable = (FieldComponent, DisplayComponent) => {
  function EditableComponent(props) {
    const {isEditingEnable, error, hasFormValuesChanged} = useContext(OperationParamsContext)

    const [validationMessages, setValidationMessages] = useState(null)

    useEffect(() => {
      if (error) {
        setValidationMessages(getValidationMessagesForField(props.name, error))
      } else {
        setValidationMessages(null)
      }
    }, [props.name, error])

    useEffect(() => {
      if (hasFormValuesChanged) {
        setValidationMessages(null)
      }
    }, [hasFormValuesChanged])

    if (isEditingEnable) {
      return (
        <FieldComponent
          {...props}
          validationMessages={validationMessages}
        />
      )
    }

    return <DisplayComponent {...props}/>
  }

  EditableComponent.propTypes = {
    isEditingEnable: PropTypes.bool
  }

  return EditableComponent
}

export default withEditable
