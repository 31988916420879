import {CheckCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {Tooltip, theme} from 'antd'
import PropTypes from 'prop-types'

function CheckIcon({isValid, error}) {
  const {token} = theme.useToken()

  return isValid ? (
    <CheckCircleOutlined style={{color: token.green}}/>
  ) : (
    <Tooltip title={error}>
      <ExclamationCircleOutlined style={{color: token.red}}/>
    </Tooltip>
  )
}

CheckIcon.propTypes = {
  isValid: PropTypes.bool.isRequired,
  error: PropTypes.string
}

export default CheckIcon
