/* eslint-disable react/prop-types */

import React, {useState, useCallback, useMemo} from 'react'

import {DownOutlined} from '@ant-design/icons'
import {Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import {getAllPaths, getFailedUploadPaths} from 'util/file-tree.js'

import DirectoryTree from 'components/ui/directory-tree.js'
import Actions from 'components/ui/fileset-explorer/actions.js'
import Counters, {countFoldersAndFiles} from 'components/ui/fileset-explorer/counters.js'
import DeleteModal from 'components/ui/fileset-explorer/delete-modal.js'
import FileInput from 'components/ui/fileset-explorer/file-input.js'
import RemainingProgress from 'components/ui/fileset-explorer/remaining-progress.js'
import TreeItem from 'components/ui/fileset-explorer/tree-item.js'
import UploadFailAlert from 'components/ui/fileset-explorer/upload-fail-alert.js'

import DropZone from 'containers/ui/drop-zone.js'

const {Text} = Typography

const styles = {
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%'
  }
}

const fieldNames = {
  title: 'title',
  key: 'key',
  children: 'children',
  size: 'size',
  isWaiting: 'isWaiting',
  progress: 'progress'
}

function FilesetExplorer({treeData, addFile, addItems, removeFile}) {
  const {t} = useTranslation('translation', {keyPrefix: 'FilesetExplorer'})

  const [expandedKeys, setExpandedKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [isDeleteModeOpen, setIsDeleteModeOpen] = useState(false)
  const [keysToDelete, setKeysToDelete] = useState([])

  const paths = getAllPaths(treeData)
  const isAllKeysChecked = checkedKeys.length === paths.length

  const counters = countFoldersAndFiles(treeData)

  const onConfirmDelete = useCallback(() => {
    for (const key of keysToDelete) {
      removeFile(key)
    }

    setIsDeleteModeOpen(false)
    setCheckedKeys([]) // Reset checked keys
  }, [keysToDelete, removeFile])

  const onCancelDelete = useCallback(() => {
    setIsDeleteModeOpen(false)
    setCheckedKeys([]) // Reset checked keys
  }, [])

  const onExpand = expandedKeys => {
    setExpandedKeys(expandedKeys)
  }

  const onCheckAll = () => {
    if (isAllKeysChecked) {
      setCheckedKeys([])
    } else {
      setCheckedKeys(getAllPaths((treeData)))
    }
  }

  const onCheck = checkedKeysValue => {
    setCheckedKeys(checkedKeysValue)
  }

  const onDelete = useCallback(() => {
    setKeysToDelete(checkedKeys)
    setIsDeleteModeOpen(true)
  }, [checkedKeys])

  const titleRender = useCallback(({key, ...nodeProps}) => <TreeItem key={key} {...nodeProps}/>, [])

  const failedUploadPaths = useMemo(() => getFailedUploadPaths(treeData), [treeData])

  return (
    <Flex vertical gap='middle' flex={1} style={styles.container}>
      <DeleteModal
        isOpen={isDeleteModeOpen}
        keysToDelete={keysToDelete}
        onConfirm={onConfirmDelete}
        onCancel={onCancelDelete}
      />

      <Flex vertical gap='middle'>
        <Text strong>{t('uploadTitle')}</Text>
        <Flex align='center' gap='small'>
          <FileInput addFile={addFile}/>
          <Text type='secondary'>{t('uploadTip')}</Text>
        </Flex>
      </Flex>

      <DropZone
        addFile={addFile}
        addItems={addItems}
      >
        {treeData.length > 0 && (
          <>
            <RemainingProgress treeData={treeData}/>

            <Actions
              treeData={treeData}
              isAllKeysChecked={isAllKeysChecked}
              checkedKeys={checkedKeys}
              onCheckAll={onCheckAll}
              onDelete={onDelete}
            />

            <DirectoryTree
              checkable
              selectedKeys={[]}
              expandedKeys={expandedKeys}
              switcherIcon={<DownOutlined/>}
              titleRender={titleRender}
              fieldNames={fieldNames}
              checkedKeys={checkedKeys}
              treeData={treeData}
              onExpand={onExpand}
              onCheck={onCheck}
            />

            <Flex justify='end'>
              <Counters {...counters}/>
            </Flex>
          </>
        )}
      </DropZone>

      {failedUploadPaths.length > 0 && (
        <UploadFailAlert failPaths={failedUploadPaths}/>
      )}
    </Flex>
  )
}

export default FilesetExplorer
