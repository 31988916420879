import {graphlib, layout} from 'dagre'
import {Position} from 'reactflow'
import {nodeDimensions} from 'theme.js'

import {getOperationType} from 'util/operations.js'

export const proOptions = {
  account: 'paid-pro',
  hideAttribution: true
}

const nodeTypes = {
  input: 'custom-input',
  output: 'custom-output'
}

export function createNodeFromOperation(operation, output) {
  const type = getOperationType(operation)

  return {
    id: operation._id,
    type: nodeTypes[type] || 'custom',
    data: {label: operation.type, operation, output},
    selected: false,
    hidden: true, // We hide the node until it's properly positioned
    width: nodeDimensions.width,
    height: nodeDimensions.height,
    position: {x: 0, y: 0}
  }
}

function getEdgeType(operation) {
  const {isCompleted, isFailed, status} = operation
  if (isCompleted) {
    return 'completed'
  }

  if (isFailed) {
    return 'failed'
  }

  if (['preparing', 'executing', 'pending'].includes(status)) {
    return 'processing'
  }

  return 'unavailable'
}

export function createEdgeFromOperationNode(node, parentNode) {
  const sourceId = node.data.operation.input
  const targetId = node.id

  const type = parentNode ? getEdgeType(parentNode.data.operation) : null
  const id = `${sourceId}->${targetId}`
  const markerId = `marker-end-${id}`

  return {
    id,
    type: 'custom',
    data: {type, markerId},
    source: sourceId,
    target: targetId,
    animated: type === 'processing',
    markerEnd: markerId
  }
}

export function createEdgesFromNodes(nodes) {
  const nodeIds = new Set(nodes.map(node => node.id))
  return nodes
    .filter(node => node.data?.operation?.input && nodeIds.has(node.data.operation.input))
    .map(node => {
      const parentNode = nodes.find(n => n.id === node.data.operation.input)
      return createEdgeFromOperationNode(node, parentNode)
    })
}

const positionMap = {
  T: Position.Top,
  L: Position.Left,
  R: Position.Right,
  B: Position.Bottom
}

export const getLayoutedElements = (nodes, edges, options) => {
  const g = new graphlib.Graph().setDefaultEdgeLabel(() => ({}))
  g.setGraph({
    rankdir: options.direction,
    nodesep: 100,
    ranksep: 100
  })

  for (const edge of edges) {
    g.setEdge(edge.source, edge.target)
  }

  for (const node of nodes) {
    g.setNode(node.id, {
      ...node,
      width: node?.width ?? 0,
      height: node?.height ?? 0
    })
  }

  layout(g)

  return {
    nodes: nodes.map(node => {
      const position = g.node(node.id)
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - ((node?.width ?? 0) / 2)
      const y = position.y - ((node?.height ?? 0) / 2)

      return {
        ...node,
        hidden: false,
        sourcePosition: positionMap[options.direction[1]],
        targetPosition: positionMap[options.direction[0]],
        position: {x, y}
      }
    }),
    edges
  }
}
