import {Switch} from 'antd'
import styled from 'styled-components'

const SwitchContainer = styled(Switch)`
  && {
    background: ${({theme}) => theme.antd.colorTextTertiary};
  }
`

export default SwitchContainer
