import {EditOutlined, PlayCircleOutlined} from '@ant-design/icons'
import {Button, Divider, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import SidebarSection from 'components/layouts/sidebar-section.js'
import Markdown from 'components/markdown.js'

import WorkspaceManager from 'containers/workspace-manager.js'

const WorkflowSummaryStyled = styled.div`
  padding: ${({theme}) => theme.antd.paddingMD}px;
`
WorkflowSummaryStyled.displayName = 'WorkflowSummaryStyled'

const Title = styled(Typography.Text)`
  width: fit-content;
  margin: 0;
  font-size: ${({theme}) => theme.antd.fontSizeHeading3}px;
`

function WorkflowSummary({workflow, isUserCanEdit, handleEdit}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkflowEditor'

  return (
    <WorkflowSummaryStyled>
      <Flex vertical gap='middle'>
        <Flex gap='small' justify='space-between' align='baseline'>
          <Title>{workflow.title}</Title>
          {isUserCanEdit && (
            <Button
              icon={<EditOutlined/>}
              shape='square'
              type='text'
              onClick={handleEdit}
            />
          )}
        </Flex>
        {isUserCanEdit && (
          <Flex vertical gap='small'>
            <WorkspaceManager/>
            <Button
              disabled
              icon={<PlayCircleOutlined/>}
              type='dashed'
            >
              {t(`${keyPrefix}.execute`)}
            </Button>
          </Flex>
        )}
      </Flex>

      <Divider/>

      {workflow.description ? (
        <SidebarSection title={t(`${keyPrefix}.description`)} onEdit={handleEdit}>
          <Markdown>{workflow.description}</Markdown>
        </SidebarSection>
      ) : (
        <SidebarSection
          handleAction={handleEdit}
          placeholderImage='/images/description-placeholder.svg'
          placeholderImageAlt={t(`${keyPrefix}.illustrationAlt`)}
          actionButtonLabel={t(`${keyPrefix}.addDescription`)}
        >
          <Typography.Text strong italic>{`${t(`${keyPrefix}.noDescription`)}`}</Typography.Text>
        </SidebarSection>
      )}
    </WorkflowSummaryStyled>
  )
}

WorkflowSummary.propTypes = {
  workflow: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  isUserCanEdit: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired
}

export default WorkflowSummary
