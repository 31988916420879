import mitt from 'mitt'

// Define a custom error class to identify errors that should not be retried
class FinalError extends Error {}

function withSeed(rawUrl) {
  const seed = Math.floor(Math.random() * 1_000_000_000)
  const url = new URL(rawUrl)
  url.searchParams.set('seed', seed)
  return url.toString()
}

export function createEventSource(url, options) {
  // Create an internal event emitter that will be returned to the user with some extra methods
  const internal = mitt()

  // Define the default options
  const inactivityTimeout = options?.inactivityTimeout ?? 20_000
  const maxRetryTimeout = options?.maxRetryTimeout ?? 10_000

  // Initialize connection AbortController reference
  let connectionAbortController = null

  // Initialize the internal properties
  internal.status = 'idle'
  internal.retryCount = 0
  internal.closed = false

  // Helper function to update the status and emit the status event
  function updateStatus(newStatus) {
    if (newStatus !== internal.status) {
      internal.status = newStatus
      internal.emit('status', newStatus)
    }
  }

  // Main function to create the connection
  async function createConnection() {
    // If the event source is closed, do nothing
    if (internal.closed) {
      return
    }

    // Initialize the connection timeout reference
    let timeout = null

    // Helper function to renew the connection timeout
    function renewTimeout() {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        // If the connection reaches the inactivity timeout, abort the connection
        connectionAbortController?.abort()
      }, inactivityTimeout)
    }

    try {
      // To start, we initialize the status, the connection AbortController and renew the timeout
      updateStatus('connecting')
      connectionAbortController = new AbortController()
      renewTimeout()

      // We make the request to the server
      const response = await fetch(withSeed(url), {
        ...options,
        signal: connectionAbortController.signal
      })

      // If the response has a user error code, we throw a FinalError
      if (response.status >= 400 && response.status < 500) {
        throw new FinalError(response.statusText)
      }

      // If the content type is not valid, we throw an Error
      if (response.headers.get('content-type') !== 'application/stream+json') {
        throw new Error('Invalid content type')
      }

      // Now that we have a valid response, we update the status and renew the timeout
      renewTimeout()
      updateStatus('connected')

      // Reset the retry count
      internal.retryCount = 0

      // Create the reader and the parser
      const reader = response.body.getReader()
      const parser = createJsonParser()

      // Read the stream and emit messages
      for await (const chunk of streamChunks(reader)) {
        renewTimeout()

        for (const item of parser.parse(chunk)) {
          handleMessage(item)
        }
      }

      // Flush the remaining items
      for (const item of parser.parse()) {
        handleMessage(item)
      }

      throw new Error('Connection closed')
    } catch (error) {
      if (internal.closed) {
        return
      }

      clearTimeout(timeout)

      // Ensure the connection is closed
      connectionAbortController.abort()

      // If the error is a FinalError, we update the status and emit the error
      if (error instanceof FinalError) {
        updateStatus('disconnected')
        internal.emit('error', error)
        return
      }

      // If the error is not a FinalError, we retry the connection after some seconds
      internal.retryCount += 1
      setTimeout(createConnection, Math.min(1000 * internal.retryCount, maxRetryTimeout))
    }
  }

  function handleMessage(message) {
    if (message.type === 'end-of-stream') {
      internal.close()
    } else {
      internal.emit('message', message)
    }
  }

  /* Methods */

  // Connect the event source
  internal.connect = () => {
    if (internal.closed) {
      throw new Error('Event source is closed')
    }

    createConnection()
  }

  // Close the event source
  internal.close = () => {
    if (internal.closed) {
      throw new Error('Event source is closed')
    }

    internal.closed = true
    connectionAbortController?.abort()

    updateStatus('disconnected')

    internal.all.clear()
  }

  return internal
}

// Helper function to read the stream chunks and yield them (async generator)
async function * streamChunks(reader) {
  while (true) {
    const {done, value} = await reader.read() // eslint-disable-line no-await-in-loop

    if (done) {
      break
    }

    yield value
  }
}

// Helper function to create a JSON parser
function createJsonParser() {
  const decoder = new TextDecoder('utf-8') // eslint-disable-line unicorn/text-encoding-identifier-case
  let buffer = ''

  return {
    parse(chunk) {
      buffer += decoder.decode(chunk, {stream: true})
      const lines = buffer.split('\n')

      if (chunk) {
        buffer = lines.pop()
      }

      const items = []

      for (const line of lines) {
        if (line.trim()) {
          items.push(JSON.parse(line))
        }
      }

      return items
    }
  }
}
