import PropTypes from 'prop-types'

import OperationBadge from 'components/display/operation-sider/operation-badge.js'

function computeDisplayStatus(operation) {
  const {isCompleted, isFailed, status} = operation || {}

  if (isCompleted) {
    return 'success'
  }

  if (isFailed) {
    return 'failed'
  }

  if (status === 'prepared') {
    return 'prepared'
  }

  if (status === 'editing') {
    return 'editing'
  }

  if (status === 'preparing') {
    return 'editing'
  }

  if (['pending', 'executing'].includes(status)) {
    return 'running'
  }

  return 'idle'
}

function OperationBadgeController({operation}) {
  return (
    <OperationBadge status={computeDisplayStatus(operation)}/>
  )
}

OperationBadgeController.propTypes = {
  operation: PropTypes.shape({
    isCompleted: PropTypes.bool,
    isFailed: PropTypes.bool,
    status: PropTypes.string
  }).isRequired
}

export default OperationBadgeController
