/* eslint-disable react/prop-types */

import {Modal} from 'antd'
import {useTranslation} from 'react-i18next'

function DeleteModal({isOpen, keysToDelete, onConfirm, onCancel}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetExplorer.DeleteModal'

  const count = keysToDelete.length

  return (
    <Modal
      title={t(`${keyPrefix}.title`, {count})}
      open={isOpen}
      okButtonProps={{danger: true}}
      okText={t('common:button.remove')}
      cancelText={t('common:button.cancel')}
      onOk={onConfirm}
      onCancel={onCancel}
    >
      <p>{t(`${keyPrefix}.content`, {count})}</p>
    </Modal>
  )
}

export default DeleteModal
