import {executeRequest} from './util/request.js'

export async function getOrganization({client, organizationId}) {
  return executeRequest({
    url: '/organizations/' + organizationId,
    method: 'GET',
    client
  })
}

export async function createOrganization({client, organization}) {
  return executeRequest({
    url: '/organizations',
    method: 'POST',
    body: organization,
    client
  })
}

export async function updateOrganization({client, organizationId, changes}) {
  return executeRequest({
    url: '/organizations/' + organizationId,
    method: 'PUT',
    body: changes,
    client
  })
}

export async function deleteOrganization({client, organizationId}) {
  return executeRequest({
    url: '/organizations/' + organizationId,
    method: 'DELETE',
    client
  })
}

export async function updateOrganizationAvatar({client, organizationId, file}) {
  return executeRequest({
    url: '/organizations/' + organizationId + '/avatar',
    method: 'PUT',
    body: file,
    client
  })
}

export async function deleteOrganizationAvatar({client, organizationId}) {
  await client.ensureAuthenticated()
  return executeRequest({
    client,
    url: `/organizations/${organizationId}/avatar`,
    method: 'DELETE'
  })
}

export async function createOrganizationProject({client, organizationId, project}) {
  return executeRequest({
    url: '/organizations/' + organizationId + '/projects',
    method: 'POST',
    body: project,
    client
  })
}

export async function getOrganizationProjects({client, organizationId}) {
  return executeRequest({
    url: '/organizations/' + organizationId + '/projects',
    method: 'GET',
    client
  })
}

export async function getOrganizationInvitations({client, organizationId}) {
  return executeRequest({
    url: '/organizations/' + organizationId + '/invitations',
    method: 'GET',
    client
  })
}

export async function inviteUserToOrganization({client, organizationId, userId}) {
  return executeRequest({
    url: '/organizations/' + organizationId + '/invitations/' + userId,
    method: 'POST',
    client
  })
}

export async function resendInvitation({client, invitationId}) {
  return executeRequest({
    url: '/invitations/' + invitationId + '/resend',
    method: 'POST',
    client
  })
}

export async function cancelInvitation({client, invitationId}) {
  return executeRequest({
    url: '/invitations/' + invitationId + '/cancel',
    method: 'POST',
    client
  })
}

export async function acceptInvitation({client, invitationId}) {
  return executeRequest({
    url: '/invitations/' + invitationId + '/accept',
    method: 'POST',
    client
  })
}

export async function declineInvitation({client, invitationId}) {
  return executeRequest({
    url: '/invitations/' + invitationId + '/decline',
    method: 'POST',
    client
  })
}

export async function searchUsersByEmail({client, email}) {
  const encodedEmail = encodeURIComponent(email)
  return executeRequest({
    url: '/users/search?email=' + encodedEmail,
    method: 'GET',
    client
  })
}

export async function removeUserFromOrganization({client, organizationId, userId}) {
  return executeRequest({
    url: '/organizations/' + organizationId + '/members/' + userId,
    method: 'DELETE',
    client
  })
}
