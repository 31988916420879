import {Descriptions, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

function Metadata({metadata}) {
  const {t} = useTranslation(['translation'], {keyPrefix: 'Project.Metadata'})

  const items = [
    {label: t('featuresCount'), value: metadata.featuresCount, children: metadata.featuresCount},
    {label: t('geometryType'), value: metadata.geometryType, children: metadata.geometryType},
    {label: t('layerName'), value: metadata.layerName, children: metadata.layerName}
  ]

  if (metadata.srs) {
    items.push(
      {label: t('proj'), value: metadata.srs.proj, children: metadata.srs.proj},
      {label: t('epsg'), value: metadata.srs.epsg, children: metadata.srs.epsg}
    )
  }

  return (
    <Space direction='vertical'>
      <Typography.Title level={5}>{t('title')}</Typography.Title>
      <Descriptions bordered column={1} size='small' items={items}/>
    </Space>
  )
}

Metadata.propTypes = {
  metadata: PropTypes.shape({
    featuresCount: PropTypes.number.isRequired,
    geometryType: PropTypes.number.isRequired,
    layerName: PropTypes.string.isRequired,
    srs: PropTypes.shape({
      proj: PropTypes.string.isRequired,
      epsg: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
}

export default Metadata
