import {Empty, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

const {Paragraph, Text} = Typography

function NoInputContext() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Operations.NoInputContext'})

  return (
    <Empty description={(
      <>
        <Text strong>{t('title')}</Text>
        <Paragraph>{t('description')}</Paragraph>
      </>
    )}
    />
  )
}

export default NoInputContext
