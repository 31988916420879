import {useContext, useEffect, useState} from 'react'

import api from 'services/api/index.js'

import {UserContext} from 'contexts/user.js'

const useAvailableOperations = projectId => {
  const {user} = useContext(UserContext)
  const [availableOperations, setAvailableOperations] = useState([])

  useEffect(() => {
    const fetchAvailableOperations = async () => {
      try {
        const availableOperations = await api.getAvailableOperations(projectId)
        setAvailableOperations(availableOperations)
      } catch (error) {
        console.error('Error while fetching available operations', error)
      }
    }

    fetchAvailableOperations()
  }, [projectId, user])

  return availableOperations
}

export default useAvailableOperations
