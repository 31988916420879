import {ApartmentOutlined} from '@ant-design/icons'
import {Badge, Space, theme} from 'antd'
import PropTypes from 'prop-types'

function WorkspacesActive({activeWorkspacesCount}) {
  const {token} = theme.useToken()

  return (
    <Space>
      <Badge
        count={activeWorkspacesCount}
        color={token.colorSuccess}
        size='small'
      >
        <ApartmentOutlined style={{fontSize: `${token.fontSizeXL}px`}}/>
      </Badge>
    </Space>
  )
}

WorkspacesActive.propTypes = {
  activeWorkspacesCount: PropTypes.number.isRequired
}

export default WorkspacesActive
