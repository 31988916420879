/* eslint-disable react/prop-types */

import React, {useMemo} from 'react'

import {DownOutlined, RollbackOutlined} from '@ant-design/icons'
import {Button, Dropdown, Empty, Spin, theme, Alert, Flex} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import WorkspaceSummary from 'containers/workspaces-manager/workspace-summary.js'

import WorkspaceLeftButton from './workspace-left-button.js'

const DropdownButton = styled(Dropdown.Button)`
button {
  width: 100%;
}
  `

const DropdownMenuContainer = styled.div`
  max-height: 400px;
  min-width: 300px;
  overflow-y: auto;
  padding: ${({theme}) => theme.antd.paddingXXS}px;
`

const CurrentWorflowButton = styled(Button)`
  margin: ${({theme}) => theme.antd.marginXS}px 0;
  width: 100%;
`

const SpinWrapper = styled(Flex)`
  padding: ${({theme}) => theme.antd.paddingMD}px;
`
const NO_WORKSPACE_ITEM_KEY = 'noCurrentWorkspace'

function WorkspaceSelector({
  workspace,
  workspaces,
  isWorkspacesLoading,
  error,
  getWorkflowWorkspaces,
  handleSelectWorkspace,
  hasControls,
  t
}) {
  const {token} = theme.useToken()

  const keyPrefix = 'Project.WorkspaceManager'

  const hasSelectedWorkspace = Boolean(workspace?._id)

  const styles = {
    container: {
      width: '100%'
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      backgroundColor: token.contentBgLight
    }
  }

  const items = useMemo(() => {
    if (isWorkspacesLoading) {
      return [{
        label: (
          <SpinWrapper justify='center' align='center'>
            <Spin/>
          </SpinWrapper>
        )
      }]
    }

    if (error) {
      return [{label: (
        <Alert
          showIcon
          type='error'
          message={t(`${keyPrefix}.fetchFailed`, {error})}
        />
      )}]
    }

    if (workspaces.length === 0) {
      return [{label: <Empty description={t(`${keyPrefix}.noWorkspace`)}/>}]
    }

    const items = workspaces.map(workspace => ({
      key: workspace._id,
      label: <WorkspaceSummary {...workspace}/>
    }))

    // Add back button if a workspace is selected
    if (hasSelectedWorkspace) {
      items.unshift({
        key: NO_WORKSPACE_ITEM_KEY,
        label: (
          <CurrentWorflowButton
            type='primary'
            icon={<RollbackOutlined/>}
          >
            {t(`${keyPrefix}.currentWorkspace`)}
          </CurrentWorflowButton>
        )
      })
    }

    return items
  }, [isWorkspacesLoading, hasSelectedWorkspace, workspaces, error, t])

  return (
    <DropdownButton
      icon={<DownOutlined/>}
      style={styles.container}
      type={hasSelectedWorkspace ? 'default' : 'primary'}
      menu={{
        items,
        style: styles.menu,
        onClick: ({key}) => handleSelectWorkspace(key)
      }}
      trigger='click'
      buttonsRender={([leftButton, rightButton]) => [
        <WorkspaceLeftButton
          key='leftButton'
          leftButton={leftButton}
          workspace={workspace}
          hasControls={hasControls}
          t={t}
        />,
        rightButton
      ]}
      dropdownRender={menu => (
        <DropdownMenuContainer>
          {menu}
        </DropdownMenuContainer>
      )}
      onOpenChange={getWorkflowWorkspaces}
    />
  )
}

WorkspaceSelector.propTypes = {
  hasControls: PropTypes.bool
}

export default WorkspaceSelector
