/* eslint-disable react/prop-types */

import {useEffect, useState} from 'react'

import api from 'services/api/index.js'

import FilesetViewerModal from 'components/ui/fileset-viewer-modal.js'

import FilesetCardController from 'containers/project/fileset-card-controller.js'

export function Execution({executionContext}) {
  const {fileListId} = executionContext

  const [fileList, setFileList] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    async function fetchFileList() {
      const fileLists = await api.getFileList(fileListId)
      setFileList(fileLists)
    }

    if (fileListId) {
      fetchFileList()
    }
  }, [fileListId])

  return (
    <>
      {fileList && (
        <FilesetCardController fileset={fileList} onOpen={() => setIsOpen(true)}/>
      )}

      {isOpen && (
        <FilesetViewerModal
          fileset={fileList}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}
