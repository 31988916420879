import {TwitterOutlined, MailOutlined} from '@ant-design/icons'
import {Button, Flex, Layout, Space, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import SwitchContainer from 'components/layouts/switch-container.js'

const {Text} = Typography

const FooterStyled = styled(Layout.Footer)`
  background-color: ${({theme}) => theme.antd.contentBgWhite};

  .content {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .living-data-button {
    padding: 6px 8px;

    img {
      width: ${({theme}) => theme.antd.size}px;
    }
  }

  @media (max-width: ${({theme}) => theme.antd.screenSMMax}px) {
    .content {
      justify-content: center;
      flex-wrap: wrap-reverse;
    }

    .copyright {
      text-align: center;
    }
  }
`
FooterStyled.displayName = 'Footer'

const currentYear = new Date().getFullYear()

function Footer() {
  const {t, i18n} = useTranslation()

  return (
    <FooterStyled>
      <Flex
        align='center'
        gap='small'
        className='content'
      >
        <Text className='copyright'>{t('Footer.copyright', {currentYear})}</Text>
        <Flex justify='end' align='center'>
          <Space size='middle'>
            <Button type='text' href='mailto:contact@livingdata.co' icon={<MailOutlined/>}/>
            <Button type='text' href='https://twitter.com/@_LivingData' icon={<TwitterOutlined/>}/>
            <Button
              type='text'
              href='https://livingdata.co'
              className='living-data-button'
              icon={(
                <img
                  src='/living-data-logo.png'
                  alt='LivingData Logo'
                />
              )}
            />
            <SwitchContainer
              checked={i18n.resolvedLanguage === 'en'}
              checkedChildren='EN'
              unCheckedChildren='FR'
              onChange={() => i18n.changeLanguage(i18n.resolvedLanguage === 'en' ? 'fr' : 'en')}
            />
          </Space>
        </Flex>
      </Flex>
    </FooterStyled>
  )
}

export default Footer
