import {useCallback, useEffect, useState} from 'react'

import {IdcardOutlined} from '@ant-design/icons'
import {Button, Flex, Form, Input, Space, theme, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import MarkdownTextArea from 'components/ui/markdown-text-area.js'

import Container from 'containers/layout/container.js'

const {Text} = Typography

function WorkflowSettingsForm({initialValues, onUpdate, onClose}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkflowSettings'

  const {token} = theme.useToken()

  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false)

  const [form] = Form.useForm()

  const handleUpdate = useCallback(async values => {
    const {title, description} = values
    await onUpdate({title, description: description || null})
  }, [onUpdate])

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' && !isDescriptionFocused) {
        event.preventDefault()
        form.submit()
      } else if (event.key === 'Escape') {
        event.preventDefault()
        onClose()
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [form, isDescriptionFocused, onClose])

  return (
    <Container background='secondary'>
      <Flex vertical flex={1}>
        <Form
          form={form}
          name='workflow'
          initialValues={initialValues}
          autoComplete='off'
          layout='vertical'
          onFinish={handleUpdate}
        >
          <Form.Item
            label={<Text strong>{t(`${keyPrefix}.labelTitle`)}</Text>}
            name='title'
            rules={[
              {
                required: true,
                message: t('common:form.requiredField')
              }
            ]}
          >
            <Input prefix={<IdcardOutlined/>}/>
          </Form.Item>

          <Form.Item
            label={<Text strong>{t(`${keyPrefix}.labelDescription`)}</Text>}
            name='description'
          >
            <MarkdownTextArea
              style={{height: token.textAreaHeight}}
              onFocus={() => setIsDescriptionFocused(true)}
              onBlur={() => setIsDescriptionFocused(false)}
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                {t('common:button.save')}
              </Button>
              <Button onClick={onClose}>
                {t('common:button.cancel')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Flex>
    </Container>
  )
}

WorkflowSettingsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default WorkflowSettingsForm
