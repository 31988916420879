/* eslint-disable react/prop-types */

import {useCallback, useEffect, useState} from 'react'

import {DownOutlined} from '@ant-design/icons'
import {Flex} from 'antd'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import {filesetToTree, getAllPaths} from 'util/file-tree.js'

import DirectoryTree from 'components/ui/directory-tree.js'
import Actions from 'components/ui/fileset-explorer/actions.js'
import Counters, {countFoldersAndFiles} from 'components/ui/fileset-explorer/counters.js'
import TreeItem from 'components/ui/fileset-explorer/tree-item.js'
import FullscreenModal from 'components/ui/fullscreen-modal.js'

const fieldNames = {
  title: 'title',
  key: 'key',
  downloadUrl: 'downloadUrl',
  children: 'children',
  size: 'size'
}

const styles = {
  content: {
    height: 'inherit'
  }
}

function FilesetViewerModal({fileset, onClose}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetModal'

  const [expandedKeys, setExpandedKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [files, setFiles] = useState([])

  const paths = getAllPaths(files)
  const isAllKeysChecked = checkedKeys.length === paths.length

  const onExpand = expandedKeys => {
    setExpandedKeys(expandedKeys)
  }

  const onCheckAll = () => {
    if (isAllKeysChecked) {
      setCheckedKeys([])
    } else {
      setCheckedKeys(getAllPaths((files)))
    }
  }

  const onCheck = checkedKeysValue => {
    setCheckedKeys(checkedKeysValue)
  }

  const titleRender = useCallback(({key, ...nodeProps}) => <TreeItem key={key} {...nodeProps}/>, [])

  useEffect(() => {
    async function fetchFiles() {
      const items = await api.getFileListItems(fileset._id)
      setFiles(filesetToTree(items))
    }

    fetchFiles()
  }, [fileset._id])

  return (
    <FullscreenModal
      destroyOnClose
      open
      title={t(`${keyPrefix}.title`)}
      footer={null}
      onCancel={onClose}
    >
      <Flex vertical gap='middle' style={styles.content}>
        <Actions
          treeData={files}
          isAllKeysChecked={isAllKeysChecked}
          checkedKeys={checkedKeys}
          onCheckAll={onCheckAll}
        />

        <DirectoryTree
          checkable
          selectedKeys={[]}
          expandedKeys={expandedKeys}
          switcherIcon={<DownOutlined/>}
          titleRender={titleRender}
          fieldNames={fieldNames}
          checkedKeys={checkedKeys}
          treeData={files}
          onExpand={onExpand}
          onCheck={onCheck}
        />

        <Flex justify='end'>
          <Counters {...countFoldersAndFiles(files)}/>
        </Flex>
      </Flex>
    </FullscreenModal>
  )
}

export default FilesetViewerModal
