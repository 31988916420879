/* eslint-disable react/prop-types */

import {useContext, useMemo} from 'react'

import {FundProjectionScreenOutlined, HomeOutlined, SettingOutlined, TeamOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'

import {getAccountSettingsUrl, getOrganizationsUrl, getProfileUrl, getProjectsUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import useTabsNavigation from 'hooks/use-tabs-navigation.js'

import TabsLayout from 'components/layouts/tabs-layout.js'

function UserTabsNavigation({activeTab, children}) {
  const {t} = useTranslation('translation', {keyPrefix: 'UserTabsNavigation'})

  const {user} = useContext(UserContext)

  const tabs = useMemo(() => {
    const tabs = {
      overview: {icon: HomeOutlined, url: getProfileUrl(user)},
      projects: {icon: FundProjectionScreenOutlined, url: getProjectsUrl()},
      organizations: {icon: TeamOutlined, url: getOrganizationsUrl()},
      settings: {icon: SettingOutlined, url: getAccountSettingsUrl()}
    }

    return tabs
  }, [user])

  const {tabsItems, onTabChange} = useTabsNavigation({tabs, t})

  return (
    <TabsLayout
      tabs={tabsItems}
      activeTab={activeTab}
      onChangeTab={onTabChange}
    >
      {children}
    </TabsLayout>
  )
}

export default UserTabsNavigation
