import {executeRequest} from './util/request.js'

export async function getWorkspace({client, workspaceId}) {
  return executeRequest({url: '/workspaces/' + workspaceId, method: 'GET', client})
}

export async function stopWorkspace({client, workspaceId, force = false}) {
  return executeRequest({
    url: '/workspaces/' + workspaceId + '/stop',
    method: 'POST',
    body: {force},
    client
  })
}

export async function joinWorkspace({client, workspaceId}) {
  return executeRequest({url: '/workspaces/' + workspaceId + '/join', method: 'POST', client})
}

export async function keepWorkspaceAlive({client, workspaceId}) {
  return executeRequest({url: '/workspaces/' + workspaceId + '/heartbeat', method: 'POST', client})
}

export async function saveWorkspaceIntoWorkflow({client, workspaceId}) {
  return executeRequest({url: '/workspaces/' + workspaceId + '/save-into-workflow', method: 'POST', client})
}

export async function getOperations({client, workspaceId}) {
  return executeRequest({url: '/workspaces/' + workspaceId + '/operations', method: 'GET', client})
}

export async function deleteWorkspace({client, workspaceId}) {
  return executeRequest({url: '/workspaces/' + workspaceId, method: 'DELETE', client})
}

export async function recreateWorkspace({client, workspaceId}) {
  return executeRequest({url: '/workspaces/' + workspaceId + '/recreate', method: 'POST', client})
}

export async function createOperation({client, workspaceId, operation}) {
  return executeRequest({
    url: '/workspaces/' + workspaceId + '/operations',
    method: 'POST',
    body: operation,
    client
  })
}

export async function updateWorkflowRelations({client, workspaceId, relations}) {
  return executeRequest({
    url: '/workspaces/' + workspaceId + '/update-relations',
    method: 'POST',
    body: relations,
    client
  })
}

export async function getWorkflowWorkspaces({client, workflowId}) {
  return executeRequest({url: '/workflows/' + workflowId + '/workspaces', method: 'GET', client})
}

export async function getCurrentUserWorkspaces({client}) {
  return executeRequest({url: '/workspaces', method: 'GET', client})
}

export async function createWorkspace({client, workflowId}) {
  return executeRequest({url: '/workflows/' + workflowId + '/workspaces', method: 'POST', client})
}

export async function getOperation({client, operationId}) {
  return executeRequest({
    url: '/operations/' + operationId,
    method: 'GET',
    client
  })
}

export async function updateOperation({client, operationId, changes}) {
  return executeRequest({
    url: '/operations/' + operationId,
    method: 'PUT',
    body: changes,
    client
  })
}

export async function deleteOperation({client, operationId}) {
  return executeRequest({
    url: '/operations/' + operationId,
    method: 'DELETE',
    client
  })
}

export async function prepareOperation({client, operationId}) {
  return executeRequest({
    url: '/operations/' + operationId + '/prepare',
    method: 'POST',
    client
  })
}

export async function getPreparation({client, operationId}) {
  const preparation = await executeRequest({
    url: '/operations/' + operationId + '/preparation',
    method: 'GET',
    client
  })

  if (preparation.status === 'finished') {
    client.cache.set(`${preparation.workspace}-preparation-${preparation.operationHash}`, preparation)
  }

  return preparation
}

export async function getPreparationByHash({client, workspaceId, operationHash}) {
  const cacheKey = `${workspaceId}-preparation-${operationHash}`

  if (!client.cache.has(cacheKey)) {
    const preparation = await executeRequest({
      url: '/workspaces/' + workspaceId + '/preparations/' + operationHash,
      method: 'GET',
      client
    })

    if (preparation.status === 'finished') {
      client.cache.set(cacheKey, preparation)
    }

    return preparation
  }

  return client.cache.get(cacheKey)
}

export async function executeOperation({client, operationId}) {
  return executeRequest({
    url: '/operations/' + operationId + '/execute',
    method: 'POST',
    client
  })
}

export async function getExecution({client, operationId}) {
  const execution = await executeRequest({
    url: '/operations/' + operationId + '/execution',
    method: 'GET',
    client
  })

  if (execution.status === 'finished') {
    client.cache.set(`${execution.workspace}-execution-${execution.operationHash}`, execution)
  }

  return execution
}

export async function getExecutionByHash({client, workspaceId, operationHash}) {
  const cacheKey = `${workspaceId}-execution-${operationHash}`

  if (!client.cache.has(cacheKey)) {
    const execution = await executeRequest({
      url: '/workspaces/' + workspaceId + '/executions/' + operationHash,
      method: 'GET',
      client
    })

    if (execution.status === 'finished') {
      client.cache.set(cacheKey, execution)
    }

    return execution
  }

  return client.cache.get(cacheKey)
}

export async function abortOperation({client, operationId}) {
  return executeRequest({
    url: '/operations/' + operationId + '/abort-execution',
    method: 'POST',
    client
  })
}
