/* eslint-disable react/prop-types */

import {UserOutlined, BookOutlined, DeleteOutlined, EditOutlined, EnvironmentOutlined, MailOutlined, TeamOutlined} from '@ant-design/icons'
import {Form, Button, Divider, Flex, Input, Space, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {replaceEmptyStringsWithNull} from 'util/form.js'

import OrganizationAvatarGroup from 'components/display/organizations-avatar-group.js'
import UserAvatar from 'components/display/user-avatar.js'
import Markdown from 'components/markdown.js'

import AvatarUpload from 'containers/user/avatar-upload.js'

import ProfileInfo from './profile-info.js'

const BioContainer = styled.div`
  padding: ${({theme}) => `${theme.antd.padding}px`};
  border-radius: ${({theme}) => `${theme.antd.borderRadius}px`};
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border: 1px solid ${({theme}) => theme.antd.colorBorder};
`

const {Link} = Typography

function ProfileSummary({
  organizations,
  isEditable,
  isEditing,
  handleEditing,
  updateAccountProfile,
  updateAccountProfileAvatar,
  avatar,
  ...user
}) {
  const {t} = useTranslation('common')

  const {fullName, location, email, company, bio} = user

  const startEditing = () => {
    handleEditing(true)
  }

  const stopEditing = () => {
    handleEditing(false)
  }

  const removeAvatar = async () => {
    await updateAccountProfileAvatar()
  }

  const handleSubmit = async values => {
    await updateAccountProfile(replaceEmptyStringsWithNull(values))
  }

  return (
    <Flex vertical>
      <Form
        initialValues={user}
        onFinish={handleSubmit}
      >
        <Flex vertical justify='center' gap='small'>

          {isEditing ? (
            <Flex vertical align='center' gap='small'>
              <AvatarUpload avatar={avatar} uploadAvatar={updateAccountProfileAvatar}/>
              <Form.Item
                name='fullName'
                rules={[{required: true, message: t('form.requiredField')}]}
              >
                <Input
                  type='text'
                  placeholder={fullName}
                  prefix={<UserOutlined/>}
                />
              </Form.Item>
              <Button danger icon={<DeleteOutlined/>} onClick={removeAvatar}>
                {t('common:button.remove')}
              </Button>
            </Flex>
          ) : (
            <UserAvatar fullName={fullName} avatar={avatar}/>
          )}

          {isEditable && !isEditing && (
            <Button icon={<EditOutlined/>} onClick={startEditing}>
              {t('common:button.edit')}
            </Button>
          )}
        </Flex>

        <Divider/>

        <Flex vertical gap='large'>

          <ProfileInfo
            icon={EnvironmentOutlined}
            title={t('common:bio.location')}
            isEditing={isEditing}
            value={
              isEditing ? (
                <Form.Item
                  name='location'
                >
                  <Input
                    type='text'
                    placeholder={location}
                    prefix={<EnvironmentOutlined/>}
                  />
                </Form.Item>
              ) : (
                location
              )
            }
          />

          <ProfileInfo
            icon={MailOutlined}
            title={t('common:bio.email')}
            isEditing={isEditing}
            value={
              isEditing ? (
                <Form.Item
                  name='email'
                >
                  <Input
                    disabled
                    type='email'
                    placeholder={email}
                    prefix={<MailOutlined/>}
                  />
                </Form.Item>
              ) : (
                email && (
                  <Link href={`mailto:${email}`}>{email}</Link>
                )
              )
            }
          />

          <ProfileInfo
            icon={TeamOutlined}
            title={t('common:bio.company')}
            value={
              isEditing ? (
                <Form.Item
                  name='company'
                >
                  <Input
                    type='text'
                    placeholder={company}
                    prefix={<TeamOutlined/>}
                  />
                </Form.Item>
              ) : (
                company
              )
            }
          />

          {!isEditing && (
            <ProfileInfo
              icon={TeamOutlined}
              title={t('common:bio.organizations')}
              value={organizations.length > 0 ? (
                <OrganizationAvatarGroup profiles={organizations}/>
              ) : t('common:bio.noOrganization')}
            />
          )}

          <ProfileInfo
            icon={BookOutlined}
            title={t('common:bio.bio')}
            value={
              isEditing ? (
                <Form.Item
                  name='bio'
                >
                  <Input.TextArea rows={4} placeholder={bio}/>
                </Form.Item>
              ) : (
                bio && (
                  <BioContainer>
                    <Markdown>{bio}</Markdown>
                  </BioContainer>
                ))
            }
          />
        </Flex>

        {isEditing && (
          <Form.Item>
            <Space>
              <Button onClick={stopEditing}>
                {t('common:button.cancel')}
              </Button>

              <Button type='primary' htmlType='submit'>
                {t('common:button.save')}
              </Button>
            </Space>
          </Form.Item>
        )}
      </Form>
    </Flex>
  )
}

export default ProfileSummary
