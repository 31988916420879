import React, {useContext} from 'react'

import {Timeline} from 'antd'
import {pick} from 'lodash-es'
import PropTypes from 'prop-types'

import {getFailedChecks} from 'util/operation-checks.js'

import StepChecksError from 'components/display/operation-params/step-checks-error.js'

import {TimelineContext} from './operation-params-wrapper.js'

function ParamsTimeline({children}) {
  const {preparationContext, t} = useContext(TimelineContext)

  const failedChecks = getFailedChecks(preparationContext)

  // Convert children to timeline items
  const timelineItems = React.Children.toArray(children)
    .filter(Boolean)
    .map(child => {
      const sectionFailedChecks = pick(failedChecks, child?.props?.checks)
      const hasError = Object.keys(sectionFailedChecks).length > 0
      const color = hasError ? 'red' : (preparationContext ? 'green' : 'gray')
      const error = hasError ? <StepChecksError failedChecks={sectionFailedChecks} t={t}/> : null

      return {
        color,
        children: React.cloneElement(child, {error})
      }
    })

  return (
    <Timeline items={timelineItems}/>
  )
}

ParamsTimeline.propTypes = {
  children: PropTypes.node.isRequired
}

export default ParamsTimeline
