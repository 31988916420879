/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import Checkbox from 'containers/form/checkbox.js'
import TextArea from 'containers/form/text-area.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('createFilter')}
        checks={['expression', 'ignoreRowOnError']}
      >
        <Checkbox label={t('params.ignoreRowOnError')} name='ignoreRowOnError'/>
        <TextArea label={t('params.expression')} name='expression'/>
      </ParamsStep>
    </ParamsTimeline>
  )
}
