import {MailOutlined} from '@ant-design/icons'
import {Form, Input, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import useIsValidForm from 'hooks/is-valid-form.js'

import HighlightButton from 'containers/ui/button/highlight-button.js'

const {Text} = Typography
function SignUpForm({initialValues, isLoading, onSubmit}) {
  const {t} = useTranslation()

  const [form, isValid] = useIsValidForm()

  return (
    <Form
      scrollToFirstError
      layout='vertical'
      form={form}
      name='signup'
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Form.Item
        name='email'
        label={<Text strong>{t('Register.emailLabel')}</Text>}
        validateDebounce={500}
        rules={[
          {type: 'email', message: t('Register.invalidEmail')},
          {required: true, message: t('Register.mandatoryField')}
        ]}
      >
        <Input
          autoFocus
          prefix={<MailOutlined/>}
          type='email'
          autoComplete='email'
          label='email'
          placeholder={t('Register.emailPlaceholder')}
        />
      </Form.Item>

      <Form.Item style={{textAlign: 'center'}}>
        <HighlightButton
          type='primary'
          htmlType='submit'
          disabled={!isValid}
          loading={isLoading}
        >
          {t('Register.register')}
        </HighlightButton>
      </Form.Item>
    </Form>
  )
}

SignUpForm.propTypes = {
  initialValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SignUpForm
