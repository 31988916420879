/* eslint-disable complexity, no-await-in-loop */
import mitt from 'mitt'
import pRetry from 'p-retry'

import {executeRequest} from './util/request.js'

const UPLOAD_CONCURRENCY = 16

export async function createProjectFileList({client, projectId}) {
  return executeRequest({
    url: `/projects/${projectId}/file-lists`,
    method: 'POST',
    client
  })
}

export async function getProjectFileLists({client, projectId}) {
  return executeRequest({
    url: `/projects/${projectId}/file-lists`,
    client
  })
}

export async function getFileList({client, fileListId}) {
  return executeRequest({
    url: `/file-lists/${fileListId}`,
    client
  })
}

export async function getFileListItems({client, fileListId}) {
  return executeRequest({
    url: `/file-lists/${fileListId}/items`,
    client
  })
}

export async function deleteFileList({client, fileListId}) {
  return executeRequest({
    url: `/file-lists/${fileListId}`,
    method: 'DELETE',
    client
  })
}

export async function closeFileList({client, fileListId}) {
  return executeRequest({
    url: `/file-lists/${fileListId}/close`,
    method: 'POST',
    client
  })
}

async function addItemToFileList({client, fileListId, item}) {
  return executeRequest({
    url: `/file-lists/${fileListId}/items`,
    method: 'POST',
    body: item,
    client
  })
}

async function removeItemFromFileList({client, fileListId, itemId}) {
  return executeRequest({
    url: `/file-lists/${fileListId}/items/${itemId}`,
    method: 'DELETE',
    client
  })
}

async function startUploadItem({client, fileListId, itemId}) {
  return executeRequest({
    url: `/file-lists/${fileListId}/items/${itemId}/upload`,
    method: 'POST',
    client
  })
}

async function getPartUploadUrl({client, fileId, partNumber}) {
  return executeRequest({
    url: `/files/${fileId}/upload/${partNumber}`,
    client
  })
}

async function uploadFile({url, file, signal}) {
  const response = await fetch(url, {
    method: 'PUT',
    body: file.slice(),
    signal
  })

  if (!response.ok) {
    throw new Error(`Failed to upload file: ${response.statusText}`)
  }
}

async function uploadPart({url, file, partSize, partNumber, signal}) {
  const start = partSize * (partNumber - 1)
  const end = Math.min(start + partSize, file.size)
  const blob = file.slice(start, end)

  const response = await fetch(url, {
    method: 'PUT',
    body: blob,
    signal
  })

  if (!response.ok) {
    throw new Error(`Failed to upload part ${partNumber}: ${response.statusText}`)
  }

  return {
    number: partNumber,
    size: blob.size,
    etag: response.headers.get('ETag')
  }
}

async function completePartUpload({client, fileId, part}) {
  await executeRequest({
    url: `/files/${fileId}/upload/${part.number}`,
    method: 'PUT',
    body: part,
    client
  })
}

async function completeUpload({client, fileId}) {
  return executeRequest({
    url: `/files/${fileId}/upload/complete`,
    method: 'POST',
    client
  })
}

function computeStatusFromServerItem(item) {
  if (item.isReady) {
    return 'uploaded'
  }

  if (item.file) {
    return 'uploading'
  }

  return 'pending'
}

function convertItemFromServer(serverItem, fileMap, client) {
  const item = {
    _id: serverItem._id,
    name: serverItem.path.split('/').pop(),
    size: serverItem.size,
    fullPath: serverItem.path,

    file: serverItem.file,
    user: serverItem.user,

    status: computeStatusFromServerItem(serverItem),
    progress: undefined,
    transferred: undefined
  }

  item.currentContext = item.user === client.user._id && fileMap.has(item.fullPath)

  return item
}

export function getFileListInteractiveInstance({client, fileListId}) {
  const instance = mitt()

  const itemMap = new Map()
  const fileMap = new Map()
  const idMap = new Map()

  /* Pending queue management */

  const pendingQueue = []

  function flushPendingQueue() {
    pendingQueue.length = 0
  }

  function addToPendingQueue(itemId) {
    pendingQueue.push(itemId)
  }

  function nextItemInQueue() {
    const itemId = pendingQueue.shift()
    if (itemId) {
      const fullPath = idMap.get(itemId)
      return itemMap.get(fullPath)
    }
  }

  function removeFromPendingQueue(fullPath) {
    const index = pendingQueue.indexOf(fullPath)

    if (index !== -1) {
      pendingQueue.splice(index, 1)
    }
  }

  /* Uploading items management */

  const uploadingItems = new Map()

  function addUploadingItem(itemId) {
    if (uploadingItems.has(itemId)) {
      throw new Error(`Item ${itemId} is already being uploaded`)
    }

    const abortController = new AbortController()
    uploadingItems.set(itemId, {abortController})
    return abortController.signal
  }

  function removeUploadingItem(itemId) {
    const {abortController} = uploadingItems.get(itemId) || {}

    if (abortController) {
      abortController.abort()
    }

    uploadingItems.delete(itemId)
  }

  /* Event channel */

  const channel = client.eventChannel(`/file-lists/${fileListId}/events`)

  channel.on('error', error => {
    instance.emit('error', error)
  })

  channel.on('message', message => {
    switch (message.type) {
      case 'ready': {
        // Clear the current pending list for now
        flushPendingQueue()

        // Create a new set to collect the actual items
        const actualItems = new Set()

        // Update the items
        for (const serverItem of message.items) {
          const item = convertItemFromServer(serverItem, fileMap, client)
          actualItems.add(item.fullPath)
          itemMap.set(item.fullPath, item)
          idMap.set(item._id, item.fullPath)
        }

        // Remove items that are no longer in the server
        for (const fullPath of itemMap.keys()) {
          if (!actualItems.has(fullPath)) {
            itemMap.delete(fullPath)
            fileMap.delete(fullPath) // Remove the file reference if available
          }
        }

        // Cancel the uploading items that are not uploaded by the current user
        for (const itemId of uploadingItems.keys()) {
          const fullPath = idMap.get(itemId)
          const item = itemMap.get(fullPath)

          if (!item || item.status !== 'uploading' || item.user !== client.user._id) {
            removeUploadingItem(itemId)
          }
        }

        // Recreate the pending queue
        for (const item of itemMap.values()) {
          if (item.status === 'pending' && item.user === client.user._id && fileMap.has(item.fullPath)) {
            addToPendingQueue(item._id)
          }
        }

        instance.emit('items:updated')
        break
      }

      case 'item:added': {
        const item = convertItemFromServer(message.fileListItem, fileMap, client)
        itemMap.set(item.fullPath, item)
        idMap.set(item._id, item.fullPath)

        if (item.status === 'pending' && item.user === client.user._id && fileMap.has(item.fullPath)) {
          addToPendingQueue(item._id)
        }

        instance.emit('items:updated')
        break
      }

      case 'item:removed': {
        const itemId = message.fileListItemId
        const fullPath = idMap.get(itemId)
        if (fullPath) {
          // Remove the item from the maps
          itemMap.delete(fullPath)
          fileMap.delete(fullPath)
          idMap.delete(itemId)

          // Remove the item from the pending queue
          removeFromPendingQueue(itemId)

          // Remove the item from the uploading items and cancel the upload
          removeUploadingItem(itemId)

          instance.emit('items:updated')
        }

        break
      }

      case 'item:upload-started': {
        const fullPath = idMap.get(message.fileListItemId)

        if (fullPath) {
          updateItem(fullPath, {
            status: 'uploading',
            progress: 0,
            transferred: 0,
            file: message.fileId
          })
        }

        break
      }

      case 'item:upload-progress': {
        const fullPath = idMap.get(message.fileListItemId)

        if (fullPath) {
          updateItem(fullPath, {
            progress: message.transferred / message.total,
            transferred: message.transferred
          })

          instance.emit('items:updated')
        }

        break
      }

      case 'item:upload-complete': {
        const fullPath = idMap.get(message.fileListItemId)

        if (fullPath) {
          updateItem(fullPath, {
            status: 'uploaded',
            progress: undefined,
            transferred: undefined
          })

          instance.emit('items:updated')
        }

        break
      }

      default:
    }

    uploadNext()
  })

  function addItem(item) {
    const entry = item.webkitGetAsEntry()

    if (entry) {
      traverseTree(entry, '')
    }
  }

  function addItems(items) {
    for (const item of items) {
      addItem(item)
    }
  }

  function traverseTree(entry, path = '') {
    if (entry.isFile) {
      entry.file(file => addFile(file, path), handleError)
    } else if (entry.isDirectory) {
      const dirReader = entry.createReader()
      dirReader.readEntries(entries => {
        for (const dirEntry of entries) {
          traverseTree(dirEntry, path + entry.name + '/')
        }
      }, handleError)
    }
  }

  async function addFile(file, path = '') {
    const fullPath = file.webkitRelativePath || path + file.name

    if (!fileMap.has(fullPath)) {
      fileMap.set(fullPath, file)
    }

    if (itemMap.has(fullPath)) {
      return
    }

    try {
      await addItemToFileList({client, fileListId, item: {path: fullPath, size: file.size}})
    } catch (error) {
      handleError(error)
    }
  }

  function updateItem(fullPath, changes) {
    const entry = itemMap.get(fullPath)

    if (!entry) {
      return
    }

    const updatedEntry = {
      ...entry,
      ...changes
    }

    itemMap.set(fullPath, updatedEntry)
  }

  async function uploadNext() {
    if (uploadingItems.size >= UPLOAD_CONCURRENCY) {
      return
    }

    const item = nextItemInQueue()

    if (!item) {
      return
    }

    const signal = addUploadingItem(item._id)

    try {
      const {_id: fileId, upload: {url, partSize, partCount}} = await startUploadItem({
        client,
        fileListId,
        itemId: item._id
      })

      const isSinglePart = Boolean(url)

      if (isSinglePart) {
        await pRetry(async () => uploadFile({
          url,
          file: fileMap.get(item.fullPath),
          signal
        }), {retries: 3, signal})
      } else {
        for (let i = 1; i <= partCount; i++) {
          const {presignedUrl} = await getPartUploadUrl({client, fileId, partNumber: i})
          const part = await pRetry(async () => uploadPart({
            url: presignedUrl,
            file: fileMap.get(item.fullPath),
            partSize,
            partNumber: i,
            signal
          }), {retries: 3, signal})

          if (signal.aborted) {
            return
          }

          await completePartUpload({client, fileId, part})
        }
      }

      await completeUpload({client, fileId})
    } catch (error) {
      handleError(error)
    }

    removeUploadingItem(item._id)

    uploadNext()
  }

  async function removeFile(fullPath) {
    const item = itemMap.get(fullPath)

    if (!item) {
      return
    }

    try {
      await removeItemFromFileList({client, fileListId, itemId: item._id})
    } catch (error) {
      if (error.code !== 404) {
        handleError(error)
      }
    }

    itemMap.delete(fullPath)
    fileMap.delete(fullPath)

    removeFromPendingQueue(item._id)
    removeUploadingItem(item._id)

    instance.emit('items:updated')
  }

  function removeFilesByPrefix(prefixPath) {
    for (const item of itemMap.values()) {
      if (item.fullPath.startsWith(prefixPath)) {
        removeFile(item.fullPath)
      }
    }
  }

  function handleError(error) {
    instance.emit('error', error)
  }

  function getFiles() {
    return [...itemMap.values()]
  }

  instance.addItem = addItem
  instance.addItems = addItems
  instance.addFile = addFile
  instance.getFiles = getFiles
  instance.removeFile = removeFile
  instance.removeFilesByPrefix = removeFilesByPrefix

  return instance
}
