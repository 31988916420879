import {useMemo} from 'react'

import {ToolOutlined} from '@ant-design/icons'
import {Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {Outlet, useLoaderData, useNavigate} from 'react-router-dom'

import {getOrganizationSettingsUrl} from 'helpers/url.js'

import useSettingsTab from 'hooks/use-settings-tab.js'

import SettingsLayout from 'components/layouts/settings-layout.js'

function OrganizationSettingsLayout() {
  const {t} = useTranslation('translation', {keyPrefix: 'OrganizationSettings'})

  const navigate = useNavigate()

  const organization = useLoaderData()

  const {selectedTab} = useSettingsTab('general')

  const menuProps = useMemo(() => {
    const items = [
      {
        key: 'general',
        label: t('tab.general'),
        icon: <ToolOutlined/>
      }
    ]

    return {
      items,
      onClick: ({key}) => navigate(getOrganizationSettingsUrl(organization, key)),
      selectedKeys: [selectedTab === 'settings' ? 'general' : selectedTab]

    }
  }, [organization, selectedTab, navigate, t])

  return (
    <SettingsLayout menuProps={menuProps}>
      <Typography.Title level={2}>{t(`tab.${selectedTab}`)}</Typography.Title>
      <Outlet/>
    </SettingsLayout>
  )
}

export default OrganizationSettingsLayout
