import React from 'react'

import {List} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import PlaceholderMessage from 'components/display/placeholder-message.js'
import OrganizationListItem from 'components/organization/organization-list-item.js'

function OrganizationsList({organizations, isUserMember = false, userId}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Organizations.OrganizationsList'})

  return (
    <List
      dataSource={organizations}
      renderItem={organization => (
        <OrganizationListItem
          key={organization._id}
          organization={organization}
          userId={userId}
        />
      )}
      locale={{emptyText: (
        <PlaceholderMessage
          message={t(isUserMember ? 'organizationPlaceholder' : 'publicOrganizationPlaceholder')}
          imgSrc='/images/organization-illustration.svg'
          imgAlt={t('noOrganizationImgAlt')}
        />
      )}}
    />
  )
}

OrganizationsList.propTypes = {
  organizations: PropTypes.array.isRequired,
  userId: PropTypes.string,
  isUserMember: PropTypes.bool
}

export default React.memo(OrganizationsList)

