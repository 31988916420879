import {groupBy, mapValues} from 'lodash-es'

export function getValidationMessages(error) {
  if (!error) {
    return null
  }

  const groupedByLabel = groupBy(error.details, message => message.context.label)
  return mapValues(groupedByLabel, details => details.map(detail => detail.message))
}

export function getValidationMessagesForField(fieldName, error) {
  const validationMessages = getValidationMessages(error)
  if (!validationMessages) {
    return null
  }

  return validationMessages[fieldName]
}

export function getValidationMessagesHelp(validationMessages, fieldName) {
  return validationMessages[fieldName] && validationMessages[fieldName].join(', ')
}

export function getValidationMessagesForArray(values, fieldName, validationMessages) {
  if (!validationMessages || values.length === 0) {
    return null
  }

  return Object.keys(validationMessages)
    .filter(key => key.startsWith(fieldName))
    .map(key => {
      const match = key.match(/\[(\d+)]/) // Extract the index within brackets
      const index = match[1] // Get the captured group (index)
      const message = validationMessages[key][0] // Get only the first validation message for this index

      return message.replace(`${fieldName}[${index}]`, values[index])
    })
}
