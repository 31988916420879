import {useEffect, useState} from 'react'

import {HourglassOutlined} from '@ant-design/icons'
import {Flex, Typography} from 'antd'
import PropTypes from 'prop-types'

import {formatDuration, getDateDiff} from 'util/date.js'

const {Text} = Typography

const styles = {
  timer: {
    width: 84
  }
}

function Timer({startTime, endTime, mode = 'since'}) {
  const [time, setTime] = useState(null)

  useEffect(() => {
    if (mode === 'since') {
      if (startTime && !endTime) {
        const interval = setInterval(() => {
          const date = new Date(getDateDiff(startTime))
          setTime(date)
        }, 100)

        return () => clearInterval(interval)
      }

      const date = new Date(getDateDiff(startTime, endTime))
      setTime(date)
    } else if (mode === 'left' && endTime) {
      const interval = setInterval(() => {
        const diff = Math.abs(getDateDiff(new Date(), endTime))
        if (diff >= 1000) { // 1 second
          const date = new Date(diff)
          setTime(date)
        }
      }, 100)

      return () => clearInterval(interval)
    }
  }, [startTime, endTime, mode])

  return (
    <Flex
      style={styles.timer}
      justify='space-between'
      align='center'
    >
      <Text type={endTime ? 'secondary' : null}>
        {time ? formatDuration(time) : '--:--:--'}
      </Text>
      <HourglassOutlined/>
    </Flex>
  )
}

Timer.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  mode: PropTypes.oneOf(['since', 'left'])
}

export default Timer
