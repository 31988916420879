/* eslint-disable react/prop-types */

import {EllipsisOutlined, RobotOutlined} from '@ant-design/icons'
import {Avatar, Button, Dropdown, Space} from 'antd'
import theme from 'theme.js'

import UsersAvatarGroup from 'components/display/users-avatar-group.js'

const styles = {
  runnerAvatar: {
    backgroundColor: theme.token.colorPrimary
  }
}

function FilesetMembers({type, members, actions}) {
  return (
    <Space>
      {type === 'system' ? (
        <Avatar style={styles.runnerAvatar} icon={<RobotOutlined/>}/>
      ) : (
        <>
          <UsersAvatarGroup profiles={members}/>
          {actions.length > 0 && (
            <Dropdown key={1} trigger='click' menu={{items: actions}}>
              <Button type='text' size='large' icon={<EllipsisOutlined/>}/>
            </Dropdown>
          )}
        </>
      )}
    </Space>
  )
}

export default FilesetMembers
