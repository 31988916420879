import i18next from 'i18next'

const sizes = [
  'Bytes',
  'KB',
  'MB',
  'GB',
  'TB'
]

export function getSize(size) {
  for (let i = 1; i < sizes.length; i++) {
    if (size < 1024 ** i) {
      const value = Math.round((size / (1024 ** (i - 1))) * 100) / 100
      const unit = sizes[i - 1]

      return {value, unit}
    }
  }

  return size
}

export function formatSize(bytes) {
  let unitIndex = 0 // Start calculating in bytes

  // While bytes is >= 1024 and the unit is not the last available in the array
  while (bytes >= 1024 && unitIndex < sizes.length - 1) {
    bytes /= 1024 // Divide by 1024 to move to the next unit
    unitIndex++ // Move to the next unit
  }

  // Return the object with the value rounded to two decimal places and the unit
  return {
    value: Number.parseFloat(bytes.toFixed(2)), // Round to two decimal places
    unit: sizes[unitIndex]
  }
}

export function formatNumber(value) {
  // Obtenir la langue actuelle de i18next
  const locale = i18next.language

  // Créer une instance de Intl.NumberFormat avec la locale courante
  const formatter = new Intl.NumberFormat(locale, {
    // Vous pouvez spécifier d'autres options ici selon les besoins
    maximumFractionDigits: 2, // Limiter le nombre de décimales à 2
    style: 'decimal' // C'est le style par défaut, il pourrait être 'currency' pour la monnaie, etc.
  })

  // Formater le nombre
  return formatter.format(value)
}
