import PropTypes from 'prop-types'
import styled from 'styled-components'

const CustomArrowMarkerStyled = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`
CustomArrowMarkerStyled.displayName = 'CustomArrowMarker'

function CustomArrowMarker({id, color}) {
  return (
    <CustomArrowMarkerStyled>
      <defs>
        <marker
          id={id}
          viewBox='0 0 10 10'
          refX='10'
          refY='5'
          markerWidth='10'
          markerHeight='10'
          orient='auto-start-reverse'
          fill={color}
        >
          <path d='M 0 0 L 10 5 L 0 10 z'/>
        </marker>
      </defs>
    </CustomArrowMarkerStyled>
  )
}

CustomArrowMarker.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

export default CustomArrowMarker
