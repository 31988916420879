import {useState} from 'react'

import {Alert, Button, Modal} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

function DangerZone({title, description, onConfirm}) {
  const {t} = useTranslation(['translation', 'common'])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  return (
    <Alert
      message={t('DangerZone.title')}
      type='error'
      description={(
        <>
          <Button danger type='primary' onClick={showModal}>
            {title}
          </Button>

          <Modal
            title={title}
            open={isModalOpen}
            okButtonProps={{danger: true}}
            okText={t('common:button.remove')}
            cancelText={t('common:button.cancel')}
            onOk={onConfirm}
            onCancel={handleCancel}
          >
            <p>{description}</p>
          </Modal>
        </>
      )}
    />
  )
}

DangerZone.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default DangerZone
