import {useCallback, useContext, useEffect, useState} from 'react'

import api from 'services/api/index.js'

import OperationsContext from 'contexts/operations-context.js'
import WorkflowContext from 'contexts/workflow-context.js'

import useExecutor from 'hooks/use-executor.js'

function useOperation({operation}) {
  const {workspace} = useContext(WorkflowContext)
  const {handleSelectOperation} = useContext(OperationsContext)

  const [preparation, setPreparation] = useState(null)
  const [execution, setExecution] = useState(null)
  const [progress, setProgress] = useState(null)

  // Update the progress when needed
  useEffect(() => {
    // Reset progress when operation is not executing
    if (operation.status !== 'executing' || !workspace.eventSource) {
      setProgress(null)
      return
    }

    function updateOperationBySSE(message) {
      if (message.operation === operation._id && message.type === 'operation:execution-progress' && operation.status === 'executing') {
        setProgress(message.progress)
      }
    }

    workspace.eventSource.on('message', updateOperationBySSE)
    return () => workspace.eventSource.off('message', updateOperationBySSE)
  }, [operation._id, operation.status, workspace.eventSource])

  // Helper function: handle action, opening the workspace modal if needed
  const handleWorkspaceAction = useCallback(async actionHandler => {
    if (workspace.status === 'active') {
      await actionHandler()
    } else {
      workspace.openModal()
    }
  }, [workspace])

  // Define the delete operation function
  const deleteOperation = useCallback(
    () => handleWorkspaceAction(async () => {
      await api.deleteOperation(operation._id)
      handleSelectOperation(operation.input)
    }),
    [operation, handleSelectOperation, handleWorkspaceAction]
  )

  // Define the execute operation function
  const executeOperation = useCallback(
    () => handleWorkspaceAction(() => operation.isExecutable && api.executeOperation(operation._id)),
    [operation, handleWorkspaceAction]
  )

  // Define the prepare operation function
  const prepareOperation = useCallback(
    () => handleWorkspaceAction(() => operation.isPreparable && api.prepareOperation(operation._id)),
    [operation, handleWorkspaceAction]
  )

  const {execute: updateOperation, error: updateOperationError} = useExecutor({
    handler: useCallback(changes => api.updateOperation(operation._id, changes), [operation._id]),
    async onDataUpdate(operation) {
      if (operation.isPreparable) {
        await handleWorkspaceAction(async () => api.prepareOperation(operation._id))
      }
    }
  })

  // Define the update operation function
  const handleUpdateOperation = useCallback(async params => {
    await handleWorkspaceAction(async () => {
      await updateOperation({params})
    })
  }, [updateOperation, handleWorkspaceAction])

  // Update preparation when needed
  useEffect(() => {
    if (!workspace._id || !operation.hash) {
      setPreparation(null)
      return
    }

    async function updatePreparation() {
      try {
        const preparation = await api.getPreparationByHash(workspace._id, operation.hash)
        setPreparation(preparation)
      } catch {
        setPreparation(null)
      }
    }

    if (operation.preparationStatus === 'finished' && operation.hash !== preparation?.hash) {
      updatePreparation()
    }
  }, [operation.preparationStatus, operation.hash, preparation?.hash, workspace._id])

  // Update execution when needed
  useEffect(() => {
    if (!workspace._id || !operation.hash || operation.executionStatus === 'none') {
      setExecution(null)
      return
    }

    async function updateExecution() {
      try {
        const execution = await api.getExecutionByHash(workspace._id, operation.hash)
        setExecution(execution)
      } catch {
        setExecution(null)
      }
    }

    updateExecution()
  }, [operation.executionStatus, operation.hash, workspace._id])

  return {
    progress,
    execution,
    abort: async () => api.abortOperation(operation._id),
    remove: deleteOperation,
    prepare: prepareOperation,
    execute: executeOperation,
    handleUpdate: handleUpdateOperation,
    updateOperationError,
    preparation
  }
}

export default useOperation
