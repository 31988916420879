
import {useCallback} from 'react'

import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

function OperationExecutionWrapper({executionContext, operationLabel, OperationExecution}) {
  const {t} = useTranslation(['operations', 'common'])

  const to = useCallback((key, options) => {
    if (key.startsWith('common:')) {
      return t(key, options)
    }

    return t(`${operationLabel}.execution.${key}`, options)
  }, [operationLabel, t])

  return (
    <OperationExecution
      executionContext={executionContext}
      t={to}
    />
  )
}

OperationExecutionWrapper.propTypes = {
  executionContext: PropTypes.object.isRequired,
  operationLabel: PropTypes.string.isRequired,
  OperationExecution: PropTypes.func.isRequired
}

export default OperationExecutionWrapper
