/* eslint-disable react/prop-types */

import {useContext, useMemo} from 'react'

import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import theme, {nodeDimensions} from 'theme.js'

import {OPERATIONS} from 'util/operations.js'

import OperationsContext from 'contexts/operations-context.js'

import useOperation from 'hooks/use-operation.js'

import NodeContent from 'components/ui/react-flow/node-content.js'

const NodeContainerStyled = styled.div`
  width: ${nodeDimensions.width}px;
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border: 1px solid ${({theme}) => theme.antd.colorBorder};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;

  box-shadow: ${({theme, selected}) => selected ? theme.antd.boxShadow : ''};
`

function NodeContainer({data, children, selected}) {
  const {t} = useTranslation('operations', {keyPrefix: data.operation.type.replace(':', '-')})

  const {handleSelectOperation} = useContext(OperationsContext)
  const {operation} = data

  const {
    progress,
    preparation,
    execute,
    abort
  } = useOperation({operation: data.operation})

  const {icon} = useMemo(() => OPERATIONS[operation.type], [operation.type])

  const executeOperation = async event => {
    event.stopPropagation()

    try {
      await execute()
    } catch (error) {
      handleSelectOperation(operation._id, {error})
    }
  }

  const stopOperation = async event => {
    event.stopPropagation()

    try {
      await abort()
    } catch (error) {
      handleSelectOperation(operation._id, {error})
    }
  }

  return (
    <NodeContainerStyled selected={selected}>
      <NodeContent
        icon={icon}
        color={theme.token[`${operation.type}Color`]}
        title={t('label')}
        type={operation.type}
        status={operation.status}
        tags={preparation?.result?.labels || []}
        progress={progress}
        isExecutable={operation.isExecutable}
        isAbortable={operation.isAbortable}
        onClick={operation.isAbortable ? stopOperation : executeOperation}
      />

      {children}
    </NodeContainerStyled>
  )
}

export default NodeContainer
