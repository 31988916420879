import {Typography} from 'antd'
import PropTypes from 'prop-types'

const {Text} = Typography

function getTranslation(key, value, t) {
  return t(`checks.${key}${value ? `.${value}` : ''}`)
}

function StepChecksError({failedChecks, t}) {
  const failedChecksKeys = Object.keys(failedChecks)

  if (failedChecksKeys.length === 1) {
    const key = failedChecksKeys[0]
    const value = failedChecks[key]
    return (
      <Text>
        {getTranslation(key, value, t)}
      </Text>
    )
  }

  return (
    <ul>
      {failedChecksKeys.map(key => (
        <li key={key}>
          <Text>
            {getTranslation(key, failedChecks[key], t)}
          </Text>
        </li>
      ))}
    </ul>
  )
}

StepChecksError.propTypes = {
  failedChecks: PropTypes.object,
  t: PropTypes.func.isRequired
}

export default StepChecksError
