import {Handle} from 'reactflow'
import styled from 'styled-components'

const CustomHandle = styled(Handle)`
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border: 3px solid ${({theme}) => theme.antd.colorPrimary};
  width: 10px;
  height: 10px;
`

export default CustomHandle
