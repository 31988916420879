import {useCallback, useContext, useState} from 'react'

import {message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import ProjectContext from 'contexts/project.js'

import Container from 'containers/layout/container.js'
import WorkflowsList from 'containers/workflows/workflows-list.js'

export async function workflowsLoader({params}) {
  const {projectId} = params
  const workflows = await api.getProjectWorkflows(projectId)
  return {workflows}
}

function Workflows() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Workflows'})
  const loaderData = useLoaderData()

  const {project} = useContext(ProjectContext)

  const [messageApi, contextHolder] = message.useMessage()

  const [workflows, setWorkflows] = useState(loaderData.workflows)

  const deleteWorkflow = useCallback(async workflowId => {
    try {
      await api.deleteWorkflow(workflowId)
      setWorkflows(prevWorkflows => prevWorkflows.filter(workflow => workflow._id !== workflowId))
    } catch (error) {
      messageApi.error(t('Workflows.deletingFailed', {error}))
    }
  }, [messageApi, t])

  return (
    <Container>
      {contextHolder}
      <WorkflowsList
        isOwner={project._permissions.includes('write')}
        workflows={workflows}
        deleteWorkflow={deleteWorkflow}
      />
    </Container>
  )
}

export default Workflows
