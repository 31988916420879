import {useMemo} from 'react'

import {Descriptions, Flex, Typography} from 'antd'
import {omit, pick} from 'lodash-es'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import PreparationChecks from 'components/display/operation-params/preparation-checks.js'

import Metadata from 'containers/operations/shared-components/metadata.js'
import PreviewDataTable from 'containers/operations/shared-components/preview-data-table.js'
import Schema from 'containers/operations/shared-components/schema.js'

const {Title} = Typography

const METADATA_KEYS = ['featuresCount', 'geometryType', 'layerName', 'srs']

function PreparationResult({result}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Operations.PreparationResult'})

  const items = useMemo(() => {
    const keys = omit(result, ['operationReady', 'checks', 'preview', 'schema', ...METADATA_KEYS])
    return Object.entries(keys).map(([key, value]) => ({
      key,
      label: key,
      children: value
    }))
  }, [result])

  const metadata = pick(result, METADATA_KEYS)

  return (
    <Flex vertical gap='middle'>
      {items.length > 0 && (
        <>
          <Title level={5}>{t('preparationTitle')}</Title>
          <Descriptions
            bordered
            column={1}
            items={items}
          />
        </>
      )}

      {result?.schema && (
        <Schema schema={result?.schema}/>
      )}

      {result?.preview && (
        <PreviewDataTable data={result?.preview} title={t('previewTableTitle')}/>
      )}

      {Object.keys(metadata).length > 0 && (
        <Metadata metadata={metadata}/>
      )}

      {result.checks.length > 0 && (
        <PreparationChecks result={result}/>
      )}
    </Flex>
  )
}

PreparationResult.propTypes = {
  result: PropTypes.object
}

export default PreparationResult
