import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {getSize} from 'util/file.js'

function FileSize({bytes}) {
  const {t} = useTranslation('common', {keyPrefix: 'fileSizes'})
  const {value, unit} = getSize(bytes)

  return (
    unit
      ? t(`${unit}`, {size: value})
      : t('overflow')
  )
}

FileSize.propTypes = {
  bytes: PropTypes.number.isRequired
}

export default FileSize
