import {EyeOutlined} from '@ant-design/icons'

export {Params} from './params.js'

export const type = 'read'
export const label = 'csv'
export const icon = EyeOutlined

export const defaultValues = {
  filePath: '*.csv',
  encoding: 'auto',
  separator: 'auto',
  header: true,
  preserveEmptyRows: false,
  trimValues: true
}
