import {Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const {Text} = Typography

const CodeBlockStyled = styled.pre`
    overflow-y: auto;
    background: ${({theme}) => theme.antd.colorBgContainer};
    padding: 10px;
    border: 1px solid ${({theme}) => theme.antd.colorBorder};
    border-radius: 4px;
`

function CodeBlock({code, isCopyable = true}) {
  return (
    <Text copyable={isCopyable ? {text: code} : false}>
      <CodeBlockStyled>
        <code>{code}</code>
      </CodeBlockStyled>
    </Text>
  )
}

CodeBlock.propTypes = {
  code: PropTypes.string.isRequired,
  isCopyable: PropTypes.bool
}

export default CodeBlock
