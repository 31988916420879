import {MailOutlined, SendOutlined} from '@ant-design/icons'
import {Form, Input, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import HighlightButton from 'containers/ui/button/highlight-button.js'

const {Text} = Typography

const FormItem = styled(Form.Item)`
  display: flex;
  justify-content: center;
  margin-top: ${({theme}) => theme.antd.marginXL}px;
`

function SignInForm({isLoading, onSubmit}) {
  const {t} = useTranslation('translation', {keyPrefix: 'SignIn.SignInForm'})

  return (
    <Form
      name='signin'
      layout='vertical'
      onFinish={onSubmit}
    >
      <Form.Item
        label={<Text strong>{t('emailLabel')}</Text>}
        name='email'
        validateDebounce={500}
        rules={[
          {type: 'email', message: t('invalidEmail')},
          {required: true, message: t('mandatoryField')}
        ]}
      >
        <Input
          autoFocus
          prefix={<MailOutlined/>}
          type='email'
          autoComplete='email'
          label='email'
          placeholder={t('emailPlaceholder')}
        />
      </Form.Item>

      <FormItem shouldUpdate>
        <HighlightButton
          type='primary'
          htmlType='submit'
          icon={<SendOutlined/>}
          loading={isLoading}
        >
          {isLoading ? t('sendingMagicLink') : t('sendMagicLink')}
        </HighlightButton>
      </FormItem>
    </Form>
  )
}

SignInForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SignInForm
