import {Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const {Title} = Typography

const StyledPlanBadge = styled(Title)`
  ${({theme}) => {
    const {padding, badgeRadius, colorGradientBadge} = theme.antd

    return `
      padding: ${padding / 2}px ${padding * 2}px;
      margin: 0 auto;
      background: ${colorGradientBadge};
      border-radius: ${badgeRadius}px;
  `
  }}
`

function PlanBadge({plan}) {
  return (
    <StyledPlanBadge
      level={5}
    >
      {plan}
    </StyledPlanBadge>
  )
}

PlanBadge.propTypes = {
  plan: PropTypes.string.isRequired
}

export default PlanBadge
