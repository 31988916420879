/* eslint-disable react/prop-types */

import ExecutionTab from 'components/display/execution-tab.js'

export function Execution({executionContext, t}) {
  const {response} = executionContext
  return response && (
    <ExecutionTab values={{
      [t('headers')]: response.headers,
      [t('statusCode')]: response.statusCode,
      [t('computedFileName')]: response.computedFileName
    }}/>
  )
}
