/* eslint-disable react/prop-types */

import {ExclamationCircleFilled} from '@ant-design/icons'
import {Modal} from 'antd'
import {useTranslation} from 'react-i18next'

function PendingUploadsModal({isOpen, onConfirm, onClose}) {
  const {t} = useTranslation('translation', {keyPrefix: 'FilesetModal.PendingUploadsModal'})
  return (
    <Modal
      title={t('title')}
      open={isOpen}
      icon={<ExclamationCircleFilled/>}
      okText={t('okText')}
      okType='danger'
      cancelText={t('cancelText')}
      onOk={onConfirm}
      onCancel={onClose}
    >
      {t('content')}
    </Modal>
  )
}

export default PendingUploadsModal
