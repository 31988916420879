import {Col, Flex, Typography, Row} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Container from 'containers/layout/container.js'

const {Title, Text} = Typography

const Illustration = styled.img`
    position: absolute;
    width: 80%;
    max-width: 600px;
`

const IllustratedLayoutStyled = styled(Row)`
  flex: 1;

  .title {
    margin: 0;
  }

  .icon {
    width: 80px;
  }

  .full-height {
    height: 100%;
  }
  
  .illustration-container {
    width: 100%;
    height: 500px;
  }
`

function IllustratedLayout({children, iconSrc, iconAlt, illustrationSrc, illustrationAlt, title, subtitle}) {
  return (
    <IllustratedLayoutStyled>
      <Col xs={24} sm={24} md={24} lg={10}>
        <Container background='secondary'>
          <Flex vertical align='center' gap='small'>
            {iconSrc && (
              <img className='icon' src={iconSrc} alt={iconAlt}/>
            )}
            {title && (
              <Title level={2} className='title' align='center'>{title}</Title>
            )}

            {subtitle && (
              <Text type='secondary' align='center'>{subtitle}</Text>
            )}
          </Flex>

          {children}
        </Container>
      </Col>

      <Col sm={0} md={0} xs={0} lg={14}>
        <Flex vertical className='full-height' justify='center'>
          <Flex className='illustration-container' justify='center'>
            <Illustration src={illustrationSrc} alt={illustrationAlt}/>
          </Flex>
        </Flex>
      </Col>
    </IllustratedLayoutStyled>
  )
}

IllustratedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  iconSrc: PropTypes.string,
  iconAlt: PropTypes.string,
  illustrationSrc: PropTypes.string.isRequired,
  illustrationAlt: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default IllustratedLayout
