/* eslint-disable react/prop-types */

import {EllipsisOutlined} from '@ant-design/icons'
import {Button, Dropdown, Typography, List, Flex, Space} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {getTimeSince} from 'util/date.js'

import Executions from 'components/display/workflows/workflow-items/executions.js'
import WorkspaceIndicator from 'components/display/workflows/workflow-items/workspace-indicator.js'

const {Text} = Typography

const WorkflowItemContainer = styled.div`
  background-color: white;
  border-radius: 3px;
  margin: ${({theme}) => theme.antd.marginXS}px 0;
  padding: 0 ${({theme}) => theme.antd.padding}px;

  .full-width {
    width: 100%;
  }

  .grow: {
    flex: 1;
  }
`

function FlexItem({children, ...props}) {
  return <Flex {...props} justify='space-between' align='center' gap='middle'>{children}</Flex>
}

function WorkflowItem({url, title, lastUpdate, hasActiveWorkspaces, executions, actions}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkflowItem'

  return (
    <WorkflowItemContainer>
      <List.Item>
        <FlexItem className='full-width'>
          <FlexItem wrap className='grow'>
            <FlexItem wrap className='grow'>
              <Space>
                <WorkspaceIndicator
                  isActive={hasActiveWorkspaces}
                  t={s => t(`${keyPrefix}.${s}`)}
                />
                <Link to={url}>{title}</Link>
              </Space>

              <Text>
                {t('common:date.updatedSince')}{t('common:date.since', {since: getTimeSince(new Date(lastUpdate))})}
              </Text>
            </FlexItem>

            {executions?.length > 0 && (
              <Executions executions={executions}/>
            )}
          </FlexItem>

          {actions?.length > 0 && (
            <Dropdown key={1} trigger='click' menu={{items: actions}}>
              <Button type='text' size='large' icon={<EllipsisOutlined/>}/>
            </Dropdown>
          )}
        </FlexItem>
      </List.Item>
    </WorkflowItemContainer>
  )
}

WorkflowItem.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired,
  hasActiveWorkspaces: PropTypes.bool.isRequired,
  executions: PropTypes.array,
  actions: PropTypes.array
}

export default WorkflowItem
