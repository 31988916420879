/*  eslint-disable react/prop-types */
import {Progress, theme} from 'antd'

function ExecutionProgress({...props}) {
  const {token} = theme.useToken()
  const strokeColor = props.type === 'circle'
    ? token.colorPrimary
    : {from: token.colorPrimary, to: token.colorSecondaryGreen}
  return (
    <Progress
      {...props}
      strokeColor={strokeColor}
    />
  )
}

export default ExecutionProgress
