/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import FilesSelector from 'containers/form/files-selector.js'
import Select from 'containers/form/select.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  const layerNameOptions = preparationContext?.result?.layerNames?.map(layerName => ({
    value: layerName,
    label: layerName
  }))

  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('fileSelection')}
        checks={['filePath']}
      >
        <FilesSelector
          name='filePath'
          files={inputContext?.files || []}
        />
      </ParamsStep>

      {layerNameOptions?.length > 1 && (
        <ParamsStep
          title={t('layerSelection')}
          checks={['layer']}
        >
          <Select
            name='layerName'
            options={layerNameOptions}
            label={t('params.layerName')}
            placeholder={t('params.layerNamePlaceholder')}
          />
        </ParamsStep>
      )}
    </ParamsTimeline>
  )
}
