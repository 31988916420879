import {UserOutlined} from '@ant-design/icons'
import {Avatar, Flex, List, Space, Tooltip, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getProjectUrl} from 'helpers/url.js'

import {getTimeSince} from 'util/date.js'

import VisibilityTag from 'containers/ui/visibility-tag.js'

const {Text} = Typography

const ProjectListItemContainer = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;

  .avatar {
    backouground-color: ${({theme}) => theme.antd.colorBGAvatar};
  }
`

function ProjectListItem({_id, owner, title, _updated, visibility}) {
  const {t} = useTranslation('common')

  const avatar = api.getAvatarURL(owner)

  return (
    <List.Item>
      <Flex flex={1} align='center' gap='large'>
        <div>
          <Tooltip title={owner.fullName || owner.displayName}>
            <Avatar
              className='avatar'
              src={avatar || <UserOutlined/>}
            />
          </Tooltip>
        </div>

        <ProjectListItemContainer justify='space-between'>
          <Space size='middle'>
            <Space direction='vertical' size='small'>
              <Link to={getProjectUrl({_id})}>
                <Text strong>{title}</Text>
              </Link>
              <Text type='secondary'>{t('date.updatedSince')}{t('common:date.since', {since: getTimeSince(_updated)})}</Text>
            </Space>
          </Space>

          <Flex align='center'>
            <VisibilityTag visibility={visibility}/>
          </Flex>
        </ProjectListItemContainer>
      </Flex>
    </List.Item>
  )
}

ProjectListItem.propTypes = {
  _id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    fullName: PropTypes.string,
    displayName: PropTypes.string
  }).isRequired,
  _updated: PropTypes.string.isRequired,
  visibility: PropTypes.oneOf(['public', 'private']).isRequired
}

export default ProjectListItem
