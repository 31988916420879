import {useContext} from 'react'

import ProjectContext from 'contexts/project.js'

function withPrivateField(Component) {
  return props => {
    const {isUserCanEdit} = useContext(ProjectContext)

    if (!isUserCanEdit) {
      return null
    }

    return <Component {...props}/>
  }
}

export default withPrivateField
