import {Fragment} from 'react'

import Icon, {CheckCircleOutlined, CloseCircleOutlined, SyncOutlined} from '@ant-design/icons'
import {Flex, Divider, theme, Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {getTimeSince} from 'util/date.js'

const DividerStyled = styled(Divider)`
  width: 20px;
  min-width: 20px;
`

function Executions({executions}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkflowItem.Executions'

  const {token} = theme.useToken()

  const STATUS = {
    finished: {
      color: token.colorSuccess,
      icon: CheckCircleOutlined
    },
    failed: {
      color: token.colorError,
      icon: CloseCircleOutlined
    },
    running: {
      color: token.colorPrimaryBlue,
      icon: SyncOutlined
    }
  }

  return (
    <Flex align='center'>
      {executions.map((execution, index) => {
        const {icon, color} = STATUS[execution.status]
        const isRunning = execution.status === 'running'

        // Construct the date string
        const datePrefix = t(`${keyPrefix}.${execution.status}`)
        const timeSinceFinished = getTimeSince(new Date(execution.finishedAt))
        const formattedDate = t('common:date.since', {since: timeSinceFinished})
        const date = `${datePrefix} ${formattedDate}`

        return (
          <Fragment key={execution._id}>
            <Tooltip title={date}>
              <Icon
                spin={isRunning}
                style={{color}}
                component={icon}
              />
            </Tooltip>

            {index < executions.length - 1 && (
              <DividerStyled/>
            )}
          </Fragment>
        )
      })}
    </Flex>
  )
}

Executions.propTypes = {
  executions: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  })).isRequired
}

export default Executions
