import React, {useCallback, useContext, useEffect, useMemo, useRef} from 'react'

import {ApartmentOutlined, PartitionOutlined} from '@ant-design/icons'
import {Flex, message} from 'antd'
import {debounce} from 'lodash-es'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import ReactFlow, {
  MarkerType,
  useReactFlow,
  Background,
  Controls,
  BackgroundVariant,
  ControlButton,
  useNodesState,
  useEdgesState,
  useNodesInitialized
} from 'reactflow'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {isOperationCompatible} from 'util/operations.js'
import {createEdgesFromNodes, createNodeFromOperation, getLayoutedElements, proOptions} from 'util/react-flow.js'

import 'reactflow/dist/style.css'
import 'react-flow-style.css'

import OperationsContext from 'contexts/operations-context.js'
import ProjectContext from 'contexts/project.js'
import WorkflowContext from 'contexts/workflow-context.js'

import CustomEdge from 'components/node/custom-edge.js'

import CustomNode from 'containers/react-flow/nodes/custom-node.js'
import InputNode from 'containers/react-flow/nodes/input-node.js'
import OutputNode from 'containers/react-flow/nodes/output-node.js'

const nodeTypes = {
  custom: CustomNode,
  'custom-input': InputNode,
  'custom-output': OutputNode
}

const edgeTypes = {
  custom: CustomEdge
}

const defaultEdgeOptions = {
  type: 'smoothstep',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20
  },
  pathOptions: {offset: 5}
}

const fitViewOptions = {duration: 400, includeHiddenNodes: true}

const ReactFlowContainerStyled = styled(Flex)`
  background-color: ${({theme}) => theme.antd.colorWhite};
`
ReactFlowContainerStyled.displayName = 'ReactFlowContainer'

function ReactFlowContainer({operations, currentWorkspaceId, isOperationsLoaded, selectedOperationId, isVerticalLayout = true, toggleLayout}) {
  const {t} = useTranslation('translation', {keyPrefix: 'ReactFlow'})

  const {getNode, fitView} = useReactFlow()

  const [messageApi, contextHolder] = message.useMessage()

  const {availableOperations} = useContext(ProjectContext)
  const {workspace} = useContext(WorkflowContext)
  const {handleSelectOperation} = useContext(OperationsContext)

  const nodesInitialized = useNodesInitialized()

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const isUpdateLayoutNeeded = React.useRef(false)
  const isFitViewNeeded = React.useRef(true)

  // Ref to track if layout needs to be reapplied
  const shouldApplyLayout = useRef(true)

  const debouncedFitView = useMemo(() => debounce(() => {
    window.requestAnimationFrame(() => {
      fitView(fitViewOptions)
      isFitViewNeeded.current = false
    })
  }, 300), [fitView])

  const applyLayout = useCallback(() => {
    const layoutedElements = getLayoutedElements(nodes, edges, {
      direction: isVerticalLayout ? 'TB' : 'LR'
    })

    setNodes(layoutedElements.nodes)
    setEdges(layoutedElements.edges)
    shouldApplyLayout.current = false

    if (isFitViewNeeded.current) {
      debouncedFitView()
    }
  }, [isVerticalLayout, nodes, edges, debouncedFitView, setNodes, setEdges])

  const debouncedLayoutUpdate = useMemo(() => debounce(() => {
    applyLayout()
  }, 300), [applyLayout])

  useEffect(() => {
    isFitViewNeeded.current = true
  }, [currentWorkspaceId])

  // Update nodes when operations change
  useEffect(() => {
    if (isOperationsLoaded) {
      setNodes(prevNodes => {
        const operationIds = new Set(operations.map(op => op._id))
        // Remove nodes that no longer exist
        const filteredNodes = prevNodes.filter(node => operationIds.has(node.id))
        // Create new nodes for new operations
        const newNodes = operations.map(operation => {
          const existingNode = filteredNodes.find(node => node.id === operation._id)
          if (existingNode) {
            // Update existing node data while preserving position
            return {
              ...existingNode,
              data: {
                ...existingNode.data,
                operation
              }
            }
          }

          // Create new node
          return createNodeFromOperation(operation)
        })

        setEdges(createEdgesFromNodes(newNodes))

        return newNodes
      })

      // Indicate that layout needs to be recalculated
      shouldApplyLayout.current = true
    }
  }, [operations, isOperationsLoaded, setNodes, setEdges])

  // Apply layout when layout needs to be reapplied
  useEffect(() => {
    shouldApplyLayout.current = true
  }, [isVerticalLayout])

  // Apply layout when nodes are initialized and when layout needs to be reapplied
  useEffect(() => {
    if (nodesInitialized && shouldApplyLayout.current) {
      debouncedLayoutUpdate()
    }
  }, [nodesInitialized, debouncedLayoutUpdate])

  // Handle selection change
  useEffect(() => {
    setNodes(prevNodes =>
      prevNodes.map(node => ({
        ...node,
        selected: node.data.operation._id === selectedOperationId
      }))
    )
  }, [selectedOperationId, setNodes])

  // Cleanup debounce on unmount
  useEffect(() => () => {
    debouncedLayoutUpdate.cancel()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onNodeClick = useCallback((event, node) => {
    const {data: {operation}} = node
    if (operation) {
      handleSelectOperation(operation._id)
    }
  }, [handleSelectOperation])

  const onNodesDelete = useCallback(async nodes => {
    try {
      await Promise.all(nodes.map(node => api.deleteOperation(node.id)))
    } catch (error) {
      messageApi.error(t('deleteNodeFail', {error}))
    }
  }, [messageApi, t])

  const onPaneClick = useCallback(() => {
    handleSelectOperation(null)
  }, [handleSelectOperation])

  const onConnect = useCallback(async ({source, target}) => {
    const sourceNode = getNode(source)
    const targetNode = getNode(target)
    isUpdateLayoutNeeded.current = true
    try {
      await api.updateOperation(targetNode.data.operation._id, {input: sourceNode.data.operation._id})
    } catch {
      isUpdateLayoutNeeded.current = false
    }
  }, [getNode, isUpdateLayoutNeeded])

  const isValidConnection = useCallback(({source, target}) => {
    if (source === target) {
      return false
    }

    const sourceOperationType = nodes.find(({id}) => id === source).data.operation.type
    const targetOperationType = nodes.find(({id}) => id === target).data.operation.type

    const sourceOperation = availableOperations.find(({type}) => type === sourceOperationType)
    const targetOperation = availableOperations.find(({type}) => type === targetOperationType)

    return isOperationCompatible(targetOperation, sourceOperation)
  }, [nodes, availableOperations])

  return (
    <ReactFlowContainerStyled flex={1}>
      {contextHolder}
      <ReactFlow
        fitView
        maxZoom={1.5}
        nodes={nodes}
        edges={edges}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        nodesDraggable={false}
        deleteKeyCode={workspace?.isActive ? 'Backspace' : null} // Disable delete key when workspace is not active
        isValidConnection={isValidConnection}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onPaneClick={onPaneClick}
        onNodeClick={onNodeClick}
        onNodesDelete={onNodesDelete}
      >
        <Controls showInteractive={false}>
          <ControlButton onClick={toggleLayout}>
            {isVerticalLayout ? <PartitionOutlined/> : <ApartmentOutlined/>}
          </ControlButton>
        </Controls>
        <Background variant={BackgroundVariant.dots}/>
      </ReactFlow>
    </ReactFlowContainerStyled>
  )
}

ReactFlowContainer.propTypes = {
  operations: PropTypes.array.isRequired,
  currentWorkspaceId: PropTypes.string,
  selectedOperationId: PropTypes.string,
  isOperationsLoaded: PropTypes.bool.isRequired,
  isVerticalLayout: PropTypes.bool.isRequired,
  toggleLayout: PropTypes.func.isRequired
}

export default ReactFlowContainer
