import {Button, Modal, Tree} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

const {DirectoryTree} = Tree

function TreeModal({isOpen, title, treeData, isOkButtonDisabled, onClose, onFileSelect, onConfirmFileSelection}) {
  const {t} = useTranslation('common')

  return (
    <Modal
      title={title}
      open={isOpen}
      selectable={Boolean(onFileSelect)}
      footer={onFileSelect ? [
        <Button key='back' onClick={onClose}>
          {t('button.cancel')}
        </Button>,
        <Button key='submit' type='primary' disabled={isOkButtonDisabled} onClick={onConfirmFileSelection}>
          {t('button.validate')}
        </Button>
      ] : null}
      onCancel={onClose}
    >
      <DirectoryTree
        defaultExpandAll={false}
        treeData={treeData}
        onSelect={onFileSelect}
      />
    </Modal>
  )
}

TreeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  treeData: PropTypes.array.isRequired,
  isOkButtonDisabled: PropTypes.bool.isRequired,
  onFileSelect: PropTypes.func,
  onConfirmFileSelection: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

export default TreeModal
