import {Flex, Typography} from 'antd'
import PropTypes from 'prop-types'

const {Text} = Typography

function ValidationMessages({validationMessages}) {
  if (!validationMessages || validationMessages.length === 0) {
    return null
  }

  return (
    <>
      {validationMessages.map(message => (
        <Flex key={message}>
          <Text type='danger'>{message}</Text>
        </Flex>
      ))}
    </>
  )
}

ValidationMessages.propTypes = {
  validationMessages: PropTypes.array
}

export default ValidationMessages
