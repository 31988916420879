/* eslint-disable react/prop-types */

import React, {useContext} from 'react'

import {CloudUploadOutlined, DownloadOutlined, TeamOutlined} from '@ant-design/icons'
import {Col, Flex, Row, Tag, Typography} from 'antd'
import {round} from 'lodash-es'
import {useTranslation} from 'react-i18next'

import {UserContext} from 'contexts/user.js'

import LinkButton from 'containers/ui/button/link-button.js'

import FileSize from '../file-size.js'

import UploadIndicator from './upload-indicator.js'

const {Text} = Typography

const styles = {
  col: {
    height: '100%'
  }
}

function TreeItem({
  title,
  fullPath,
  removeFile,
  size,
  progress,
  status,
  user,
  downloadUrl,
  currentContext,
  ...folderProps
}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetExplorer'

  const {user: {_id}} = useContext(UserContext)
  const {uploadingSize, totalSize} = folderProps

  const isDirectory = Object.prototype.hasOwnProperty.call(folderProps, 'children')
  const isProgressing = ['uploading', 'pending'].includes(status) || uploadingSize < totalSize
  const _progress = progress || isDirectory ? round((uploadingSize / totalSize) * 100) : null

  const getTitle = () => {
    const statusText = _progress ? `${_progress}%` : t(`status.${status}`)

    let contextInfo = ''
    if (currentContext === false) {
      contextInfo = _id === user
        ? `(${t(`${keyPrefix}.uploadedByOtherTab`)})`
        : `(${t(`${keyPrefix}.uploadedByOtherUser`)})`
    }

    return `${statusText} ${contextInfo}`
  }

  return (
    <Row wrap={false}>
      <Col span={18}>
        <div
          // Italic prop of Text component do not work… no issue found
          style={{fontStyle: status && status !== 'uploaded' ? 'italic' : 'initial'}}
        >
          <Text>{title}</Text>
        </div>
      </Col>

      <Col span={6}>
        <Row
          justify='end'
          align='center'
          gutter={8}
          wrap={false}
          className={isDirectory ? 'directory-meta' : ''}
        >
          <Col align='end' span={12}>
            {downloadUrl && !isDirectory ? (
              <LinkButton
                href={downloadUrl}
                type='link'
                size='small'
                icon={<DownloadOutlined/>}
              >
                {t('common:button.download')}
              </LinkButton>
            ) : (
              <Flex justify='end' align='center' style={styles.col}>
                {isProgressing && status && status !== 'failed' && (
                  <UploadIndicator
                    title={getTitle()}
                    icon={currentContext ? CloudUploadOutlined : TeamOutlined}
                    isActive={isProgressing}
                  />
                )}

                {status === 'failed' && (
                  <Tag color='error'>
                    {t(`${keyPrefix}.status.failed`)}
                  </Tag>
                )}
              </Flex>
            )}
          </Col>

          <Col align='end' span={12}>
            <Flex justify='end' align='center' style={styles.col}>
              {typeof size === 'number' && size >= 0 && (
                <Text italic type='secondary'>
                  <FileSize bytes={totalSize || size}/>
                </Text>
              )}
            </Flex>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default React.memo(TreeItem)
