import {useCallback, useState} from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import api from 'services/api/index.js'

import WorkflowSettingsForm from 'containers/workflow/workflow-settings-form.js'
import WorkflowSummary from 'containers/workflow-editor/workflow-summary.js'

const ScrollableContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: absolute;
`

function WorkflowSettings({workflow, isUserCanEdit}) {
  const [isEditing, setIsEditing] = useState(false)

  const handleUpdate = useCallback(async changes => {
    await api.updateWorkflow(workflow._id, changes)
    setIsEditing(false)
  }, [workflow._id])

  return (
    <ScrollableContainer>
      {isUserCanEdit && isEditing ? (
        <WorkflowSettingsForm
          initialValues={workflow}
          onUpdate={handleUpdate}
          onClose={() => setIsEditing(false)}
        />
      ) : (
        <WorkflowSummary
          workflow={workflow}
          isUserCanEdit={isUserCanEdit}
          handleEdit={() => setIsEditing(true)}
        />
      )}
    </ScrollableContainer>
  )
}

WorkflowSettings.propTypes = {
  workflow: PropTypes.object.isRequired,
  isUserCanEdit: PropTypes.bool.isRequired
}

export default WorkflowSettings
