/* eslint-disable react/prop-types */

import Icon, {CaretRightOutlined} from '@ant-design/icons'
import {Badge, Button, Divider, Flex, Space, Typography} from 'antd'

import NodeOperationProgress from 'containers/react-flow/node-operation-progress.js'

import {ReactComponent as BorderFilled} from 'assets/images/border-filled.svg'

const {Text} = Typography

const styles = {
  divider: {
    margin: 0
  }
}
function OperationState({statusColor, progress, statusLabel, isAbortable, isExecutable, onClick}) {
  return (
    <>
      <Divider style={styles.divider}/>

      <Flex align='center' justify='space-between'>
        <Space>
          <Badge
            status={statusColor}
            color={statusColor}
          />
          <Text italic={isAbortable}>{statusLabel}</Text>
          {progress && (
            <NodeOperationProgress {...progress}/>
          )}
        </Space>

        <Button
          size='small'
          type='primary'
          shape='circle'
          danger={isAbortable}
          icon={<Icon component={isAbortable ? BorderFilled : CaretRightOutlined}/>}
          disabled={!isExecutable && !isAbortable}
          onClick={onClick}
        />
      </Flex>
    </>
  )
}

export default OperationState
