import {useState} from 'react'

import {Button, Checkbox, Modal, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

const {Paragraph} = Typography

function StopWorkspaceModal({isOpen, saveAndStopWorkspace, stopWorkspace, onCancel}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.StopWorkspaceModal'})

  const [isChecked, setIsChecked] = useState(false)

  const handleOnChange = event => {
    const {checked} = event.target
    setIsChecked(checked)
  }

  const handleSaveAndStopWorkspace = async () => {
    await saveAndStopWorkspace({force: isChecked})
  }

  const handleStopWorkspace = async () => {
    await stopWorkspace({force: isChecked})
  }

  return (
    <Modal
      destroyOnClose
      title={t('title')}
      open={isOpen}
      okButtonProps={{danger: true}}
      okText={t('common:button.remove')}
      cancelText={t('common:button.cancel')}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          {t('cancelButtonLabel')}
        </Button>,
        <Button key='stopWorkspace' danger type='primary' onClick={handleStopWorkspace}>
          {t('stopButtonLabel')}
        </Button>,
        <Button
          key='saveAndStopWorkspace'
          type='primary'
          onClick={handleSaveAndStopWorkspace}
        >
          {t('saveAndStopButtonLabel')}
        </Button>
      ]}
      onOk={handleStopWorkspace}
      onCancel={onCancel}
    >
      <Paragraph>{t('content')}</Paragraph>

      <Checkbox
        checked={isChecked}
        onChange={handleOnChange}
      >
        {t('stopAllProcessCheckboxLabel')}
      </Checkbox>
    </Modal>
  )
}

StopWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  saveAndStopWorkspace: PropTypes.func.isRequired,
  stopWorkspace: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default StopWorkspaceModal
