/* eslint-disable react/prop-types */

import {useCallback, useContext, useEffect, useState} from 'react'

import {FileOutlined} from '@ant-design/icons'
import {Flex, message, Skeleton, theme} from 'antd'
import {orderBy} from 'lodash-es'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import {getProjectSettingsUrl} from 'helpers/url.js'

import {getTimeSince} from 'util/date.js'

import ProjectContext from 'contexts/project.js'

import PlaceholderMessage from 'components/display/placeholder-message.js'
import ProjectMeta from 'components/display/project/project-meta.js'
import ProjectSummary from 'components/display/project/project-summary.js'
import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'

import ProjectIntegrations from 'containers/project/project-integrations.js'
import WorkflowsList from 'containers/workflows/workflows-list.js'

import FilesetCardController from './fileset-card-controller.js'

const FILELIST_LIMIT = 3

function ProjectContainer({fileLists, initialWorkflows}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project'})

  const {project} = useContext(ProjectContext)

  const {token} = theme.useToken()

  const [messageApi, contextHolder] = message.useMessage()

  const [avatar, setAvatar] = useState()
  const [isAvatarLoading, setIsAvatarLoading] = useState(true)
  const [workflows, setWorkflows] = useState(initialWorkflows)

  const deleteWorkflow = useCallback(async workflowId => {
    try {
      await api.deleteWorkflow(workflowId)
      setWorkflows(prevWorkflows => prevWorkflows.filter(workflow => workflow._id !== workflowId))
    } catch (error) {
      messageApi.error(t('Workflows.deletingFailed', {error}))
    }
  }, [messageApi, t])

  useEffect(() => {
    async function fetchAvatar() {
      const avatar = api.getAvatarURL(project.owner, 'small')

      setIsAvatarLoading(false)
      setAvatar(avatar)
    }

    fetchAvatar()
  }, [project.owner])

  return (
    <>
      {contextHolder}
      <SidebarLayout
        main={(
          <Flex vertical gap={token.marginLG}>
            <ProjectSummary {...project}/>
            <WorkflowsList
              isOwner={project._permissions.includes('write')}
              workflows={workflows}
              deleteWorkflow={deleteWorkflow}
            />
          </Flex>

        )}
        sidebar={
          <Flex vertical gap={token.marginLG}>
            <Skeleton active avatar loading={isAvatarLoading} size='large'>
              <ProjectMeta
                avatar={avatar}
                ownerType={project.owner.type}
                fullName={project.owner.displayName}
                creationDate={getTimeSince(project._created)}
                lastUpdate={getTimeSince(project._updated)}
              />
            </Skeleton>

            <Section
              title={t('ProjectFiles.sectionTitle')}
              icon={FileOutlined}
              link={fileLists.length > FILELIST_LIMIT && {
                href: getProjectSettingsUrl(project, 'storage')
              }}
            >
              {fileLists.length === 0 && (
                <PlaceholderMessage
                  message={t('ProjectFiles.noFilePlaceholder')}
                  imgSrc='/images/container-illustration.svg'
                  imgAlt={t('ProjectFiles.noFileImgAlt')}
                />
              )}

              <Flex vertical gap='small'>
                {orderBy(fileLists.slice(0, FILELIST_LIMIT), ['updatedAt'], ['desc']).map(fileset => (
                  <FilesetCardController
                    key={fileset._id}
                    fileset={fileset}
                  />
                ))}
              </Flex>

            </Section>

            <ProjectIntegrations/>
          </Flex>
        }
      />
    </>
  )
}

export default ProjectContainer
