
import {Tree} from 'antd'
import styled from 'styled-components'

const DirectoryTree = styled(Tree.DirectoryTree)`
  flex: 1;
  overflow: auto;

  .ant-tree-node-content-wrapper {
    display: flex;
  }

  .ant-tree-treenode:nth-child(odd) {
    background-color: ${({theme}) => theme.antd.contentBgLight};
    transition: background-color 0.5s ease-in-out;
  }

  .ant-tree-title {
    width: 100%;
  }

  .ant-tree-node-content-wrapper-open .directory-meta {
    display: none;
  }
`

export default DirectoryTree
