import {LeftCircleFilled, RightCircleFilled} from '@ant-design/icons'
import {Button} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SiderToggleStyled = styled.div`
  position: absolute;
  background: ${({theme}) => theme.antd.contentBgLight};
  z-index: 2;
  top: calc(50% - ${({theme}) => theme.antd.siderToggleHeight}px);
  height: ${({theme}) => theme.antd.siderToggleHeight}px;
  width: ${({theme}) => theme.antd.siderToggleHeight / 2}px;
  display: flex;
  align-items: center;
  left: ${({theme, $isCollapsed}) => $isCollapsed ? 0 : theme.antd.SiderLayout.width}px;
  border-radius: 0 ${({theme}) => theme.antd.borderRadius}px ${({theme}) => theme.antd.borderRadius}px 0;
  cursor: pointer;
  transition: left ${({theme}) => theme.antd.siderAnimationDuration}ms ease-in-out;
  box-shadow: ${({theme}) => theme.antd.boxShadowSiderLeft}};

  .sider-toggle {
    height: 100%;
    border-radius: inherit;
  }
`

function SiderToggle({isCollapsed, onToggle}) {
  return (
    <SiderToggleStyled $isCollapsed={isCollapsed}>
      <Button
        type='text'
        className='sider-toggle'
        icon={isCollapsed ? <RightCircleFilled/> : <LeftCircleFilled/>}
        onClick={onToggle}
      />
    </SiderToggleStyled>
  )
}

SiderToggle.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default SiderToggle
