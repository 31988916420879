/* eslint-disable react/prop-types */

import React, {useEffect, useState} from 'react'

import {DeleteOutlined, FileDoneOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import FilesetCard from 'components/display/fileset-card.js'

function FilesetCardController({fileset, onOpen, onCloseFileset, onDelete}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetModal'

  const [members, setMembers] = useState([])

  const {_id, status, createdAt, updatedAt, users, itemCount, itemsSize} = fileset

  const isLocked = status === 'closed'

  const actions = []

  if (onCloseFileset && fileset.status === 'open') {
    actions.push({
      label: t(`${keyPrefix}.confirmButton`),
      icon: <FileDoneOutlined/>,
      disabled: fileset.itemCount === 0,
      onClick: () => onCloseFileset(_id)
    })
  }

  if (onDelete) {
    actions.push({
      label: t('common:button.remove'),
      danger: true,
      icon: <DeleteOutlined/>,
      onClick: () => onDelete(_id)
    })
  }

  useEffect(() => {
    async function fetchMembers() {
      const members = await Promise.all(users.map(user => api.getUser(user)))
      setMembers(members)
    }

    if (users) {
      fetchMembers()
    }
  }, [_id, users])

  return (
    <FilesetCard
      key={_id}
      type={fileset.type}
      isLocked={isLocked}
      createdAt={createdAt}
      updatedAt={updatedAt}
      members={members}
      counters={{
        folderCount: 0,
        fileCount: itemCount,
        totalSize: itemsSize
      }}
      actions={actions}
      onOpen={() => onOpen(fileset)}
    />
  )
}

export default React.memo(FilesetCardController)
