/* eslint-disable react/prop-types, no-unused-vars, unicorn/text-encoding-identifier-case */

import {useMemo} from 'react'

import {checkKeysExist, getFailedChecks} from 'util/operation-checks.js'

import ParamsStep from 'components/display/operation-params/params-step.js'

import Checkbox from 'containers/form/checkbox.js'
import FilesSelector from 'containers/form/files-selector.js'
import InputColumns from 'containers/form/input-columns.js'
import Select from 'containers/form/select.js'
import ParamsTimeline from 'containers/params-timeline.js'

function computeSeparatorOption(value, t) {
  return {
    value,
    label: t(`params.separators.${value}`)
  }
}

function computeEncodingOption(value, t) {
  return {
    value,
    label: value.toUpperCase()
  }
}

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  const failedChecks = getFailedChecks(preparationContext)
  const isFileValid = !checkKeysExist(failedChecks, ['filePath', 'fileFormat'])

  const {detectedSeparator, detectedEncoding, detectedFirstRow} = preparationContext?.result || {}

  const separatorOptions = useMemo(() => {
    if (!detectedSeparator) {
      return null
    }

    return [
      {value: 'auto', label: t('params.separators.auto', {value: t(`params.separators.${detectedSeparator}`)})},
      computeSeparatorOption('comma', t),
      computeSeparatorOption('semicolon', t),
      computeSeparatorOption('tab', t),
      computeSeparatorOption('pipe', t)
    ]
  }, [detectedSeparator, t])

  const encodingOptions = useMemo(() => {
    if (!detectedEncoding) {
      return null
    }

    return [
      {value: 'auto', label: t('params.encodings.auto', {value: detectedEncoding.toUpperCase()})},
      computeEncodingOption('utf-8', t),
      computeEncodingOption('iso-8859-1', t),
      computeEncodingOption('iso-8859-15', t),
      computeEncodingOption('windows-1252', t)
    ]
  }, [detectedEncoding, t])

  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('fileSelection')}
        checks={['filePath', 'fileFormat']}
      >
        <FilesSelector
          files={inputContext?.files || []}
          name='filePath'
        />
        {preparationContext?.result.computedFilePath && (
          <p>✅ {t('params.computedFilePath', {computedFilePath: preparationContext.result.computedFilePath})}</p>
        )}
      </ParamsStep>

      {preparationContext && isFileValid && (
        <ParamsStep
          title={t('csvParams')}
          checks={['csvParams']}
        >
          <Select
            label={t('params.encoding')}
            name='encoding'
            options={encodingOptions}
            suffix={t('params.autoDetected', {value: detectedEncoding})}
          />

          <Select
            label={t('params.separator')}
            name='separator'
            options={separatorOptions}
            suffix={t('params.autoDetected', {value: detectedSeparator})}
          />

          {detectedFirstRow && (
            <p>✅ Le fichier semble lisible et contient {detectedFirstRow.length} colonnes.</p>
          )}

          <Checkbox label={t('params.emptyRows')} name='preserveEmptyRows'/>
          <Checkbox label={t('params.trimValues')} name='trimValues'/>
        </ParamsStep>
      )}

      {preparationContext && isFileValid && preparationContext?.result?.detectedFirstRow && (
        <ParamsStep
          title={t('columnsConfig')}
          checks={['columns']}
        >
          <Checkbox label={t('params.header')} name='header'/>

          <InputColumns
            label={t('params.columns')}
            name='columns'
            detectedFirstRow={preparationContext?.result?.detectedFirstRow}
            preview={preparationContext?.result?.preview}
          />
        </ParamsStep>
      )}
    </ParamsTimeline>
  )
}
