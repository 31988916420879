import {useMemo} from 'react'

import {theme} from 'antd'
import PropTypes from 'prop-types'
import {BaseEdge, getBezierPath} from 'reactflow'

import CustomArrowMarker from './custom-arrow-marker.js'

function CustomEdge({id, sourceX, sourceY, targetX, targetY, data, ...props}) {
  const {token} = theme.useToken()

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY
  })

  const style = useMemo(() => {
    const edgeStyle = {
      stroke: token[`${data.type}EdgeColor`]
    }

    if (data.type === 'processing') {
      edgeStyle.strokeDasharray = '3 3'
    }

    return edgeStyle
  }, [data.type, token])

  return (
    <>
      <CustomArrowMarker id={data.markerId} color={style.stroke}/>
      <BaseEdge
        path={edgePath}
        {...props}
        style={style}
      />
    </>
  )
}

CustomEdge.propTypes = {
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  data: PropTypes.shape({
    markerId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
}

export default CustomEdge
