import {Tooltip} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WorkspaceIndicatorDot = styled.div`
  background-color: ${({isActive, theme}) => isActive ? theme.antd.colorSuccess : theme.antd.colorGray90};
  border-radius: 50%;
  height: 10px;
  width: 10px;
  transition: background-color 0.3s ease;

  &.active {
    background-color: ${({theme}) => theme.antd.colorSuccess};
    animation: pulsar 1.5s infinite;
  }

  @keyframes pulsar {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`

function WorkspaceIndicator({isActive = false, t}) {
  return (
    <Tooltip title={t(isActive ? 'activeWorkspace' : 'inactiveWorkspace')}>
      <WorkspaceIndicatorDot className={isActive ? 'active' : ''}/>
    </Tooltip>
  )
}

WorkspaceIndicator.propTypes = {
  isActive: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

export default WorkspaceIndicator
