import {useContext, useState} from 'react'

import {DeleteOutlined, PoweroffOutlined, RetweetOutlined, SaveOutlined} from '@ant-design/icons'
import {Button, Space, Tooltip, message} from 'antd'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import WorkflowContext from 'contexts/workflow-context.js'

import StopWorkspaceModal from 'containers/workspaces-manager/stop-workspace-modal.js'

function WorkspaceControls() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkspaceControls'})
  const {workspace} = useContext(WorkflowContext)

  const [messageApi, contextHolder] = message.useMessage()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const saveWorkspace = async () => {
    try {
      await api.saveWorkspaceIntoWorkflow(workspace._id)
      messageApi.open({
        type: 'success',
        content: t('saveSuccessed')
      })
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: t('saveFailed', {error})
      })
    }
  }

  const stopWorkspace = async props => {
    try {
      await workspace.stopWorkspace(props)
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: t('stopFailed', {error})
      })
    }

    setIsModalOpen(false)
  }

  const saveAndStopWorkspace = async props => {
    try {
      await workspace.saveAndStopWorkspace(props)
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: t('stopFailed', {error})
      })
    }

    setIsModalOpen(false)
  }

  const deleteWorkspace = async () => {
    try {
      await workspace.deleteWorkspace()
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: t('deleteFailed', {error})
      })
    }
  }

  const recreateWorkspace = async () => {
    try {
      await workspace.recreateWorkspace()
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: t('recreateFailed', {error})
      })
    }
  }

  if (workspace?._id) {
    return (
      <>
        {contextHolder}

        <StopWorkspaceModal
          isOpen={isModalOpen}
          saveAndStopWorkspace={saveAndStopWorkspace}
          stopWorkspace={stopWorkspace}
          onCancel={closeModal}
        />

        {workspace.status === 'stopped' || workspace.status === 'stalled' ? (
          <Space>
            <Tooltip title={t('deleteButtonLabel')}>
              <Button danger type='primary' icon={<DeleteOutlined/>} onClick={deleteWorkspace}/>
            </Tooltip>
            <Tooltip title={t('recreateButtonLabel')}>
              <Button type='primary' icon={<RetweetOutlined/>} onClick={recreateWorkspace}/>
            </Tooltip>
          </Space>
        ) : (
          <Space>
            <Tooltip title={t('stopButtonLabel')}>
              <Button danger type='primary' icon={<PoweroffOutlined/>} onClick={openModal}/>
            </Tooltip>
            <Tooltip title={t('saveButtonLabel')}>
              <Button type='primary' icon={<SaveOutlined/>} onClick={saveWorkspace}/>
            </Tooltip>
          </Space>
        )}
      </>
    )
  }
}

export default WorkspaceControls
