import {Suspense} from 'react'

import {Spin, Flex} from 'antd'
import PropTypes from 'prop-types'

import CenteredContent from 'components/layouts/centered-content.js'

function SuspensePage({children}) {
  return (
    <Suspense fallback={(
      <Flex flex={1}>
        <CenteredContent><Spin/></CenteredContent>
      </Flex>
    )}
    >
      {children}
    </Suspense>
  )
}

SuspensePage.propTypes = {
  children: PropTypes.node.isRequired
}

export default SuspensePage
