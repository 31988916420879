/* eslint-disable react/prop-types */
import {useContext} from 'react'

import {Alert, Empty, Flex, Typography} from 'antd'
import {omit} from 'lodash-es'
import {useTranslation} from 'react-i18next'

import ProjectContext from 'contexts/project.js'

import ExecutionTab from 'components/display/execution-tab.js'
import PreparationResult from 'components/display/operation-params/preparation-result.js'
import FileExplorer from 'components/ui/file-explorer.js'

import ExecutionTime from 'containers/operations/execution-time.js'
import ExecutionProgressDropdown from 'containers/workflow-editor/operation-side/execution-progress-dropdown.js'
import OperationExecutionWrapper from 'containers/workflow-editor/operation-side/operation-execution-wrapper.js'

const {Paragraph, Text} = Typography

const EXECUTION_FIELDS_TO_OMIT = ['files']

function OperationResult({operation, progress, preparation, execution, operationLabel, OperationExecution}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.OperationSider.OperationResult'})
  const {availableOperations} = useContext(ProjectContext)

  const isExecutableOperationType = availableOperations.find(op => op.type === operation.type).outputType !== 'stream'

  if (isExecutableOperationType) {
    if (!execution) {
      return (
        <Empty description={(
          <>
            <Text strong>{t('noResultTitle')}</Text>
            <Paragraph>{t('noResultDescription')}</Paragraph>
          </>
        )}/>
      )
    }

    return (
      <Flex vertical gap='small'>
        <ExecutionTime
          startedAt={execution.startedAt}
          finishedAt={execution.finishedAt}
        />

        {execution.status !== 'finished' && (
          <ExecutionProgressDropdown progress={progress}/>
        )}

        {execution.error && (
          <Alert
            showIcon
            type='error'
            message={t('executionError')}
            description={execution.error}
          />
        )}

        {execution.result?.files && (
          <FileExplorer
            files={execution.result.files}
            modalTitle={t('filesListTitle')}
          />
        )}

        {execution.result && (
          OperationExecution ? (
            <OperationExecutionWrapper
              executionContext={execution.result}
              operationLabel={operationLabel}
              OperationExecution={OperationExecution}
            />
          ) : (
            <ExecutionTab values={omit(execution.result, EXECUTION_FIELDS_TO_OMIT)}/>
          )
        )}
      </Flex>
    )
  }

  if (!preparation || !preparation.result?.operationReady) {
    return (
      <Empty description={(
        <>
          <Text strong>{t('noResultTitle')}</Text>
          <Paragraph>{t('noResultDescription')}</Paragraph>
        </>
      )}/>
    )
  }

  return (
    <Flex vertical gap='small'>
      <PreparationResult result={preparation.result}/>
    </Flex>
  )
}

export default OperationResult
