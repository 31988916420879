/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import InputExtension from 'containers/form/input-extension.js'
import ParamsTimeline from 'containers/params-timeline.js'

const archiveFormats = ['zip', 'tar.gz', 'tar.bz2', 'tar.xz']

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('archiveCreation')}
      >
        <InputExtension
          label={t('params.archiveNameLabel')}
          name='archiveName'
          placeholder={t('params.archiveNamePlaceholder')}
          extensions={archiveFormats}
          extensionName='archiveFormat'
          archiveFormatLabel={t('params.archiveFormatLabel')}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
