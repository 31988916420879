import {GithubFilled, GoogleOutlined} from '@ant-design/icons'
import {Alert, Button, Flex} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {getRegisterProviderUrl} from '../../helpers/url.js'

const ThirdPartiesContainer = styled(Flex)`
    & button {
        width: 80%;
    }
`
function ThirdPartiesRegister({errorMessage}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Register'})

  const handleClick = provider => {
    window.location.href = getRegisterProviderUrl(provider)
  }

  return (
    <>
      <ThirdPartiesContainer vertical align='center' gap='middle'>
        <Button
          size='large'
          icon={<GoogleOutlined/>}
          onClick={() => handleClick('google')}
        >
          Google
        </Button>

        <Button
          size='large'
          icon={<GithubFilled/>}
          onClick={() => handleClick('github')}
        >
          GitHub
        </Button>
      </ThirdPartiesContainer>

      {errorMessage && (
        <Alert
          showIcon
          type='error'
          message={t(errorMessage)}
        />
      )}
    </>
  )
}

ThirdPartiesRegister.propTypes = {
  errorMessage: PropTypes.string
}

export default ThirdPartiesRegister
