import i18n from 'i18next'

export function getDateDiff(endDate, startDate) {
  const start = startDate ? new Date(startDate) : new Date()
  const end = new Date(endDate)
  const diff = start - end

  return diff
}

export function formatDuration(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  seconds %= 60
  minutes %= 60

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export function formatDate(dateISOString, format = 'short') {
  const date = new Date(dateISOString)
  return new Intl.DateTimeFormat(i18n.resolvedLanguage, {dateStyle: format}).format(date)
}

export function formatTime(dateISOString) {
  const date = new Date(dateISOString)
  // Options to ensure the time is displayed as hh:mm:ss
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // Use 24-hour format
  }
  // Apply the formatting with specified options
  return new Intl.DateTimeFormat(i18n.resolvedLanguage, options).format(date)
}

export function formatDateTime(dateISOString) {
  const date = new Date(dateISOString)
  return new Intl.DateTimeFormat(i18n.resolvedLanguage, {dateStyle: 'short', timeStyle: 'short'}).format(date)
}

export function timeDiff(date1, date2) {
  // Calculate the time difference in milliseconds
  const diffInMs = Math.abs(date2 - date1)

  // Convert the milliseconds to seconds, minutes, and hours.
  let seconds = Math.floor(diffInMs / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  // Calculate the remaining seconds, minutes, and hours.
  seconds %= 60
  minutes %= 60
  hours %= 24

  let timeString = i18n.t('common:date.seconds', {count: seconds})

  if (minutes > 0) {
    const minutesLabel = i18n.t('common:date.minutes', {count: minutes})
    timeString = `${minutesLabel}, ${timeString}`
  }

  if (hours > 0) {
    const hoursLabel = i18n.t('common:date.hours', {count: hours})
    timeString = `${hoursLabel}, ${timeString}`
  }

  return timeString
}

export function getTimeSince(endDate, startDate = null, isDuration = false) {
  const diff = getDateDiff(endDate, startDate)
  const totalDays = Math.floor(diff / (1000 * 60 * 60 * 24))
  const years = Math.floor(totalDays / 365)
  const months = Math.floor((totalDays % 365) / 30)
  const days = totalDays % 30
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((diff / (1000 * 60)) % 60)

  let timeString = ''

  if (minutes === 0 && hours === 0 && days === 0 && months === 0 && years === 0) {
    // If less than a minute, display 'less than a minute' or 'just now'
    const key = isDuration ? 'lessThanAMinute' : 'justNow'
    return i18n.t(`common:date.${key}`)
  }

  if (years > 0) {
    const yearsLabel = i18n.t('common:date.years', {count: years})
    const monthsLabel = i18n.t('common:date.months', {count: months})
    timeString = `${yearsLabel}${months ? ' ' + i18n.t('common:date.and') + ' ' + monthsLabel : ''}`
  } else if (months > 0) {
    const monthsLabel = i18n.t('common:date.months', {count: months})
    const daysLabel = i18n.t('common:date.days', {count: days})
    timeString = `${monthsLabel}${days ? ' ' + i18n.t('common:date.and') + ' ' + daysLabel : ''}`
  } else if (days > 0) {
    const daysLabel = i18n.t('common:date.days', {count: days})
    const hoursLabel = i18n.t('common:date.hours', {count: hours})
    timeString = `${daysLabel}${hours ? ' ' + i18n.t('common:date.and') + ' ' + hoursLabel : ''}`
  } else if (hours > 0) {
    const hoursLabel = i18n.t('common:date.hours', {count: hours})
    const minutesLabel = i18n.t('common:date.minutes', {count: minutes})
    timeString = `${hoursLabel}${minutes ? ' ' + i18n.t('common:date.and') + ' ' + minutesLabel : ''}`
  } else {
    const minutesLabel = i18n.t('common:date.minutes', {count: minutes})
    timeString = `${minutesLabel}`
  }

  return timeString
}

// Returns true if the difference is less than the specified threshold
export function isWithinThreshold(keepActiveUntil, thresholdMilliseconds) {
  const now = new Date()
  const keepActiveDate = new Date(keepActiveUntil)

  const diff = keepActiveDate - now

  return diff < thresholdMilliseconds
}
