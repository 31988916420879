/* eslint-disable react/prop-types */

import {Result} from 'antd'

function Success({t}) {
  return (
    <Result
      status='success'
      title={t('success')}
      subTitle={t('successMessage')}
    />
  )
}

export default Success
