import {useState, useContext} from 'react'

import {LikeOutlined, UserOutlined} from '@ant-design/icons'
import {Button, Form, Input, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {styled} from 'styled-components'

import api from 'services/api/index.js'

import IllustratedLayout from 'components/layouts/illustrated-layout.js'
import BasicPlan from 'components/plans/basic-plan.js'

import Container from 'containers/layout/container.js'

import {UserContext} from '../contexts/user.js'

const {Paragraph, Text} = Typography

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  font-size: ${({theme}) => theme.antd.fontSizeLG}px;
`

function SuccessfulRegistration() {
  const {t} = useTranslation('translation', {keyPrefix: 'SuccessfullRegistration'})
  const {user} = useContext(UserContext)
  const [fullName, setFullName] = useState(user.fullName)

  const handleSubmit = async () => {
    try {
      await api.updateAccountProfile({
        fullName
      })

      window.location.href = '/welcome'
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Container>
      <IllustratedLayout
        illustrationSrc='/images/inscription-illustration.svg'
        illustrationAlt={t('illustrationAlt')}
        title={t('title')}
      >
        <StyledParagraph type='secondary'>
          {t('confirmation')}
        </StyledParagraph>
        <Form
          name='confirmation'
          layout='vertical'
        >
          <Form.Item
            label={<Text strong>{t('fullName')}</Text>}
          >
            <Input
              placeholder={t('fullName')}
              value={fullName}
              prefix={<UserOutlined/>}
              onChange={e => setFullName(e.target.value)}
            />
          </Form.Item>
        </Form>
        <BasicPlan/>
        <Form.Item
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: '1em auto'
          }}
        >
          <Button
            type='primary'
            icon={<LikeOutlined/>}
            onClick={handleSubmit}
          >
            {t('confirmButton')}
          </Button>
        </Form.Item>
      </IllustratedLayout>
    </Container>
  )
}

export default SuccessfulRegistration
