import {useCallback, useMemo} from 'react'

import Icon from '@ant-design/icons'
import {useNavigate} from 'react-router-dom'

function useTabsNavigation({tabs, t}) {
  const navigate = useNavigate()

  const tabsItems = useMemo(() => Object.keys(tabs)
    .map(key => ({
      key,
      icon: tabs[key].icon ? <Icon component={tabs[key].icon}/> : null,
      label: t(key)
    })), [tabs, t])

  const onTabChange = useCallback(key => {
    navigate(tabs[key].url)
  }, [tabs, navigate])

  return {tabsItems, onTabChange}
}

export default useTabsNavigation
