import {useState} from 'react'

import {Skeleton} from 'antd'
import PropTypes from 'prop-types'

import api from 'services/api/index.js'

import ProfileSummary from 'components/user/profile-summary.js'

function ProfileSummaryController({
  profileAccount,
  organizations,
  isEditable = false,
  isLoading,
  updateAccountProfile,
  updateAccountProfileAvatar
}) {
  const [isEditing, setIsEditing] = useState(false)

  const onUpdateAccountProfile = async payload => {
    await updateAccountProfile(payload)
    setIsEditing(false)
  }

  if (!profileAccount) {
    return null
  }

  return (
    <Skeleton active avatar loading={isLoading}>
      <ProfileSummary
        {...profileAccount}
        isEditable={isEditable}
        isEditing={isEditing}
        handleEditing={setIsEditing}
        organizations={organizations}
        avatar={api.getAvatarURL(profileAccount, 'large')}
        updateAccountProfile={onUpdateAccountProfile}
        updateAccountProfileAvatar={updateAccountProfileAvatar}
      />
    </Skeleton>
  )
}

ProfileSummaryController.propTypes = {
  profileAccount: PropTypes.object,
  organizations: PropTypes.array,
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  updateAccountProfile: PropTypes.func,
  updateAccountProfileAvatar: PropTypes.func
}

export default ProfileSummaryController
