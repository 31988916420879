import {ArrowLeftOutlined} from '@ant-design/icons'
import {Button, Flex, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

const {Text} = Typography

const WorkflowHeaderStyled = styled(Flex)`
  padding: ${({theme}) => theme.antd.paddingXS}px ${({theme}) => theme.antd.paddingMD}px;

  .title {
    margin: 0;
  }
`
WorkflowHeaderStyled.displayName = 'WorkflowHeader'

function WorkflowHeader({projectUrl, workflowTitle, children}) {
  const {t} = useTranslation('common')

  return (
    <WorkflowHeaderStyled
      justify='space-between'
      align='center'
      wrap='wrap'
      gap='small'
    >
      <Space size='middle'>
        <Link to={projectUrl}>
          <Button icon={<ArrowLeftOutlined/>}>
            {t('button.back')}
          </Button>

        </Link>
        <Text strong className='title'>{workflowTitle}</Text>
      </Space>

      {children}
    </WorkflowHeaderStyled>
  )
}

WorkflowHeader.propTypes = {
  projectUrl: PropTypes.string.isRequired,
  workflowTitle: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default WorkflowHeader
