/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import Checkbox from 'containers/form/checkbox.js'
import Input from 'containers/form/input.js'
import Select from 'containers/form/select.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('writeFile')}
      >
        <Input
          name='fileName'
          label={t('params.fileName')}
          placeholder={t('params.fileNamePlaceholder')}
        />

        <Select
          name='encoding'
          label={t('params.encoding')}
          options={[
            {value: 'utf8', label: 'UTF-8'}, // eslint-disable-line unicorn/text-encoding-identifier-case
            {value: 'iso-8859-1', label: 'ISO-8859-1'},
            {value: 'iso-8859-15', label: 'ISO-8859-15 '}
          ]}
        />

        <Select
          name='separator'
          label={t('params.separator')}
          options={[
            {value: 'comma', label: t('params.separators.comma')},
            {value: 'semicolon', label: t('params.separators.semicolon')},
            {value: 'tab', label: t('params.separators.tab')},
            {value: 'pipe', label: t('params.separators.pipe')}
          ]}
        />

        <Select
          name='rowSeparator'
          label={t('params.rowSeparator')}
          options={[
            {value: 'windows', label: 'Windows'},
            {value: 'unix', label: 'Unix'}
          ]}
        />

        <Checkbox
          name='writeHeader'
          label={t('params.writeHeader')}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
