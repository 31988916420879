import {Alert, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const {Text} = Typography

const StepContainer = styled(Flex)`
    padding: ${({theme}) => theme.antd.padding}px;
    background-color: ${({theme}) => theme.antd.contentBgLight};
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
`

const StepTitle = styled(Text)`
    font-size: ${({theme}) => theme.antd.fontSizeLG}px;
`

function ParamsStep({
  title,
  error,
  children
}) {
  return (
    <Flex vertical gap='small'>
      <StepTitle>{title}</StepTitle>

      <StepContainer vertical gap='small'>
        {children}

        {error && (
          <Alert showIcon type='error' message={error}/>
        )}
      </StepContainer>
    </Flex>
  )
}

ParamsStep.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default ParamsStep
