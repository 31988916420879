import {useContext, useMemo} from 'react'

import {DeleteOutlined, PlayCircleOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {getWorkflowUrl} from 'helpers/url.js'

import ProjectContext from 'contexts/project.js'

import WorkflowItem from 'components/display/workflows/workflow-item.js'

function WorkflowItemWrapper({workflow, onDelete}) {
  const {t} = useTranslation(['common'])

  const {project, isUserCanEdit} = useContext(ProjectContext)

  const actions = useMemo(() => isUserCanEdit
    ? [
      {
        label: t('common:button.execute'),
        icon: <PlayCircleOutlined/>,
        disabled: true
      },
      {
        label: t('common:button.remove'),
        icon: <DeleteOutlined/>,
        danger: true,
        disabled: workflow.hasActiveWorkspaces,
        onClick: () => onDelete(workflow._id)
      }
    ]
    : [], [workflow._id, workflow.hasActiveWorkspaces, isUserCanEdit, t, onDelete])

  return (
    <WorkflowItem
      title={workflow.title}
      url={getWorkflowUrl(project, workflow)}
      description={workflow.description}
      lastUpdate={workflow._updated}
      hasActiveWorkspaces={workflow.hasActiveWorkspaces}
      executions={[]}
      actions={actions}
    />
  )
}

WorkflowItemWrapper.propTypes = {
  workflow: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    hasActiveWorkspaces: PropTypes.bool.isRequired,
    _updated: PropTypes.string.isRequired
  }).isRequired,
  onDelete: PropTypes.func.isRequired
}

export default WorkflowItemWrapper
