import {Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {formatNumber, formatSize} from 'util/file.js'

import ExecutionProgress from 'containers/ui/execution-progress.js'

const {Text} = Typography

function NodeOperationProgress({total, value, unit, step}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.OperationProgress'

  const percent = total ? Math.round(value / total * 100) : 100
  const fileSize = unit === 'bytes' ? formatSize(value) : null

  return (
    <Flex gap='small'>
      <Flex
        align='center'
        gap='small'
        wrap='wrap'
        flex={1}
      >
        <Flex align='center' gap='small'>
          {step && <Text italic>{t(`${keyPrefix}.step.${step}`)}</Text>}

          {value >= 0 && (
            <Text italic>
              {fileSize ? (
                t(`common:fileSizes.${fileSize.unit}`, {size: fileSize.value})
              ) : (
                t(`${keyPrefix}.progress`, {
                  value: formatNumber(value),
                  unit: t(`${keyPrefix}.unit.${unit}`, {count: value})
                }))}
            </Text>
          )}

          {total && total < 1_000_000_000 && (
            <ExecutionProgress
              percent={percent}
              type='circle'
              size={20}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

NodeOperationProgress.propTypes = {
  unit: PropTypes.oneOf(['rows', 'bytes']).isRequired,
  value: PropTypes.number,
  total: PropTypes.number,
  step: PropTypes.oneOf([
    'downloading',
    'archiving',
    'preparing',
    'unarchiving',
    'uploading',
    'reading',
    'writing'
  ])
}

export default NodeOperationProgress

