import {FundProjectionScreenOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import Section from 'components/layouts/section.js'

import Container from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'
import ProjectsList from 'containers/projects/projects-list.js'

export async function projectsLoader() {
  const [userProjects, organizations] = await Promise.all([
    api.getAllProjects()
  ])

  return {userProjects, organizations}
}

function Projects() {
  const {t} = useTranslation('translation', {keyPrefix: 'Projects'})

  const {userProjects} = useLoaderData()

  return (
    <>
      <Meta title={t('pageTitle')}/>
      <Container>
        <Section
          title={t('projectsListTitle')}
          icon={FundProjectionScreenOutlined}
        >
          <ProjectsList
            isUserMember
            projects={userProjects}
          />
        </Section>
      </Container>
    </>
  )
}

export default Projects
