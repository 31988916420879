import React from '@ant-design/icons'
import {Flex, Layout, theme} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SiderToggle from 'components/sider-toggle.js'

const {Sider} = Layout

const SiderLayoutStyled = styled(Layout)`
  position: relative;

  .content {
    position: relative;
  }

  .sider {
    box-shadow: ${({theme}) => theme.antd.boxShadowSiderLeft};
    z-index: 1;
  }
`
SiderLayoutStyled.displayName = 'SiderLayout'

function SiderLayout({isCollapsed, onToggle, siderContent, children}) {
  const {token} = theme.useToken()

  return (
    <SiderLayoutStyled>
      {siderContent && (
        <>
          <Sider
            collapsible
            theme='light'
            trigger={null}
            collapsed={isCollapsed}
            width={token.SiderLayout.width}
            collapsedWidth={0}
            className='sider'
          >
            {!isCollapsed && siderContent}
          </Sider>

          <SiderToggle
            isCollapsed={isCollapsed}
            onToggle={onToggle}
          />
        </>
      )}

      <Flex vertical flex={1} className='relative'>
        {children}
      </Flex>
    </SiderLayoutStyled>
  )
}

SiderLayout.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  siderContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
}

export default SiderLayout

