/* eslint-disable react/prop-types */

import {Tabs} from 'antd'
import styled from 'styled-components'

const styles = {
  tabBar: {
    margin: 0
  }
}

const NavigationContainer = styled.div`
  padding: ${({theme}) => `0 ${theme.antd.paddingLG}`}px;
  background-color: ${({theme}) => theme.antd.contentBgWhite};
`
NavigationContainer.displayName = 'NavigationContainer'

function TabsLayout({tabs, activeTab, onChangeTab, children}) {
  return (
    <>
      <NavigationContainer>
        <Tabs
          items={tabs}
          activeKey={activeTab}
          tabBarStyle={styles.tabBar}
          onChange={onChangeTab}
        />
      </NavigationContainer>
      {children}
    </>
  )
}

export default TabsLayout
