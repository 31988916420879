import {Flex, Input, Space, Typography, theme} from 'antd'
import {useTranslation} from 'react-i18next'

const {Link, Text} = Typography

function MarkdownTextArea({...props}) {
  const {t} = useTranslation('common', {keyPrefix: 'MarkdownTextArea'})
  const {token} = theme.useToken()

  return (
    <Flex vertical>
      <Input.TextArea style={{height: token.textAreaHeight}} {...props}/>
      <Space style={{alignSelf: 'end'}} size={4}>
        <Text style={{fontSize: 'x-small'}}>{t('placeholder')}</Text>
        <Link style={{fontSize: 'x-small'}} href='https://commonmark.org/help/' target='_blank'>
          Markdown
        </Link>
      </Space>
    </Flex>
  )
}

export default MarkdownTextArea
