import {ToolOutlined, CheckCircleOutlined, CloseCircleOutlined, RocketOutlined, LoadingOutlined, DisconnectOutlined} from '@ant-design/icons'
import {Tag, theme} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const OperationBadgeStyled = styled(Tag)`
  margin: 0;
`
OperationBadgeStyled.displayName = 'OperationBadge'

function OperationBadge({status = 'idle'}) {
  const {token} = theme.useToken()
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Operations.OperationBadge'})

  const statuses = {
    editing: {
      icon: <ToolOutlined/>,
      color: token.colorPrimary
    },
    success: {
      icon: <CheckCircleOutlined/>,
      color: token.colorSuccess
    },
    failed: {
      icon: <CloseCircleOutlined/>,
      color: token.colorError
    },
    prepared: {
      icon: <RocketOutlined/>,
      color: token.colorPrimaryBlue
    },
    running: {
      icon: <LoadingOutlined/>,
      color: token.colorPrimaryBrightBlue
    },
    disconnected: {
      icon: <DisconnectOutlined/>,
      color: token.colorGray100
    },
    idle: {
      icon: null,
      color: null
    }
  }

  return (
    <OperationBadgeStyled
      icon={statuses[status].icon}
      color={statuses[status].color}
    >
      {t(status)}
    </OperationBadgeStyled>
  )
}

OperationBadge.propTypes = {
  status: PropTypes.oneOf(['idle', 'editing', 'success', 'failed', 'prepared', 'running', 'disconnected'])
}

export default OperationBadge
