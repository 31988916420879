import {Descriptions, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

function Schema({schema}) {
  const {t} = useTranslation(['translation'], {keyPrefix: 'Project.Schema'})

  const items = schema.map(({name, type}) => ({
    label: name,
    children: type
  }))
  return (
    <Space direction='vertical'>
      <Typography.Title level={5}>{t('title')}</Typography.Title>
      <Descriptions bordered column={1} size='small' items={items}/>
    </Space>
  )
}

Schema.propTypes = {
  schema: PropTypes.array.isRequired
}

export default Schema
