/* eslint-disable react/prop-types */

import {useCallback, useEffect, useRef, useState} from 'react'

import {Alert, Typography, Flex} from 'antd'
import {useTranslation} from 'react-i18next'

import Counters, {countFoldersAndFiles} from 'components/ui/fileset-explorer/counters.js'

import Timer from 'containers/timer.js'

const {Text} = Typography

const TIME_DELTA = 20
const MAX_RATE_HISTORY = 10

function computeUploadedSize(array) {
  let uploadedSize = 0

  function recurse(files) {
    for (const file of files) {
      if (file.children) {
        recurse(file.children)
      } else if (file.isLeaf) {
        if (file.status === 'uploaded') {
          uploadedSize += file.size
        }

        uploadedSize += file.transferred || 0
      }
    }
  }

  recurse(array)

  return uploadedSize
}

function getRollingAverage(newRate, rateHistory) {
  // Add the new rate to the history
  const updatedHistory = [...rateHistory, newRate]

  // Ensure the history does not exceed the maximum length
  if (updatedHistory.length > MAX_RATE_HISTORY) {
    updatedHistory.shift() // Remove the oldest rate
  }

  // Calculate the rolling average
  const sum = updatedHistory.reduce((acc, rate) => acc + rate, 0)
  const averageRate = sum / updatedHistory.length

  return [averageRate, updatedHistory]
}

function getEstimatedEndTime(uploadedSize, remainingSize, initialUploadedSize, startTime, rateHistory) { // eslint-disable-line max-params
  const totalUploaded = uploadedSize - initialUploadedSize
  const now = new Date()
  const totalElapsedTime = (now - startTime) / 1000

  if (totalElapsedTime > 0) {
    const currentRate = totalUploaded / totalElapsedTime
    const [averageRate, updatedRateHistory] = getRollingAverage(currentRate, rateHistory)

    if (averageRate > 0 && Number.isFinite(averageRate)) {
      const estimatedTimeRemaining = remainingSize / averageRate

      if (Number.isFinite(estimatedTimeRemaining) && estimatedTimeRemaining >= 0 && estimatedTimeRemaining < 1e12) {
        const estimatedEndTime = new Date(now.getTime() + (estimatedTimeRemaining * 1000))
        return [estimatedEndTime, updatedRateHistory]
      }

      return [null, updatedRateHistory]
    }

    return [null, updatedRateHistory]
  }

  return [null, rateHistory]
}

function RemainingProgress({treeData}) {
  const {t} = useTranslation('translation', {keyPrefix: 'FilesetExplorer.RemainingProgress'})

  const [endTime, setEndTime] = useState(null)
  const [startTime, setStartTime] = useState(null)

  const filterFunction = useCallback(file => file.status !== 'uploaded', [])

  const currentContextTreeData = treeData.filter(({currentContext}) => currentContext === true)

  const {folderCount, fileCount} = countFoldersAndFiles(currentContextTreeData, filterFunction)
  const {totalSize} = countFoldersAndFiles(currentContextTreeData)
  const uploadedSize = computeUploadedSize(currentContextTreeData)
  const remainingSize = totalSize - uploadedSize

  const previousTotalCount = useRef(fileCount)
  const rateHistory = useRef([]) // Track the rate history
  const previousEndTimeRef = useRef(null) // Track the previous endTime
  const initialUploadedSize = useRef(computeUploadedSize(currentContextTreeData))

  useEffect(() => {
    setStartTime(prevTime => {
      if (uploadedSize > 0 && prevTime === null) {
        return new Date()
      }

      if (uploadedSize === 0) {
        return null
      }

      return prevTime
    })
  }, [totalSize, uploadedSize])

  useEffect(() => {
    const [newEndTime, newRateHistory] = getEstimatedEndTime(uploadedSize, remainingSize, initialUploadedSize.current, startTime, rateHistory.current)
    rateHistory.current = newRateHistory

    if (newEndTime) {
      // Compare the new endTime with the previous one
      const previousEndTime = previousEndTimeRef.current
      const timeDifference = previousEndTime ? Math.abs((newEndTime - previousEndTime) / 1000) : null

      if ((previousEndTime === null || timeDifference > TIME_DELTA) && rateHistory.current.length >= MAX_RATE_HISTORY) {
        setEndTime(newEndTime.toISOString())
        previousEndTimeRef.current = newEndTime // Update the reference to the current endTime
      }
    }
  }, [uploadedSize, remainingSize, startTime])

  useEffect(() => {
    if (fileCount > previousTotalCount.current) {
      previousTotalCount.current = fileCount
      rateHistory.current = [] // Reset the rate history
    }
  }, [fileCount])

  if (remainingSize === 0) {
    return null
  }

  return (
    <Alert
      message={(
        <Flex justify='space-between' wrap='wrap' gap='small'>
          <Flex gap='small'>
            <Text>{t('uploadingLeft')}</Text>
            <Counters folderCount={folderCount} fileCount={fileCount} totalSize={remainingSize}/>
          </Flex>

          <Flex gap='small'>
            <Text>{t('timeLeft')}</Text>
            <Timer endTime={endTime} mode='left'/>
          </Flex>
        </Flex>
      )}
      type='info'
    />
  )
}

export default RemainingProgress
