/* eslint-disable react/prop-types, no-unused-vars */

import {useEffect} from 'react'

import NoParamNeeded from 'components/display/operation-params/no-param-needed.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  useEffect(() => {
    async function autoPrepare() {
      try {
        await triggerPreparation()
      } catch (error) {
        console.error(error)
      }
    }

    if (inputContext) {
      autoPrepare()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NoParamNeeded/>
  )
}
