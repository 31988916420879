/* eslint-disable react/prop-types */

import {useCallback, useContext, useMemo, useState} from 'react'

import {Button, Flex, Space, Input, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import {buildTree} from 'util/file-tree.js'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import useField from 'hooks/use-field.js'

import TreeModal from 'components/browse/tree-modal.js'
import ValidationMessages from 'components/display/form/validation-messages.js'

const {Text} = Typography

function Display({name}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.FilesSelector'})
  const {params} = useContext(OperationParamsContext)

  return (
    <Space>
      <Text>{t('inputLabel')} :</Text>
      <Text strong>{params[name]}</Text>
    </Space>
  )
}

function Field({name, files, placeholder, validationMessages}) {
  const {t} = useTranslation(['translation', 'common'])
  const nsPrefix = 'Project.FilesSelector'

  const {initialParams, params, updateParam} = useContext(OperationParamsContext)
  const {value, initialValue, onChange, onChangeValue, handleSubmit} = useField({
    name,
    initialValue: params ? params[name] : initialParams[name],
    submit: updateParam
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [focusedFilePath, setFocusedFilePath] = useState(null)

  const treeData = useMemo(() => buildTree(files), [files])

  const handleConfirm = useCallback(() => {
    updateParam({name, value: focusedFilePath})
    onChangeValue(focusedFilePath)
    setIsModalOpen(false)
  }, [name, focusedFilePath, updateParam, onChangeValue])

  const handleCancel = () => {
    setFocusedFilePath(null)
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const handleFileSelect = ([filePath]) => {
    setFocusedFilePath(filePath)
  }

  return (
    <Flex vertical>
      <Text>{t(`${nsPrefix}.inputLabel`)}</Text>

      <Space.Compact block>
        <Input
          value={value}
          placeholder={initialValue || placeholder}
          status={validationMessages ? 'error' : 'default'}
          onChange={onChange}
          onBlur={handleSubmit}
        />
        <Button
          type='primary'
          onClick={openModal}
        >
          {t('common:button.selectFile')}
        </Button>
      </Space.Compact>

      <TreeModal
        title={t('common:selectFile')}
        isOpen={isModalOpen}
        treeData={treeData}
        isOkButtonDisabled={Boolean(!focusedFilePath)}
        onConfirmFileSelection={handleConfirm}
        onFileSelect={handleFileSelect}
        onClose={handleCancel}
      />
      <ValidationMessages validationMessages={validationMessages}/>
    </Flex>
  )
}

export default withEditable(Field, Display)
