import {createContext, useCallback, useEffect, useMemo, useState} from 'react'

import PropTypes from 'prop-types'

import api from 'services/api/index.js'

const UserContext = createContext()

function UserProvider({children}) {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  // Init session on mount
  useEffect(() => {
    async function initSession() {
      await api.init()
      setUser(api.user)
      setIsLoading(false)
    }

    initSession()
  }, [])

  const getAvatarURL = useCallback(size => api.getAvatarURL(user, size), [user])

  const logout = useCallback(async () => {
    await api.clearSession()
    setUser(null)
  }, [])

  const refreshUser = useCallback(async () => {
    if (await api.isAuthenticated()) {
      setIsLoading(true)
      await api.refreshUser()
      setUser(api.user)
      setIsLoading(false)
    }
  }, [])

  const value = useMemo(() => ({
    user, isLoading, getAvatarURL, refreshUser, logout
  }), [user, isLoading, getAvatarURL, refreshUser, logout])

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export {UserContext, UserProvider}
