/* eslint-disable react/prop-types */

import {Alert, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

const {Text, Paragraph} = Typography

const MAX_PATHS = 5

function UploadFailAlert({failPaths}) {
  const {t} = useTranslation('translation', {keyPrefix: 'FilesetExplorer.UploadFailAlert'})
  return (
    <Alert
      showIcon
      message={t('title')}
      description={(
        <div>
          <Text>{t('subtitle')}</Text>
          <Paragraph strong>{t('description')}</Paragraph>
          <ul>
            {failPaths.slice(0, MAX_PATHS).map(path => (
              <li key={path}>{path}</li>
            ))}
            {failPaths.length > MAX_PATHS && (
              <li>…</li>
            )}
          </ul>

          {failPaths.length > MAX_PATHS && (
            <Text italic>{t('remainingFailedUploads', {count: failPaths.length - MAX_PATHS})}</Text>
          )}
        </div>
      )}
      type='error'
    />
  )
}

export default UploadFailAlert
