/* eslint-disable react/prop-types */

import {useContext, useState} from 'react'

import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons'
import {Flex, Typography, Input} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'
import withPrivateField from 'hoc/with-private-field.js'

import useField from 'hooks/use-field.js'

import ValidationMessages from 'components/display/form/validation-messages.js'

const {Text} = Typography

const PasswordInputStyled = styled(Input)`
  -webkit-text-security: ${(({$isVisible}) => $isVisible ? 'none' : 'disc')};
  text-security: ${(({$isVisible}) => $isVisible ? 'none' : 'disc')};
`

function Display({label, name}) {
  const {t} = useTranslation('common')
  const {params} = useContext(OperationParamsContext)

  return (
    <Flex vertical>
      <Text>{label} :</Text>
      {params[name]
        ? <Text strong>{params[name]}</Text>
        : <Text italic>{t('notSpecified')}</Text>}
    </Flex>
  )
}

function Field({label, name, validationMessages}) {
  const {t} = useTranslation(['common'])
  const [isVisible, setIsVisible] = useState(false)
  const {params, initialParams, updateParam} = useContext(OperationParamsContext)

  const {value, onChange, handleSubmit} = useField({
    name,
    initialValue: params ? params[name] : initialParams[name],
    submit: updateParam
  })

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <Flex vertical>
      <Text>{label}</Text>
      <PasswordInputStyled
        name={name}
        $isVisible={isVisible}
        value={value}
        placeholder={t('common:form.password')}
        suffix={
          isVisible
            ? <EyeOutlined onClick={toggleVisibility}/>
            : <EyeInvisibleOutlined onClick={toggleVisibility}/>
        }
        onChange={onChange}
        onBlur={handleSubmit}
      />
      <ValidationMessages messages={validationMessages}/>
    </Flex>
  )
}

export default withEditable(withPrivateField(Field), Display)
