import {useContext, useEffect} from 'react'

import {Spin} from 'antd'
import {useNavigate} from 'react-router-dom'

import {getHomeUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import CenteredContent from 'components/layouts/centered-content.js'

function Logout() {
  const {user, logout} = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      logout()
    }

    navigate(getHomeUrl())
  }, [user, logout, navigate])

  return (
    <CenteredContent>
      <Spin/>
    </CenteredContent>
  )
}

export default Logout
