/* eslint-disable react/prop-types */

import {useContext, useRef, useState} from 'react'

import {Flex, Typography, Input as InputAntd, Tag} from 'antd'
import {uniq, zipObject} from 'lodash-es'
import {useTranslation} from 'react-i18next'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import useDebounce from 'hooks/use-debounce.js'

import ValidationMessages from 'components/display/form/validation-messages.js'

const {Text} = Typography

function mapColumnsValues(columns, detectedFirstRow) {
  return zipObject(detectedFirstRow, columns || detectedFirstRow)
}

function Display({label, name, detectedFirstRow}) {
  const {params} = useContext(OperationParamsContext)
  const columns = params[name] || detectedFirstRow

  return (
    <Flex vertical>
      <Text>{label} :</Text>
      <Flex vertical align='baseline' gap='small'>
        {columns.map(column => (
          <Tag key={column}>{column}</Tag>
        ))}
      </Flex>
    </Flex>
  )
}

function Field({name, detectedFirstRow, preview, validationMessages}) {
  const {t} = useTranslation('common', {keyPrefix: 'operations'})

  const {params, updateParam} = useContext(OperationParamsContext)

  const [columns, setColumns] = useState(mapColumnsValues(params[name], detectedFirstRow))

  const activeField = useRef(null)

  const handleChange = (field, value) => {
    setColumns(prevColumns => ({...prevColumns, [field]: value}))
  }

  const debouncedSubmit = useDebounce(() => {
    if (activeField.current === null) {
      updateParam({name, value: Object.keys(columns).map(key => columns[key] === '' ? key : columns[key])})
    }
  }, 300)

  const handleFocus = field => {
    activeField.current = field
  }

  const handleBlur = () => {
    activeField.current = null
    debouncedSubmit()
  }

  return (
    <Flex vertical>
      <Flex vertical gap='small'>
        {detectedFirstRow.map((field, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Flex key={index} vertical>
            <Text>{t('column', {index: index + 1})}</Text>
            <InputAntd
              value={columns[field]}
              placeholder={field}
              onChange={e => handleChange(field, e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <Text italic ellipsis type='secondary'>
              {uniq(preview.map(({properties}) => properties[field]))
                .filter(Boolean)
                .join(', ') || 'aucune valeur trouvée'}
            </Text>
          </Flex>
        ))}
      </Flex>
      <ValidationMessages validationMessages={validationMessages}/>
    </Flex>
  )
}

export default withEditable(Field, Display)
