import {Result, Spin} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import CenteredContent from 'components/layouts/centered-content.js'

import RetryButton from 'containers/ui/button/retry-button.js'

function EventsLoading({status}) {
  const {t} = useTranslation(['translation'], {keyPrefix: 'ProjectContent.EventsLoading'})

  if (status === 'disconnected') {
    return (
      <CenteredContent>
        <Result
          status='500'
          title={t('errorTitle')}
          subTitle={t('errorDescription')}
          extra={(
            <RetryButton
              type='primary'
              onClick={() => window.location.reload()}
            />
          )}
        />
      </CenteredContent>
    )
  }

  return (
    <Spin fullscreen delay={200} tip={t(`loadingTip.${status}`)}/>
  )
}

EventsLoading.propTypes = {
  status: PropTypes.string
}

export default EventsLoading
