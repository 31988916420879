import {Typography, theme} from 'antd'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

function Markdown({children}) {
  const {token} = theme.useToken()
  return (
    <div>
      <ReactMarkdown
        components={{
          h1(props) {
            return <Typography.Title level={2} {...props}/>
          },
          h2(props) {
            return <Typography.Title level={3} {...props}/>
          },
          h3(props) {
            return <Typography.Title level={4} {...props}/>
          },
          h4(props) {
            return <Typography.Title level={5} {...props}/>
          },
          h5(props) {
            return <Typography.Title style={{fontSize: token.fontSizeSM}} {...props}/>
          }
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  )
}

Markdown.propTypes = {
  children: PropTypes.string.isRequired
}

export default Markdown
