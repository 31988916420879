import {Button, Flex} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

function OperationPanelFooter({buttonProps, isDeleteDisabled, onDelete}) {
  const {t} = useTranslation(['translation', 'common'])

  return (
    <Flex
      justify='space-between'
      gap='small'
      wrap='wrap'
    >
      <Button
        danger
        disabled={isDeleteDisabled}
        onClick={onDelete}
      >
        {t('common:button.remove')}
      </Button>

      <Button
        type='primary'
        {...buttonProps}
      />
    </Flex>
  )
}

OperationPanelFooter.propTypes = {
  buttonProps: PropTypes.object.isRequired,
  isDeleteDisabled: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default OperationPanelFooter
