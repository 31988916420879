import {UserOutlined} from '@ant-design/icons'
import {Avatar, Flex, Space, Tag, Tooltip, Typography, theme} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getTimeSince} from 'util/date.js'

import UsersAvatarGroup from 'components/display/users-avatar-group.js'

const {Text} = Typography

const WorkspaceSummaryContainer = styled(Flex)`
  background-color: ${({theme}) => theme.antd.colorWhite};
  padding: ${({theme}) => theme.antd.paddingXS}px;
  border-radius: ${({theme}) => theme.antd.borderRadius}px;

  &:hover {
    cursor: pointer;
    box-shadow: ${({theme}) => theme.antd.boxShadow};
  }
`

function WorkspaceSummary({status, createdBy, activatedAt, stoppedAt, users}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkspaceSummary'
  const {token} = theme.useToken()

  const avatar = api.getAvatarURL(createdBy, 'small')

  const endTime = stoppedAt ? new Date(stoppedAt) : null
  const since = getTimeSince(new Date(activatedAt), endTime, true)

  const statusTag = {
    active: token.workspaceActiveColor,
    stopped: token.workspaceStoppedColor,
    pending: token.workspacePendingColor,
    stalled: token.workspaceStalledColor
  }

  return (
    <WorkspaceSummaryContainer vertical gap='small'>
      <Flex justify='space-between' align='center'>
        <Tooltip title={createdBy.fullName}>
          <Avatar
            style={{backgroundColor: token.colorBGAvatar}}
            src={avatar || <UserOutlined/>}
          />
        </Tooltip>
        <Tag color={statusTag[status]}>{t(`common:workspace.status.${status}`)}</Tag>
      </Flex>

      <Text>
        {stoppedAt ? t(`${keyPrefix}.activeFor`) : t(`${keyPrefix}.activeSince`) }
        {t('common:date.since', {since})}
      </Text>

      <Space>
        <Text>Utilisateurs : </Text>
        <UsersAvatarGroup profiles={users}/>
      </Space>
    </WorkspaceSummaryContainer>
  )
}

WorkspaceSummary.propTypes = {
  status: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  activatedAt: PropTypes.string.isRequired,
  stoppedAt: PropTypes.string,
  users: PropTypes.array.isRequired
}

export default WorkspaceSummary
