import {CheckCircleOutlined, ClockCircleOutlined, EditOutlined, LoadingOutlined, StopOutlined} from '@ant-design/icons'
import * as Archive from 'operations/fs/archive/index.js'
import * as Unarchive from 'operations/fs/unarchive/index.js'
import * as SimpleDownload from 'operations/input/simple-download/index.js'
import * as StoredFiles from 'operations/input/stored-files/index.js'
import * as OutputStoredFiles from 'operations/output/stored-files/index.js'
import * as ReadCsv from 'operations/read/csv/index.js'
import * as ReadGeoJSON from 'operations/read/geojson/index.js'
import * as ReadGpkg from 'operations/read/gpkg/index.js'
import * as ReadShp from 'operations/read/shp/index.js'
import * as TransformFilter from 'operations/transform/filter/index.js'
import * as TransformGeocodeAddresses from 'operations/transform/fr-geocode-ban/index.js'
import * as TransformMakePoint from 'operations/transform/make-point/index.js'
import * as WriteCsv from 'operations/write/csv/index.js'
import * as WriteGeojson from 'operations/write/geojson/index.js'

export const OPERATIONS = {}

for (const operation of [
  Archive,
  Unarchive,
  StoredFiles,
  OutputStoredFiles,
  SimpleDownload,
  ReadCsv,
  ReadGeoJSON,
  ReadGpkg,
  ReadShp,
  WriteCsv,
  WriteGeojson,
  TransformFilter,
  TransformMakePoint,
  TransformGeocodeAddresses
]) {
  OPERATIONS[`${operation.type}:${operation.label}`] = {
    ...operation,
    OperationParams: operation.Params,
    OperationExecution: operation.Execution
  }
}

const OPERATIONS_DOT = {
  editing: {dot: <EditOutlined/>, color: 'gray'}, // Operation must be edited before going further
  preparing: {dot: <ClockCircleOutlined/>, color: 'gray'}, // Preparation in progress (some milliseconds or seconds)
  prepared: {dot: <CheckCircleOutlined/>, color: 'green'}, // Preparation has succeeded and has fulfilled all the operation requirements
  pending: {dot: <LoadingOutlined/>, color: 'blue'}, // Execution has been asked
  executing: {dot: <LoadingOutlined/>, color: 'blue'}, // Execution in progress
  executed: {color: 'green'}, //  Execution has been successful
  failed: {color: 'red'}, // Execution has failed
  unavailable: {dot: <StopOutlined/>, color: 'red'} // Operation detached from active workflow (parent operations must be prepared/executed)
}

export function computeOperationStatus(operation) {
  const {status} = operation

  if (OPERATIONS_DOT[status]) {
    return OPERATIONS_DOT[status]
  }

  return null
}

export function convertOperationToTimelineItem(operation, OperationContainer, isLast) {
  const {OperationParams, type} = OPERATIONS[operation.type]
  const operationStatus = computeOperationStatus(operation)

  const dotAndColor = operationStatus

  return {
    ...dotAndColor,
    id: operation._id,
    type,
    children: (
      <OperationContainer
        operation={operation}
        OperationParams={OperationParams}
        isLast={isLast}
      />
    )
  }
}

export function getOperationType(operation) {
  return operation.type.split(':')[0]
}

export function isOperationCompatible(newOperation, previousOperation) {
  // First operation need to be an input operation
  if (!previousOperation) {
    return getOperationType(newOperation) === 'input'
  }

  // New operation input need to be compatible with previous operation output
  return newOperation.inputType && newOperation.inputType === previousOperation.outputType
}

function checkOperationsChainIntegrity(operations) {
  if (operations.length > 0) {
    const inputOperations = operations.filter(operation => getOperationType(operation) === 'input')

    if (!inputOperations) {
      throw new Error('Pipeline must have an input operation')
    } else if (inputOperations && inputOperations.length > 1) {
      throw new Error('Pipeline must have only one input operation')
    }
  }
}

export function groupRelatedOperations(operations) {
  checkOperationsChainIntegrity(operations)

  // Function to find the next operation in the chain based on current operation _id
  const findNextOperation = (currentId, visited) => operations.find(op =>
    op.input === currentId && !visited.has(op._id)
  )

  // Recursive function to build a chain of operations
  // 'operation' is the current operation, 'visited' keeps track of operations already in the chain
  // 'chain' is the current chain being built
  const buildChain = (operation, visited = new Set(), chain = []) => {
    chain.push(operation) // Add the current operation to the chain
    visited.add(operation._id) // Mark the current operation as visited

    const nextOperation = findNextOperation(operation._id, visited)
    if (nextOperation) {
      // If there is a next operation, continue building the chain recursively
      return buildChain(nextOperation, visited, chain)
    }

    // Once there are no more operations to add, return the completed chain
    return chain
  }

  // Start with operations that are either without input or have a broken related status
  // and build chains from them
  return operations
    .filter(op => !op.input || op.relationStatus === 'broken')
    .map(op => buildChain(op))
    .sort((a, b) => new Date(a[0]._created) - new Date(b[0]._created))
}

export function updateOperationRelation(newOperation, operations) {
  if (newOperation.input) {
    const operationWithSameRelation = operations.find(operation => operation.input === newOperation.input)
    if (operationWithSameRelation) {
      return {
        ...operationWithSameRelation,
        input: newOperation._id
      }
    }
  }
}
