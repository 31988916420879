import {useContext, useMemo, useState} from 'react'

import {FundProjectionScreenOutlined, TeamOutlined} from '@ant-design/icons'
import {Flex, message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import {replaceEmptyStringsWithNull} from 'util/form.js'

import {UserContext} from 'contexts/user.js'

import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'

import Meta from 'containers/layout/meta.js'
import ProjectsList from 'containers/projects/projects-list.js'
import MembersList from 'containers/user/organizations/members-list.js'
import Summary from 'containers/user/organizations/summary.js'

export async function organizationLoader({params}) {
  const [organization, projects] = await Promise.all([
    api.getOrganization(params.organizationId),
    api.getOrganizationProjects(params.organizationId)
  ])

  return {organization, projects}
}

function Organization() {
  const {t} = useTranslation('translation', {keyPrefix: 'Organization'})

  const {user} = useContext(UserContext)

  const loaderData = useLoaderData()

  const [messageApi, contextHolder] = message.useMessage()

  const [members, setMembers] = useState(loaderData.organization.members)
  const [organization, setOrganization] = useState(loaderData.organization)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isUserMember = useMemo(() => loaderData.organization.members.some(({_id}) => user._id === _id), [loaderData.organization.members, user._id])

  const updateOrganization = async values => {
    setIsLoading(true)
    try {
      const updatedOrganization = await api.updateOrganization(organization._id, replaceEmptyStringsWithNull(values))
      setOrganization(updatedOrganization)
      setIsEditing(false)
    } catch (error) {
      messageApi.error(t('organizationUpdateError', {error}))
    }

    setIsLoading(false)
  }

  const updateAvatar = async avatar => {
    try {
      const updatedOrganization = avatar
        ? await api.updateOrganizationAvatar(organization._id, avatar)
        : await api.deleteOrganizationAvatar(organization._id)
      setOrganization(updatedOrganization)
    } catch (error) {
      messageApi.error(t('organizationUpdateError', {error}))
    }
  }

  return (
    <>
      <Meta title={organization.displayName}/>

      {contextHolder}

      <SidebarLayout
        isSidebarFirst
        main={(
          <Flex vertical gap='middle'>
            <Section
              title={t('projectsSectionTitle')}
              icon={FundProjectionScreenOutlined}
            >
              <ProjectsList projects={loaderData.projects}/>
            </Section>
            <Section
              title={t('membersSectionTitle')}
              icon={TeamOutlined}
            >
              <MembersList
                isUserMember={isUserMember}
                members={members}
                setMembers={setMembers}
                organization={organization}
                setOrganization={setOrganization}
                message={messageApi}
                t={t}
              />
            </Section>
          </Flex>
        )}
        sidebar={(
          <Section>
            <Summary
              isEditable={isUserMember}
              isEditing={isEditing}
              organization={organization}
              handleEditing={setIsEditing}
              updateOrganization={updateOrganization}
              updateAvatar={updateAvatar}
              isLoading={isLoading}
            />
          </Section>
        )}
      />
    </>
  )
}

export default Organization
