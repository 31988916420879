import {DeleteOutlined, EditOutlined, EnvironmentOutlined, TeamOutlined} from '@ant-design/icons'
import {Button, Flex, Form, Skeleton, Input, Divider, Space} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import OrganizationAvatar from 'components/display/organization-avatar.js'
import UsersAvatarGroup from 'components/display/users-avatar-group.js'
import ProfileInfo from 'components/user/profile-info.js'

import AvatarUpload from 'containers/user/avatar-upload.js'

function Summary({
  organization,
  isEditable,
  isEditing,
  handleEditing,
  updateOrganization,
  updateAvatar,
  isLoading
}) {
  const {t} = useTranslation('common')

  const {displayName, location, members} = organization
  const avatar = api.getAvatarURL(organization, 'large')

  return (
    <Skeleton active avatar loading={isLoading}>
      <Flex vertical>
        <Form
          initialValues={organization}
          layout='vertical'
          onFinish={updateOrganization}
        >

          <Flex vertical justify='center' gap='small'>

            {isEditing ? (
              <Flex vertical align='center' gap='small'>
                <AvatarUpload
                  avatar={avatar}
                  uploadAvatar={updateAvatar}
                />
                <Form.Item
                  name='displayName'
                  rules={[{required: true, message: t('form.requiredField')}]}
                >
                  <Input
                    type='text'
                    placeholder={displayName}
                    prefix={<TeamOutlined/>}
                  />
                </Form.Item>
                <Button danger icon={<DeleteOutlined/>} onClick={() => updateAvatar()}>
                  {t('common:button.remove')}
                </Button>
              </Flex>
            ) : (
              <OrganizationAvatar fullName={displayName} avatar={avatar}/>
            )}

            {isEditable && !isEditing && (
              <Button icon={<EditOutlined/>} onClick={() => handleEditing(true)}>
                {t('common:button.edit')}
              </Button>
            )}
          </Flex>

          <Divider/>

          <Flex vertical gap='large'>

            <ProfileInfo
              icon={EnvironmentOutlined}
              title={t('common:bio.location')}
              value={
                isEditing ? (
                  <Form.Item
                    name='location'
                  >
                    <Input
                      type='text'
                      placeholder={location}
                      prefix={<EnvironmentOutlined/>}
                    />
                  </Form.Item>
                ) : (
                  location
                )
              }
            />

            {!isEditing && (
              <ProfileInfo
                icon={TeamOutlined}
                title={t('common:bio.members')}
                value={(
                  <UsersAvatarGroup profiles={members}/>
                )}
              />
            )}
          </Flex>

          {isEditing && (
            <Form.Item>
              <Space>
                <Button onClick={() => handleEditing(false)}>
                  {t('common:button.cancel')}
                </Button>

                <Button type='primary' htmlType='submit'>
                  {t('common:button.save')}
                </Button>
              </Space>
            </Form.Item>
          )}
        </Form>
      </Flex>
    </Skeleton>
  )
}

Summary.propTypes = {
  organization: PropTypes.object,
  isEditable: PropTypes.bool,
  isEditing: PropTypes.bool,
  handleEditing: PropTypes.func,
  updateOrganization: PropTypes.func,
  updateAvatar: PropTypes.func,
  isLoading: PropTypes.bool
}

export default Summary
