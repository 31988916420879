import {useContext} from 'react'

import {Layout, Spin} from 'antd'
import PropTypes from 'prop-types'
import {HelmetProvider} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {Outlet} from 'react-router-dom'
import styled from 'styled-components'

import {PageContextProvider} from 'contexts/page-context.js'
import StyleConfigProvider from 'contexts/style-config-provider.js'
import {UserContext} from 'contexts/user.js'

import CenteredContent from 'components/layouts/centered-content.js'
import Footer from 'components/layouts/footer.js'

import Header from 'containers/layout/header.js'
import Meta from 'containers/layout/meta.js'

const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  padding: 0;
`

function Page({children}) {
  const {isLoading} = useContext(UserContext)
  const {t} = useTranslation('translation')

  return (
    <StyleConfigProvider>
      <PageContextProvider>
        <HelmetProvider>
          <Layout>
            <Meta title='Living Data' description={t('catchphrase')}/>
            <Header/>

            <Content>
              {isLoading ? (
                <CenteredContent>
                  <Spin size='large'/>
                </CenteredContent>
              ) : (
                children || <Outlet/>
              )}
            </Content>

            <Footer/>
          </Layout>
        </HelmetProvider>
      </PageContextProvider>
    </StyleConfigProvider>
  )
}

Page.propTypes = {
  children: PropTypes.node
}

export default Page
