/* eslint-disable react/prop-types */

import {useContext} from 'react'

import {RollbackOutlined} from '@ant-design/icons'
import {Flex, Typography, Input} from 'antd'
import {useTranslation} from 'react-i18next'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import useField from 'hooks/use-field.js'

import ValidationMessages from 'components/display/form/validation-messages.js'

const {Text} = Typography
const {TextArea} = Input

function Display({label, name}) {
  const {t} = useTranslation('common')
  const {params} = useContext(OperationParamsContext)

  return (
    <Flex vertical>
      <Text>{label} :</Text>
      {params[name]
        ? <Text strong ellipsis>{params[name]}</Text>
        : <Text italic>{t('notSpecified')}</Text>}
    </Flex>
  )
}

function Field({label, name, placeholder, validationMessages}) {
  const {initialParams, params, updateParam} = useContext(OperationParamsContext)

  const {value, initialValue, onChange, reset, handleSubmit} = useField({
    name,
    initialValue: params ? params[name] : initialParams[name],
    submit: updateParam
  })

  const hasPreviousValue = value === '' && initialValue

  return (
    <Flex vertical>
      <Text>{label}</Text>
      <TextArea
        value={value}
        placeholder={initialValue || placeholder}
        status={validationMessages ? 'error' : 'default'}
        suffix={
          <RollbackOutlined
            style={{
              display: hasPreviousValue ? 'block' : 'none',
              color: 'rgba(0,0,0,.45)',
              cursor: 'pointer'
            }}
            onClick={reset}
          />
        }
        onChange={onChange}
        onBlur={handleSubmit}
      />
      <ValidationMessages
        validationMessages={validationMessages}
      />
    </Flex>
  )
}

export default withEditable(Field, Display)
