/* eslint-disable react/prop-types */

import React, {useEffect, useRef, useState} from 'react'

import {PlusCircleOutlined} from '@ant-design/icons'
import {Button, Flex} from 'antd'
import {useTranslation} from 'react-i18next'

const styles = {
  uploadButton: {width: 'min-content'}
}

function FileInput({addFile}) {
  const {t} = useTranslation('translation', {keyPrefix: 'FilesetExplorer.FileInput'})

  const [isFirefox, setIsFirefox] = useState(false)

  useEffect(() => {
    const isFirefoxBrowser = navigator.userAgent.toLowerCase().includes('firefox')
    setIsFirefox(isFirefoxBrowser)
  }, [])

  const inputFileRef = useRef(null)
  const inputFolderRef = useRef(null)

  const handleUploadClick = inputRef => {
    // Redirect the click event onto the hidden input element
    inputRef.current?.click()
  }

  const handleFileChange = e => {
    const {files} = e.target
    for (const file of files) {
      addFile(file)
    }
  }

  return (
    <Flex wrap='wrap' gap='small'>
      <input
        ref={inputFolderRef}
        webkitdirectory=''
        type='file'
        style={{display: 'none'}}
        onChange={handleFileChange}
      />

      {isFirefox ? (
        <>
          <Button
            style={styles.uploadButton}
            type='primary'
            icon={<PlusCircleOutlined/>}
            onClick={() => handleUploadClick(inputFileRef)}
          >
            {t('addFilesButton')}
          </Button>

          <input
            ref={inputFileRef}
            multiple
            type='file'
            style={{display: 'none'}}
            onChange={handleFileChange}
          />

          <Button
            style={styles.uploadButton}
            type='primary'
            icon={<PlusCircleOutlined/>}
            onClick={() => handleUploadClick(inputFolderRef)}
          >
            {t('addFoldersButton')}
          </Button>
        </>
      ) : (
        <Button
          style={styles.uploadButton}
          type='primary'
          icon={<PlusCircleOutlined/>}
          onClick={() => handleUploadClick(inputFolderRef)}
        >
          {t('addFilesButton')}
        </Button>
      )}
    </Flex>
  )
}

export default React.memo(FileInput)
