import {Alert, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import Timer from 'containers/timer.js'

const {Text} = Typography

function ExecutionTime({startedAt, finishedAt}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.OperationSider.OperationResult.ExecutionTime'})

  return (
    <Alert
      type='info'
      message={(
        <Space>
          <Text>{t('title')}</Text>
          <Timer
            startTime={startedAt}
            endTime={finishedAt}
          />
        </Space>
      )}
    />
  )
}

ExecutionTime.propTypes = {
  startedAt: PropTypes.string,
  finishedAt: PropTypes.string
}

export default ExecutionTime
