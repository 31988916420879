import React from 'react'

import PropTypes from 'prop-types'

import CustomHandle from 'containers/react-flow/custom-handle.js'
import NodeContainer from 'containers/react-flow/node-container.js'

function CustomNode({data, sourcePosition, targetPosition, ...props}) {
  return (
    <NodeContainer data={data} {...props}>
      <CustomHandle
        id='left'
        type='target'
        position={targetPosition}
      />
      <CustomHandle
        id='right'
        type='source'
        position={sourcePosition}
      />
    </NodeContainer>
  )
}

CustomNode.propTypes = {
  data: PropTypes.shape({
    operation: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isCompleted: PropTypes.bool
    }).isRequired
  }).isRequired,
  sourcePosition: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired
}

export default CustomNode
