import {Col, Flex, Row, Typography} from 'antd'
import PropTypes from 'prop-types'

import FileSize from 'components/ui/file-size.js'

const {Text} = Typography

const styles = {
  col: {
    height: '100%'
  }
}

function TreeDataFile({title, size, totalSize, children}) {
  return (
    <Row wrap={false}>
      <Col span={18}>
        <Text>{title}</Text>
      </Col>

      <Col span={6} className={children ? 'directory-meta' : ''}>
        <Flex justify='end' align='center' style={styles.col}>
          {typeof size === 'number' && size >= 0 && (
            <Text italic type='secondary'>
              <FileSize bytes={totalSize || size}/>
            </Text>
          )}
        </Flex>
      </Col>
    </Row>
  )
}

TreeDataFile.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.number,
  totalSize: PropTypes.number,
  children: PropTypes.array
}

export default TreeDataFile
