import mitt from 'mitt'

import {createEventSource} from '../../../util/sse.js'

export const API_URL = process.env.REACT_APP_API_URL

const DEBUG_EVENTS = process.env.REACT_APP_DEBUG_EVENTS === '1'

if (!API_URL) {
  throw new Error('REACT_APP_API_URL must be set')
}

export async function executeRequest({client, url, method, body, ignoreInit}) {
  const fetchOptions = {
    mode: 'cors',
    method: method || 'GET',
    headers: {}
  }

  if (!ignoreInit) {
    await client.init()
  }

  if (client.session) {
    fetchOptions.headers.Authorization = `Session ${client.session.token}`
  }

  if (body && body instanceof Blob) {
    fetchOptions.headers['Content-Type'] = body.type || 'application/octet-stream'
    fetchOptions.body = body
  } else if (body) {
    fetchOptions.headers['Content-Type'] = 'application/json'
    fetchOptions.body = JSON.stringify(body)
  }

  const response = await fetch(`${API_URL}${url}`, fetchOptions)

  if (response.status === 401) {
    await client.clearSession()
    throw createHttpError(401, 'Not authenticated')
  }

  if (!response.ok) {
    if (response.headers.get('Content-Type')?.startsWith('application/json')) {
      const errorBody = await response.json()
      const error = new Error(errorBody.message)
      error.code = errorBody.code
      error.details = errorBody.details
      throw error
    }

    const error = new Error(response.statusText)
    error.code = response.status
    throw error
  }

  if (response.headers.get('Content-Type')?.startsWith('application/json')) {
    return response.json()
  }
}

export function createHttpError(code, message) {
  const error = new Error(message)
  error.code = code
  return error
}

export function createEventChannel(channel, client) {
  if (!client.session) {
    throw new Error('Not authenticated')
  }

  const eventSourceProxy = mitt()

  let eventSource

  let listenersCount = 0
  let cleanupTimeout

  let messageId = 1

  function initEventSource() {
    eventSource = createEventSource(`${API_URL}${channel}`, {
      headers: {
        Authorization: `Session ${client.session.token}`
      }
    })

    eventSource.on('message', message => {
      if (message.type === 'heartbeat') {
        return
      }

      const messageWithId = {...message, messageId: messageId++}

      if (DEBUG_EVENTS) {
        console.log('Event', channel, messageWithId)
      }

      eventSourceProxy.emit('message', messageWithId)
    })

    eventSource.on('status', status => eventSourceProxy.emit('status', status))

    eventSource.connect()
  }

  function cleanupEventSource() {
    eventSource.close()
    eventSource = undefined
  }

  initEventSource()

  function refreshCleanupTimeout() {
    clearTimeout(cleanupTimeout)

    cleanupTimeout = setTimeout(() => {
      if (listenersCount === 0) {
        try {
          cleanupEventSource()
        } catch {}
      }
    }, 5000)
  }

  refreshCleanupTimeout()

  return {
    on(eventType, listener) {
      if (!eventSource) {
        initEventSource()
      }

      eventSourceProxy.on(eventType, listener)

      listenersCount++
    },

    off(eventType, listener) {
      eventSourceProxy.off(eventType, listener)

      listenersCount--

      if (listenersCount === 0) {
        refreshCleanupTimeout()
      }
    },

    get status() {
      return eventSource?.status || 'disconnected'
    }
  }
}
