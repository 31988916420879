/* eslint-disable react/prop-types */

import {DeleteOutlined, DownloadOutlined} from '@ant-design/icons'
import {Button, Flex, Space, Checkbox} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import Counters, {countFoldersAndFiles} from 'components/ui/fileset-explorer/counters.js'

const ActionsContainer = styled(Flex)`
  background-color: ${({theme}) => theme.antd.contentBgLight};
  padding: ${({theme}) => theme.antd.paddingXS}px;
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
`

function Actions({
  treeData,
  isAllKeysChecked,
  checkedKeys,
  onCheckAll,
  onDelete,
  onDownload
}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetExplorer.Actions'

  const hasKeysChecked = checkedKeys.length > 0
  const counters = countFoldersAndFiles(treeData, file => checkedKeys.includes(file.key), [checkedKeys])

  return (
    <ActionsContainer vertical>
      <Flex justify='space-between' align='center'>
        <Checkbox checked={isAllKeysChecked} onChange={onCheckAll}>
          {t(`${keyPrefix}.selectAll`)}
        </Checkbox>
        <Space>
          {onDownload && (
            <Button
              type='primary'
              icon={<DownloadOutlined/>}
              disabled={!hasKeysChecked}
              onClick={onDownload}
            >
              {t('common:button.download')}
            </Button>
          )}
          {onDelete && (
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined/>}
              disabled={!hasKeysChecked}
              onClick={onDelete}
            >
              {t('common:button.remove')}
            </Button>
          )}
        </Space>
      </Flex>

      {hasKeysChecked && (
        <Counters {...counters}/>
      )}
    </ActionsContainer>
  )
}

export default Actions
