import {Typography, Space, Flex} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import Markdown from 'components/markdown.js'

import Container from 'containers/layout/container.js'
import VisibilityTag from 'containers/ui/visibility-tag.js'

const {Title} = Typography

function ProjectSummary({title, visibility, description}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectSummary'})

  return (
    <Container background='secondary'>
      <Flex vertical>
        <Space
          size='large'
          align='center'
        >
          <Title level={1}>{title}</Title>
          <VisibilityTag visibility={visibility}/>
        </Space>
        <Markdown>{description || t('description')}</Markdown>
      </Flex>
    </Container>
  )
}

ProjectSummary.propTypes = {
  title: PropTypes.string.isRequired,
  visibility: PropTypes.oneOf(['public', 'private']).isRequired,
  description: PropTypes.string
}

export default ProjectSummary
