import {useCallback, useEffect, useMemo, useState} from 'react'

import {DownOutlined} from '@ant-design/icons'
import {Empty, Input} from 'antd'
import {deburr} from 'lodash-es'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {buildTree} from 'util/file-tree.js'

import useDebounce from 'hooks/use-debounce.js'

import DropdownWrapper from 'components/display/dropdown-wrapper.js'
import DirectoryTree from 'components/ui/directory-tree.js'
import TreeDataFile from 'components/ui/file-explorer/tree-data-file.js'

function normalizeString(string) {
  return deburr(string.toLowerCase())
}

const TreeContainer = styled.div`
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingXXS}px 0;
`

// Get all keys from a tree data recursively
function getAllKeys(data, keys = []) {
  for (const node of data) {
    keys.push(node.key)
    if (node.children) {
      getAllKeys(node.children, keys)
    }
  }

  return keys
}

const fieldNames = {title: 'title', key: 'key', children: 'children', size: 'size'}

function FileExplorer({files, modalTitle}) {
  const {t} = useTranslation('translation', {keyPrefix: 'FileExplorer'})

  const defaultTreeData = useMemo(() => buildTree(files), [files])

  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [treeData, setTreeData] = useState(defaultTreeData)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [manualExpandedKeys, setManualExpandedKeys] = useState([])

  const hasDirectory = files.some(({path}) => path.includes('/'))

  const filtreFiles = useCallback(() => {
    if (searchInput.length > 0) {
      const filteredTreeData = files.filter(({path}) =>
        normalizeString(path).includes(normalizeString(searchInput))
      )

      const newTreeData = buildTree(filteredTreeData)
      setTreeData(newTreeData)
      setIsLoading(false)

      const allKeys = getAllKeys(newTreeData)
      setExpandedKeys(allKeys)
    } else {
      setTreeData(defaultTreeData)
      setIsLoading(false)
      setExpandedKeys([])
    }
  }, [files, searchInput, defaultTreeData])

  const debouncedSearchInput = useDebounce(filtreFiles, 800)

  const handleChange = event => {
    const {value} = event.target
    setIsLoading(value.length > 0)
    setSearchInput(value)
    debouncedSearchInput()
  }

  // Reset search input when the tree data changes
  useEffect(() => {
    setSearchInput('')
    setTreeData(defaultTreeData)
    setExpandedKeys([])
  }, [defaultTreeData])

  const combinedExpandedKeys = searchInput.length > 0 ? [...new Set([...expandedKeys, ...manualExpandedKeys])] : manualExpandedKeys

  return (
    <DropdownWrapper
      title={modalTitle}
      isOpen={isOpen}
      toggleOpen={() => setIsOpen(!isOpen)}
    >
      {files.length > 2 && (
        <Input.Search
          allowClear
          value={searchInput}
          loading={isLoading}
          placeholder={t('searchPlaceholder')}
          onChange={handleChange}
        />
      )}

      <TreeContainer>
        {treeData.length > 0 ? (
          <DirectoryTree
            showLine={hasDirectory}
            selectable={false}
            autoExpandParent={searchInput.length > 0}
            fieldNames={fieldNames}
            expandedKeys={combinedExpandedKeys}
            switcherIcon={<DownOutlined/>}
            height={400}
            treeData={treeData}
            titleRender={TreeDataFile}
            onExpand={setManualExpandedKeys}
          />
        ) : (
          <Empty description={t('noFile')}/>
        )}
      </TreeContainer>
    </DropdownWrapper>
  )
}

FileExplorer.propTypes = {
  files: PropTypes.array.isRequired,
  modalTitle: PropTypes.string.isRequired
}

export default FileExplorer
