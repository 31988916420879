/* eslint-disable react/prop-types */

import {useRef, useState, useTransition, useEffect} from 'react'

import {Empty, Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const {Text} = Typography

const DropZoneContainer = styled(Flex)`
    flex: 1;
    overflow: auto;

    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    border-color: ${({theme}) => theme.antd.colorPrimary};

    &.drag-over {
      border-color: ${({theme}) => theme.antd.colorPrimary};
      border: 1px dashed ${({theme}) => theme.antd.colorBorder};
      padding: ${({theme}) => theme.antd.paddingXXS}px;
    }

    &.drag-over {
      background-color: ${({theme}) => theme.antd.colorWhiteSmoke};
    }
    
    & .empty-drop-zone {
        border: 1px dashed ${({theme}) => theme.antd.colorBorder};
        border-radius: ${({theme}) => theme.antd.borderRadius}px;
        cursor: copy;
        transition: border-color 0.3s ease;
    }

    & .empty-drop-zone:hover {
        border: 1px dashed ${({theme}) => theme.antd.colorPrimary};
    }
  }
`

const styles = {
  inputFile: {
    display: 'none'
  }
}

function DropZone({children, addFile, addItems}) {
  const {t} = useTranslation(['translation'])
  const translationPrefix = 'Project.SourceImport.FilesUpload'

  const [isDragOver, setIsDragOver] = useState(false)
  const inputRef = useRef(null)
  const dragCounter = useRef(0)

  const [, startTransition] = useTransition()

  useEffect(() => {
    const handleDragEnter = event => {
      event.preventDefault()
      event.stopPropagation()
      dragCounter.current++
      setIsDragOver(true)
    }

    const handleDragLeave = event => {
      event.preventDefault()
      event.stopPropagation()
      dragCounter.current--
      if (dragCounter.current === 0) {
        setIsDragOver(false)
      }
    }

    const handleDragOver = event => {
      event.preventDefault()
      event.stopPropagation()
    }

    const handleDrop = async e => {
      e.preventDefault()
      const {items} = e.dataTransfer

      addItems(items)
      setIsDragOver(false)
    }

    document.addEventListener('dragenter', handleDragEnter)
    document.addEventListener('dragleave', handleDragLeave)
    document.addEventListener('dragover', handleDragOver)
    document.addEventListener('drop', handleDrop)

    return () => {
      document.removeEventListener('dragenter', handleDragEnter)
      document.removeEventListener('dragleave', handleDragLeave)
      document.removeEventListener('dragover', handleDragOver)
      document.removeEventListener('drop', handleDrop)
    }
  }, [addItems])

  const handleFileInputChange = e => {
    startTransition(() => {
      const {files} = e.target
      for (const file of files) {
        addFile(file)
      }
    })
  }

  const handleClick = () => {
    if (!children) {
      inputRef.current?.click()
    }
  }

  return (
    <DropZoneContainer
      vertical
      gap='small'
      className={`${isDragOver ? 'drag-over' : ''}`}
      onClick={handleClick}
    >
      <input
        ref={inputRef}
        multiple
        webkitdirectory=''
        type='file'
        style={styles.inputFile}
        onChange={handleFileInputChange}
      />

      {children || (
        <Flex
          flex={1}
          justify='center'
          align='center'
          className='empty-drop-zone'
        >
          <Empty
            image={isDragOver ? Empty.PRESENTED_IMAGE_DEFAULT : Empty.PRESENTED_IMAGE_SIMPLE}
            description={(
              <Text>{t(`${translationPrefix}.draggerTip`)}</Text>
            )}
          >
            <Text type='secondary'>
              {t(`${translationPrefix}.draggerInfo`)}
            </Text>
          </Empty>
        </Flex>
      )}
    </DropZoneContainer>
  )
}

export default DropZone
