import {useContext} from 'react'

import {useLoaderData, useSearchParams} from 'react-router-dom'

import api from 'services/api/index.js'

import ProjectContext from 'contexts/project.js'
import {WorkflowProvider} from 'contexts/workflow-context.js'

import WorkflowEditor from 'containers/workflow-editor.js'

export async function workflowLoader({params: {workflowId}}) {
  const workflow = await api.getWorkflow(workflowId)

  return {workflow}
}

function Workflow() {
  const {workflow} = useLoaderData()

  const [searchParams] = useSearchParams()
  const workspaceId = searchParams.get('workspaceId')

  const {project, eventSource} = useContext(ProjectContext)

  return (
    <WorkflowProvider
      initialValue={workflow}
      projectId={project._id}
      workspaceId={workspaceId}
      eventSource={eventSource}
    >
      <WorkflowEditor/>
    </WorkflowProvider>
  )
}

export default Workflow
