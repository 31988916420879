import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import Invitation from './invitation.js'

function InvitationsList({invitations, updateInvitations, updateOrganizations, user, message}) {
  const {t} = useTranslation('translation', {keyPrefix: 'UserInvitationsList'})

  return (
    <div>
      {invitations.map(invitation => (
        <Invitation
          key={invitation._id}
          invitation={invitation}
          updateInvitations={updateInvitations}
          updateOrganizations={updateOrganizations}
          user={user}
          message={message}
          t={t}
        />
      ))}
    </div>
  )
}

InvitationsList.propTypes = {
  invitations: PropTypes.array.isRequired,
  updateInvitations: PropTypes.func.isRequired,
  updateOrganizations: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
}

export default InvitationsList
