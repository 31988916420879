import {useContext, useEffect, useState} from 'react'

import OperationParamsContext from 'contexts/operation-params-context.js'

function useField({name, initialValue, submit}) {
  const {setHasFormValuesChanged} = useContext(OperationParamsContext)

  const [value, setValue] = useState(initialValue)
  const [hasChanged, setHasChanged] = useState(false)

  const onChange = e => {
    const {value} = e.target
    setValue(value)
  }

  const onChangeValue = value => {
    setValue(value)
  }

  const reset = () => {
    setValue(initialValue)
    submit({name, value: initialValue})
  }

  const handleSubmit = () => {
    if (submit) {
      submit({name, value: value === '' ? undefined : value})
    }
  }

  useEffect(() => {
    const hasChanged = value !== initialValue
    setHasChanged(hasChanged)
    setHasFormValuesChanged(hasChanged)
  }, [value, initialValue, setHasFormValuesChanged])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return {value, initialValue, hasChanged, onChange, onChangeValue, reset, handleSubmit}
}

export default useField
