export function getFailedChecks(preparationContext) {
  const errors = {}
  if (preparationContext?.checks) {
    const {checks} = preparationContext

    const failedChecks = checks.filter(check => check.status === 'failed')

    for (const check of failedChecks) {
      errors[check.name] = check.error || check.message
    }
  }

  return errors
}

export function checkKeysExist(obj, keys) {
  const {hasOwnProperty} = Object.prototype
  return keys.some(key => hasOwnProperty.call(obj, key))
}
