import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'

import {Alert, Flex, Spin} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import OperationParamsContext from 'contexts/operation-params-context.js'
import WorkflowContext from 'contexts/workflow-context.js'

import NoInputContext from 'components/display/operation-params/no-input-context.js'

import DebugParams from 'containers/operations/debug-params.js'

export const TimelineContext = createContext()

function OperationParamsWrapper({
  operation,
  inputType = 'none',
  OperationParams,
  prepare,
  preparation,
  operationLabel
}) {
  const {t} = useTranslation(['operations', 'common'])

  const {workspace} = useContext(WorkflowContext)
  const {params, handleUpdate} = useContext(OperationParamsContext)

  const [inputContext, setInputContext] = useState(null)
  const [isLoading, setIsLoading] = useState(workspace.isActive)

  const Params = useMemo(() => OperationParams || DebugParams, [OperationParams])

  const inputContextType = getInputContextType(inputType)
  const isInputContextOk = inputContextType === 'none' || Boolean(inputContext)

  useEffect(() => {
    if (operation.status) {
      setIsLoading(['pending', 'preparing'].includes(operation.status))
    } else {
      setIsLoading(false)
    }
  }, [operation.status])

  useEffect(() => {
    async function fetchInputContext() {
      if (inputContextType === 'none') {
        setInputContext(null)
        setIsLoading(false)
        return
      }

      setIsLoading(true)

      try {
        const inputContextHolder = inputContextType === 'preparation'
          ? await api.getPreparation(operation.input)
          : await api.getExecution(operation.input)

        setInputContext(inputContextHolder?.result)
      } catch {
        setInputContext(null)
      }

      setIsLoading(false)
    }

    function watchInputContextUpdate(message) {
      const {type, operation: operationId, changes} = message

      if (operationId !== operation.input || type !== 'operation:updated') {
        return
      }

      if (changes.hash || changes.status) {
        fetchInputContext()
      }
    }

    if (operation.input && workspace.isActive) {
      if (workspace.eventSource) {
        workspace.eventSource.on('message', watchInputContextUpdate)
      }

      fetchInputContext()
    }

    return () => {
      if (workspace.eventSource) {
        workspace.eventSource.off('message', watchInputContextUpdate)
      }
    }
  }, [workspace.eventSource, workspace.isActive, operation.input, inputContextType])

  const handlePrepare = useCallback(async () => {
    if (operation.isPreparable) {
      await prepare()
    } else {
      await handleUpdate(params)
    }
  }, [prepare, operation.isPreparable, params, handleUpdate])

  const to = useCallback((key, options) => {
    if (key.startsWith('common:')) {
      return t(key, options)
    }

    return t(`${operationLabel}.${key}`, options)
  }, [operationLabel, t])

  const value = useMemo(() => ({
    preparationContext: preparation?.result,
    t: to
  }), [preparation, to])

  return (
    <Flex vertical gap='middle'>
      {operation.isRequiredByExecution && (
        <Alert showIcon message={t('common:operations.isRequiredByExecution')}/>
      )}

      <Spin spinning={isLoading}>
        {!isInputContextOk && Boolean(operation.status) ? (
          <NoInputContext/>
        ) : (
          <TimelineContext.Provider value={value}>
            <Params
              t={to}
              params={params}
              inputContext={inputContext}
              triggerPreparation={handlePrepare}
              preparationContext={preparation}
            />
          </TimelineContext.Provider>
        )}
      </Spin>
    </Flex>
  )
}

OperationParamsWrapper.propTypes = {
  operation: PropTypes.shape({
    status: PropTypes.string,
    input: PropTypes.string,
    isParamsEditable: PropTypes.bool,
    isRequiredByExecution: PropTypes.bool,
    isPreparable: PropTypes.bool
  }),
  inputType: PropTypes.string,
  OperationParams: PropTypes.func.isRequired,
  prepare: PropTypes.func.isRequired,
  preparation: PropTypes.object,
  operationLabel: PropTypes.string
}

export default OperationParamsWrapper

function getInputContextType(inputType) {
  if (inputType === 'none') {
    return 'none'
  }

  if (inputType === 'stream') {
    return 'preparation'
  }

  return 'execution'
}
