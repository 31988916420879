import {Flex, Space, Spin, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {formatNumber, formatSize} from 'util/file.js'

import ExecutionProgress from 'containers/ui/execution-progress.js'

const {Text} = Typography

function OperationProgress({total, value, unit, step}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.OperationProgress'

  const percent = total ? Math.round(value / total * 100) : 100
  const fileSize = unit === 'bytes' ? formatSize(value) : null

  const status = step === 'finished' ? 'success' : 'active'

  return (
    <Flex vertical gap='small'>
      {step && <Text strong>{t(`${keyPrefix}.step.${step}`)}</Text>}

      {total && (
        <ExecutionProgress percent={percent} status={status} showInfo={Boolean(total)}/>
      )}

      <Space>
        {!total && step !== 'finished' && <Spin size='small'/>}
        {value >= 0 && unit && (
          <Text italic>
            {fileSize ? (
              t(`common:fileSizes.${fileSize.unit}`, {size: fileSize.value})
            ) : (
              t(`${keyPrefix}.progress`, {
                value: formatNumber(value),
                unit: t(`${keyPrefix}.unit.${unit}`, {count: value})
              })
            )}
          </Text>
        )}
      </Space>
    </Flex>
  )
}

OperationProgress.propTypes = {
  step: PropTypes.oneOf([
    'downloading',
    'archiving',
    'preparing',
    'unarchiving',
    'uploading',
    'reading',
    'writing',
    'finished'
  ]),
  unit: PropTypes.oneOf(['rows', 'bytes']),
  value: PropTypes.number,
  total: PropTypes.number
}

export default OperationProgress
