/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import Input from 'containers/form/input.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('writeFile')}
      >
        <Input
          name='fileName'
          label={t('params.fileName')}
          placeholder={t('params.fileNamePlaceholder')}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
