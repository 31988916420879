import {Button} from 'antd'
import styled from 'styled-components'

const HighlightButton = styled(Button)`
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -110%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        transform: skewX(-25deg);
        transition: 1s;
      }
    
      &:hover::after {
        left: ${({disabled}) => disabled ? '-100%' : '110%'};
      }
`

export default HighlightButton
