import {ApiOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'

import PlaceholderMessage from 'components/display/placeholder-message.js'
import Section from 'components/layouts/section.js'

function ProjectIntegrations() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectIntegrations'})

  return (
    <Section
      title={t('sectionTitle')}
      icon={ApiOutlined}
      link={null}
    >
      <PlaceholderMessage
        message={t('noIntegrationPlaceholder')}
        imgSrc='/images/integrations-illustration.svg'
        imgAlt={t('noIntegrationImgAlt')}
      />
    </Section>
  )
}

export default ProjectIntegrations
