import {useContext} from 'react'

import {ContactsOutlined, CrownOutlined, FundProjectionScreenOutlined, TeamOutlined, LikeOutlined} from '@ant-design/icons'
import {Typography, Col, Flex, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {UserContext} from 'contexts/user.js'

import WelcomeCard from 'components/welcome/welcome-card.js'

const {Title} = Typography

const BackgroundContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('/images/waves-light.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${({theme}) => theme.antd.colorWhite};
    flex: 1;
`

const Illustration = styled.img`
    position: absolute;
    width: 80%;
    max-width: 600px;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({theme}) => theme.antd.padding}px;

  .margin-bottom {
    margin-bottom: ${({theme}) => theme.antd.marginXL}px;
  }
`

const GreenText = styled(Title)`
  width: 100%;
  margin: auto;
  text-align: center;

  .anticon {
    margin-right: ${({theme}) => theme.antd.margin}px;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({theme}) => theme.antd.paddingXL}px;
  justify-content: center;
`

const CardWrapper = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({theme}) => theme.antd.margin}px;
`

function RegistrationDone() {
  const {t} = useTranslation('translation', {keyPrefix: 'Welcome'})
  const {user} = useContext(UserContext)
  const welcomeInformations = [
    {
      icon: <ContactsOutlined/>,
      title: t('WelcomeInformations.1.title'),
      description: t('WelcomeInformations.1.description'),
      linkLabel: t('WelcomeInformations.1.linkLabel'),
      link: `/users/${user._id}`
    },
    {
      icon: <FundProjectionScreenOutlined/>,
      title: t('WelcomeInformations.2.title'),
      description: t('WelcomeInformations.2.description'),
      linkLabel: t('WelcomeInformations.2.linkLabel'),
      link: '/projects/new'
    },
    {
      icon: <TeamOutlined/>,
      title: t('WelcomeInformations.3.title'),
      description: t('WelcomeInformations.3.description'),
      linkLabel: t('WelcomeInformations.3.linkLabel'),
      link: ''
    },
    {
      icon: <CrownOutlined/>,
      title: t('WelcomeInformations.4.title'),
      description: t('WelcomeInformations.4.description'),
      linkLabel: t('WelcomeInformations.4.linkLabel'),
      link: ''
    }
  ]

  return (
    <BackgroundContainer>
      <Row>
        <Col xs={24} sm={24} md={24} lg={14}>
          <StyledContainer>
            <GreenText type='success' level={4}>
              <LikeOutlined/>
              {t('inscriptionDone')}
            </GreenText>
            <Title level={3}>{t('welcome')}</Title>
            <Title level={5} type='secondary' className='margin-bottom'>
              {t('subtitle')}
            </Title>
            <CardContainer>
              {welcomeInformations.map(({icon, title, description, linkLabel, link}) => (
                <CardWrapper key={title}>
                  <WelcomeCard
                    icon={icon}
                    title={title}
                    description={description}
                    linkLabel={linkLabel}
                    link={link}
                  />
                </CardWrapper>
              ))}
            </CardContainer>
          </StyledContainer>
        </Col>

        <Col sm={0} md={0} xs={0} lg={10}>
          <Flex vertical style={{height: '100%'}} justify='center'>
            <Flex style={{width: '100%', height: '500px'}} justify='center'>
              <Illustration src='/images/bienvenue-illustration.svg' alt='Bienvenue illustration'/>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </BackgroundContainer>
  )
}

export default RegistrationDone
