import {UserOutlined} from '@ant-design/icons'
import {Avatar, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const {Text} = Typography

const FullNameAvatarStyled = styled(Space)`
  &.left {
    flex-direction: row-reverse;
  }

  &.right {
    flex-direction: row;
  }

  &.top {
    flex-direction: column-reverse;
  }
`
FullNameAvatarStyled.displayName = 'FullNameAvatar'

function FullNameAvatar({fullName, avatar, avatarPosition = 'left'}) {
  return (
    <FullNameAvatarStyled className={avatarPosition}>
      <Text>{fullName}</Text>
      <Avatar
        src={<img src={avatar || '/default-user-avatar.png'} alt='avatar'/>}
        icon={UserOutlined}
      />
    </FullNameAvatarStyled>
  )
}

FullNameAvatar.propTypes = {
  fullName: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  avatarPosition: PropTypes.oneOf(['left', 'right', 'top'])
}

export default FullNameAvatar

