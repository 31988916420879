import React, {useMemo} from 'react'

import {Table, Typography} from 'antd'
import PropTypes from 'prop-types'

const {Title} = Typography

const generateColumnsFromData = data => {
  const uniqueKeys = new Set()

  for (const item of data) {
    for (const key of Object.keys(item.properties)) {
      uniqueKeys.add(key)
    }
  }

  const columns = [...uniqueKeys].map(key => ({
    title: key,
    dataIndex: ['properties', key],
    key
  }))

  return columns
}

function PreviewDataTable({data, title}) {
  const columns = useMemo(() => generateColumnsFromData(data), [data])

  return (
    <>
      {title && <Title level={5}>{title}</Title>}
      <Table
        showHeader
        scroll={{x: 'max-content'}}
        columns={columns}
        dataSource={data}
        rowKey={record => record.properties.id}
        pagination={false}
      />
    </>
  )
}

PreviewDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string
}

export default PreviewDataTable
