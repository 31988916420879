import React from 'react'

import {EditOutlined} from '@ant-design/icons'
import {Button, Typography, Flex, Divider} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SectionWrapper = styled(Flex)`
  background-color: ${({theme}) => theme.token.colorGray70};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingSM}px;

  .divider {
    margin: ${({theme}) => theme.antd.marginXS}px 0;
  }
`

const Title = styled(Typography.Text)`
  width: fit-content;
  margin: 0;
  font-size: ${({theme}) => theme.antd.fontSizeHeading5}px;
  font-weight: ${({theme}) => theme.antd.fontWeightStrong};
`

function SidebarSection({title, placeholderImage, placeholderImageAlt, onEdit, actionButtonLabel, handleAction, children}) {
  return (
    <SectionWrapper vertical gap='middle'>
      {title && (
        <Flex vertical>
          <Flex justify='space-between' align='baseline'>
            <Title>{title}</Title>
            {onEdit && (
              <Button
                shape='square'
                icon={<EditOutlined/>}
                size='small'
                onClick={onEdit}
              />
            )}
          </Flex>

          <Divider className='divider'/>
        </Flex>
      )}

      <Flex vertical gap='small' align={placeholderImage ? 'center' : ''}>
        {children}
        <img width={200} src={placeholderImage} alt={placeholderImageAlt}/>
      </Flex>

      {handleAction && <Button onClick={handleAction}>{actionButtonLabel}</Button>}
    </SectionWrapper>
  )
}

SidebarSection.propTypes = {
  title: PropTypes.string,
  placeholderImage: PropTypes.string,
  placeholderImageAlt: PropTypes.string,
  actionButtonLabel: PropTypes.string,
  onEdit: PropTypes.func,
  handleAction: PropTypes.func,
  children: PropTypes.node
}

export default SidebarSection
