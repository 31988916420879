import {useMemo} from 'react'

import {Descriptions, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import CheckIcon from 'components/ui/check-icon.js'

const {Text, Title} = Typography

function PreparationChecks({result}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Operations.PreparationChecks'})

  const items = useMemo(() => result.checks.map(({name, status, error}) => {
    const isValid = status === 'ok'
    const errorMessage = error || result[name]
    return {
      key: name,
      label: name,
      children: (
        <Flex justify='space-between'>
          <Text>{name}</Text>
          <CheckIcon isValid={isValid} error={errorMessage}/>
        </Flex>
      )
    }
  }), [result])

  return (
    <>
      <Title level={5}>{t('title')}</Title>
      <Descriptions
        bordered
        column={1}
        items={items}
      />
    </>
  )
}

PreparationChecks.propTypes = {
  result: PropTypes.shape({
    checks: PropTypes.array.isRequired
  }).isRequired
}

export default PreparationChecks
