import {createContext, useEffect, useMemo, useState} from 'react'

import PropTypes from 'prop-types'
import {useLocation} from 'react-router-dom'

const PageContext = createContext()

export function PageContextProvider({children}) {
  const [headerNavItems, setHeaderNavItems] = useState([])

  const location = useLocation()

  const value = useMemo(() => ({
    headerNavItems, setHeaderNavItems
  }), [headerNavItems])

  // Reset header nav items when the URL not contains '/project' (to be improved)
  useEffect(() => {
    if (!location.pathname.includes('/project')) {
      setHeaderNavItems([])
    }
  }, [location])

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  )
}

PageContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageContext
