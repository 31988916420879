import {createContext, useCallback, useMemo, useState} from 'react'

import PropTypes from 'prop-types'

const OperationsContext = createContext()

export function OperationsContextProvider({operations, children}) {
  const [selectedOperationId, setSelectedOperationId] = useState(null)

  const handleSelectOperation = useCallback(operationId => {
    setSelectedOperationId(operationId)
  }, [])

  const getNextOperations = useCallback(operationId => operations.filter(({input}) => input === operationId), [operations])

  const value = useMemo(() => ({
    selectedOperationId,
    selectedOperation: operations.find(({_id}) => _id === selectedOperationId),
    handleSelectOperation,
    getNextOperations
  }), [selectedOperationId, operations, handleSelectOperation, getNextOperations])

  return (
    <OperationsContext.Provider value={value}>
      {children}
    </OperationsContext.Provider>
  )
}

OperationsContextProvider.propTypes = {
  operations: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired
}

export default OperationsContext
