import React, {useContext} from 'react'

import {Modal, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import WorkflowContext from 'contexts/workflow-context.js'

function WorkspaceModal() {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkspaceModal'

  const {workspace} = useContext(WorkflowContext)
  const {isModalOpen, closeModal, startWorkspace} = workspace

  const handleOk = () => {
    startWorkspace()
    closeModal()
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Modal
      title={t(`${keyPrefix}.title`)}
      open={isModalOpen}
      cancelText={t('common:button.cancel')}
      okText={t(`${keyPrefix}.okText`)}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Typography.Paragraph>{t(`${keyPrefix}.warning`)}</Typography.Paragraph>
      <Typography.Paragraph>{t(`${keyPrefix}.explanation`)}</Typography.Paragraph>
      <Typography.Paragraph>{t(`${keyPrefix}.question`)}</Typography.Paragraph>
    </Modal>
  )
}

export default WorkspaceModal
