import {FormOutlined} from '@ant-design/icons'

export {Params} from './params.js'

export const type = 'write'
export const label = 'csv'
export const icon = FormOutlined

export const defaultValues = {
  encoding: 'utf8',
  rowSeparator: 'windows',
  separator: 'comma',
  writeHeader: true
}
