import {Menu} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const HeaderMenuWithoutSeparator = styled(Menu)`
    width: ${({theme}) => `calc(100% - ${theme.antd.NavHeader.widthSM})`};
    justify-content: flex-end;
    border-bottom: none;
  }
`

function HeaderMenu({items}) {
  return (
    <HeaderMenuWithoutSeparator
      mode='horizontal'
      selectable={false}
      items={items}
    />
  )
}

HeaderMenu.propTypes = {
  items: PropTypes.array
}

export default HeaderMenu
