import {useState, useCallback, useEffect} from 'react'

import {Divider, Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {removeUnusedFields} from 'util/form.js'

import useExecutor from 'hooks/use-executor.js'

import IllustratedLayout from 'components/layouts/illustrated-layout.js'
import ThirdPartiesRegister from 'components/register/third-parties-register.js'

import Container from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'
import RequestFailed from 'containers/sign-up/request-failed.js'
import RequestSucceeded from 'containers/sign-up/request-succeeded.js'
import SignUpForm from 'containers/sign-up/sign-up-form.js'

const {Text} = Typography

const errorMessages = {
  'unknown-email': 'unknownEmail',
  'authentication-failed': 'authenticationFailed',
  'existing-email': 'existingEmail',
  'unexpected-error': 'unexpectedError',
  'expired-token': 'expiredToken'
}

function SignUp() {
  const location = useLocation()
  const navigate = useNavigate()
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'Register'})

  const [values, setValues] = useState()
  const [errorMessage, setErrorMessage] = useState(null)

  const askRegistration = useCallback(payload => api.askRegistration(payload), [])
  const {data, error, isPending, execute, cleanup} = useExecutor({
    handler: askRegistration
  })

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search)
    const error = queryParam.get('error')

    if (error) {
      setErrorMessage(errorMessages[error] || errorMessages('unexpected-error'))
    }
  }, [location.search])

  const onSubmit = useCallback(values => {
    setValues(values)
    const payload = {
      ...removeUnusedFields(values),
      locale: i18n.resolvedLanguage
    }
    execute(payload)
  }, [i18n.resolvedLanguage, execute])

  const cleanupError = useCallback(() => {
    setErrorMessage(null)
    cleanup()
    navigate(location.pathname)
  }, [navigate, cleanup, location.pathname])

  return (
    <Container>
      <Meta title={t('pageTitle')}/>

      <IllustratedLayout
        title={t('welcome')}
        iconSrc='/living-data-logo.png'
        illustrationSrc='/images/inscription-illustration.svg'
        illustrationAlt={t('illustrationAlt')}
      >
        {(errorMessage || error) ? (
          <RequestFailed error={errorMessage || error.message} onRetry={cleanupError}/>
        ) : (
          data ? (
            <RequestSucceeded/>
          ) : (
            <Flex vertical gap='large'>
              <div>
                <SignUpForm initialValues={values} isLoading={isPending} onSubmit={onSubmit}/>

                <Flex wrap='wrap' justify='center' gap='small'>
                  <Text>{t('loginAccount')}</Text>

                  <Text>
                    <Link to='/sign-in'>{t('loginAccountLink')}</Link>
                  </Text>
                </Flex>
              </div>
              <Divider plain>{t('alternativeRegister')}</Divider>
              <ThirdPartiesRegister/>
            </Flex>
          ))}
      </IllustratedLayout>
    </Container>
  )
}

export default SignUp
