import {useState, useEffect} from 'react'

import {useLocation} from 'react-router-dom'

function useSettingsTab(defaultTab) {
  const [selectedTab, setSelectedTab] = useState(defaultTab)

  const location = useLocation()

  useEffect(() => {
    const tab = location.pathname.split('/').pop()
    setSelectedTab(tab === 'settings' ? defaultTab : tab)
  }, [location, defaultTab])

  return {
    selectedTab,
    setSelectedTab
  }
}

export default useSettingsTab
