/* eslint-disable react/prop-types */
import React from 'react'

import {Modal, theme} from 'antd'

const styles = {
  modal: {
    top: 0,
    padding: `${theme.getDesignToken().padding}px`,
    height: '100vh'
  },
  body: {
    height: 'calc(100vh - 148px)',
    position: 'relative'
  }
}

function FullscreenModal({children, ...modalProps}) {
  return (
    <Modal
      {...modalProps}
      centered
      styles={styles}
      style={styles.modal}
      width='100vw'
    >
      {children}
    </Modal>
  )
}

export default FullscreenModal
