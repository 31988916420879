
import {useContext} from 'react'

import {createGlobalStyle} from 'styled-components'

import {OperationsContextProvider} from 'contexts/operations-context.js'
import ProjectContext from 'contexts/project.js'
import WorkflowContext from 'contexts/workflow-context.js'

import WorkflowHeader from 'components/display/workflows/workflow-header.js'

import OperationsSelect from 'containers/workflow-editor/operations-select.js'
import WorkflowLayout from 'containers/workflow-editor/workflow-layout.js'
import WorkspaceManager from 'containers/workspace-manager.js'

// Override main layout styles to fit the workflow editor
const GlobalStyle = createGlobalStyle`
  body,
  html,
  #root {
    width: 100%;
    height: 100%;
  }

  main {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  footer {
    display: none;
  }
`

function WorkflowEditor() {
  const {isUserCanEdit, project} = useContext(ProjectContext)
  const {workspace, workflow, operations} = useContext(WorkflowContext)

  return (
    <OperationsContextProvider operations={operations}>
      <GlobalStyle/>

      <WorkflowHeader
        projectUrl={`/projects/${project._id}`}
        workflowTitle={workflow.title}
      >
        {isUserCanEdit && (workspace.status === 'active' || workspace.status === 'stopped') && (
          <WorkspaceManager hasControls/>
        )}
      </WorkflowHeader>

      <WorkflowLayout>
        {workspace?.isActive && (
          <OperationsSelect/>
        )}
      </WorkflowLayout>
    </OperationsContextProvider>
  )
}

export default WorkflowEditor
