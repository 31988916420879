/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import FilesSelector from 'containers/form/files-selector.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('fileSelection')}
        checks={['filePath', 'layer', 'schema', 'preview']}
      >
        <FilesSelector
          name='filePath'
          files={inputContext?.files || []}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
