import {useEffect, useMemo, useRef} from 'react'

import {debounce} from 'lodash-es'

const useDebounce = (callback, delay) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, delay)
  }, [delay])

  return debouncedCallback
}

export default useDebounce
