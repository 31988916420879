/* eslint-disable react/prop-types */

import {useMemo} from 'react'

import {FundProjectionScreenOutlined, HomeOutlined, SettingOutlined, TeamOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {getOrganizationSettingsUrl, getOrganizationMembersUrl, getOrganizatioProjectsUrl, getOrganizationUrl} from 'helpers/url.js'

import useTabsNavigation from 'hooks/use-tabs-navigation.js'

import TabsLayout from 'components/layouts/tabs-layout.js'

function OrganizationTabsNavigation({activeTab, children}) {
  const {t} = useTranslation('translation', {keyPrefix: 'OrganizationTabsNavigation'})

  const {organizationId} = useParams()

  const tabs = useMemo(() => {
    const tabs = {
      overview: {icon: HomeOutlined, url: getOrganizationUrl({_id: organizationId})},
      projects: {icon: FundProjectionScreenOutlined, url: getOrganizatioProjectsUrl({_id: organizationId})},
      members: {icon: TeamOutlined, url: getOrganizationMembersUrl({_id: organizationId})},
      settings: {icon: SettingOutlined, url: getOrganizationSettingsUrl({_id: organizationId})}
    }

    return tabs
  }, [organizationId])

  const {tabsItems, onTabChange} = useTabsNavigation({tabs, t})

  return (
    <TabsLayout
      tabs={tabsItems}
      activeTab={activeTab}
      onChangeTab={onTabChange}
    >
      {children}
    </TabsLayout>
  )
}

export default OrganizationTabsNavigation
