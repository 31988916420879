import React from 'react'

import {Layout, Flex} from 'antd'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {getHomeUrl} from 'helpers/url.js'

import HeaderMenu from 'components/layouts/header/header-menu.js'
import LinkLogo from 'components/layouts/header/link-logo.js'

import HeaderNav from 'containers/layout/header-nav.js'

const HeaderStyled = styled(Layout.Header)`
  display: flex;

  .nav {
    width: ${({theme}) => theme.antd.NavHeader.widthLG};
  }

  @media (max-width: ${({theme}) => theme.antd.screenLGMax}px) {
    .nav {
      width: ${({theme}) => theme.antd.NavHeader.widthMD};
    }
  }

  @media (max-width: ${({theme}) => theme.antd.screenMDMax}px) {
    .nav {
      width: ${({theme}) => theme.antd.NavHeader.widthMD};
    }
  }
`
HeaderStyled.displayName = 'Header'

function Header({headerNavItems, menuItems}) {
  return (
    <HeaderStyled>
      <Flex className='nav' align='center' gap='middle'>
        <Link to={getHomeUrl()}>
          <LinkLogo src='/living-data-logo.png'/>
        </Link>
        <HeaderNav
          headerNavItems={headerNavItems}
        />
      </Flex>

      <HeaderMenu items={menuItems}/>
    </HeaderStyled>
  )
}

Header.propTypes = {
  headerNavItems: PropTypes.array.isRequired,
  menuItems: PropTypes.array.isRequired
}

export default React.memo(Header)
