import {useState} from 'react'

import {LoadingOutlined, PlusOutlined} from '@ant-design/icons'
import {Upload, message} from 'antd'
import ImgCrop from 'antd-img-crop'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

const nbCount = 10
const maxSize = nbCount * 1024 * 1024 // 10 MB
const shapes = {
  round: {cropShape: 'round', listType: 'picture-circle'},
  square: {cropShape: 'square', listType: 'picture-card'}
}

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function AvatarUpload({avatar, shape = 'round', uploadAvatar}) {
  const {t} = useTranslation()

  const [messageApi, contextHolder] = message.useMessage()

  const [loading, setLoading] = useState(false)

  const handleChange = ({file}) => {
    if (file.status === 'uploading') {
      setLoading(true)
      return
    }

    if (file.status === 'done') {
      getBase64(file.originFileObj, () => {
        setLoading(false)
      })
    }
  }

  const beforeUpload = file => {
    const validTypes = new Set(['image/jpeg', 'image/png', 'image/webp'])

    if (!validTypes.has(file.type)) {
      message.error('AvatarUpload.Unsupported image format')
      return false
    }

    if (file.size > maxSize) {
      message.error(t('AvatarUpload.request entity too large'))
      return false
    }

    return true
  }

  const handleUpload = async ({file, onSuccess, onProgress, onError}) => {
    onProgress(0)

    try {
      const uploadResult = await uploadAvatar(file)
      onProgress(100)
      onSuccess(uploadResult)
      messageApi.success(t('AvatarUpload.success'))
    } catch (error) {
      onError(error)
      messageApi.error(t('AvatarUpload.fail', {error: t(`AvatarUpload.${error.message}`)}))
    }

    setLoading(false)
  }

  return (
    <div>
      {contextHolder}

      <ImgCrop cropShape={shapes[shape].cropShape}>
        <Upload
          style={{width: '200px', height: '200px'}}
          name='avatar'
          listType={shapes[shape].listType}
          accept='.jpeg,.jpg,.png,.webp'
          maxCount={1}
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={handleUpload}
          onChange={handleChange}
        >
          {loading ? (
            <div>
              <LoadingOutlined/>
              <div style={{marginTop: 8}}>
                {t('AvatarUpload.uploading')}
              </div>
            </div>
          ) : (
            avatar ? (
              <img
                src={avatar}
                alt='avatar'
                width={100}
                height={100}
                style={{borderRadius: shape === 'round' ? '50%' : 0, padding: '10px'}}
              />
            ) : (
              <div>
                <PlusOutlined/>
                <div style={{marginTop: 8}}>
                  {t('AvatarUpload.upload')}
                </div>
              </div>
            ))}

        </Upload>
      </ImgCrop>
    </div>
  )
}

AvatarUpload.propTypes = {
  avatar: PropTypes.string,
  shape: PropTypes.oneOf(['round', 'square']),
  uploadAvatar: PropTypes.func.isRequired
}

export default AvatarUpload
