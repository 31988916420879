/* eslint-disable react/prop-types */

import React, {useCallback} from 'react'

import {LockOutlined} from '@ant-design/icons'
import {Flex, Space, Tag, Tooltip, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {getTimeSince} from 'util/date.js'

import Counters from 'components/ui/fileset-explorer/counters.js'
import FilesetMembers from 'components/ui/fileset-members.js'

const {Text} = Typography

const FilesetCardContainer = styled(Flex)`
  width: 100%;
  padding: ${({theme}) => theme.antd.padding}px;
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  border: 1px solid ${({theme}) => theme.antd.contentBgLight};
  background-color: ${({theme}) => theme.antd.contentBgWhite};
`

const Title = styled(Text)`
  &:hover {
    background-color: ${({theme}) => theme.token.contentBgLight};
    cursor: pointer;
  }
`

const formatParams = {
  date: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'}
}

function FilesetCard({
  type,
  isLocked,
  createdAt,
  updatedAt,
  members,
  counters,
  actions,
  onOpen
}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'FilesetCard'

  const handleOpen = useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
    onOpen()
  }, [onOpen])

  return (
    <FilesetCardContainer
      vertical
      gap='middle'
    >
      <Flex
        justify='space-between'
        align='start'
        wrap='wrap'
        gap='middle'
      >
        <Flex vertical>
          <Space>
            <Title strong underline onClick={handleOpen}>
              {t(`${keyPrefix}.title`,
                {
                  date: new Date(createdAt),
                  formatParams
                })}
            </Title>

            {isLocked && (
              <Tooltip title={t(`${keyPrefix}.locked`)}>
                <Tag icon={<LockOutlined/>} color='orange'/>
              </Tooltip>
            )}
          </Space>

          <Text type='secondary'>
            {t('common:date.updatedSince')}{t('common:date.since', {since: getTimeSince(updatedAt)})}
          </Text>
        </Flex>

        <FilesetMembers
          type={type}
          members={members}
          actions={actions}
        />
      </Flex>

      <Flex justify='space-between' align='center'>
        <Space>
          <Counters {...counters}/>
        </Space>
      </Flex>
    </FilesetCardContainer>
  )
}

export default React.memo(FilesetCard)
