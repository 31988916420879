import {ArrowRightOutlined} from '@ant-design/icons'
import {Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LinkButton from 'containers/ui/button/link-button.js'

const {Text} = Typography

const CardContainer = styled.div`
  ${({theme}) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.antd.padding}px;
    border-radius: ${theme.antd.borderRadius}px;
    box-shadow: ${theme.antd.boxShadow};
    text-align: center;
    margin: auto;
    background-color: ${theme.antd.contentBgLight};
    flex: 1;
  `}
`

const Icon = styled.div`
  font-size: ${({theme}) => theme.token.fontSizeIconLG}px;
`

const Title = styled(Text)`
  font-size: ${({theme}) => theme.antd.fontSizeHeading5}px;
  font-weight: ${({theme}) => theme.antd.fontWeightStrong};
  margin-bottom: ${({theme}) => theme.antd.margin}px;
`

const Description = styled(Text)`
  font-weight: ${({theme}) => theme.antd.fontWeightLight};
  margin-bottom: ${({theme}) => theme.antd.margin}px;
`

function WelcomeCard({icon, title, description, linkLabel, link}) {
  return (
    <CardContainer>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
      <Description type='secondary'>
        {description}
      </Description>
      <LinkButton
        disabled={!link}
        type='primary'
        icon={<ArrowRightOutlined/>}
        href={link}
      >
        {linkLabel}
      </LinkButton>
    </CardContainer>
  )
}

WelcomeCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  link: PropTypes.string
}

export default WelcomeCard
