import {useCallback, useEffect, useState} from 'react'

import {FileOutlined} from '@ant-design/icons'
import {Flex, message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import useEventSource from 'hooks/use-event-source.js'

import PlaceholderMessage from 'components/display/placeholder-message.js'
import Section from 'components/layouts/section.js'
import FilesetViewerModal from 'components/ui/fileset-viewer-modal.js'

import FilesetModal from 'containers/fileset-modal.js'
import FilesetCardController from 'containers/project/fileset-card-controller.js'

export async function projectStorageLoader({params: {projectId}}) {
  const fileLists = await api.getProjectFileLists(projectId)
  return {projectId, fileLists}
}

function FilesContainers() {
  const data = useLoaderData()

  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectFiles'})

  const [fileLists, setFileLists] = useState(data.fileLists)
  const [openedFileList, setOpenedFileList] = useState(null)
  const [filesetInstance, setFilesetInstance] = useState(null)

  const eventSource = useEventSource({url: `/file-lists/${openedFileList?._id}/events`, enabled: Boolean(openedFileList)})

  const [messageApi, contextHolder] = message.useMessage()

  const hasFileLists = fileLists.length > 0

  useEffect(() => {
    if (openedFileList) {
      const fileList = fileLists.find(fileset => fileset._id === openedFileList._id)

      if (fileList.status === 'closed') {
        setOpenedFileList(fileList)
      } else {
        const filesetInstance = api.getFileListInteractiveInstance(fileList._id)
        setFilesetInstance(filesetInstance)
      }
    } else {
      setFilesetInstance(null)
      setOpenedFileList(null)
    }
  }, [openedFileList, fileLists])

  useEffect(() => {
    async function updateFileList(message) {
      if (message.type === 'file-list:closed') {
        const fileLists = await api.getProjectFileLists(data.projectId)
        setFileLists(fileLists)
      }
    }

    if (eventSource) {
      eventSource.on('message', updateFileList)
    }

    return () => {
      if (eventSource) {
        eventSource.off('message', updateFileList)
      }
    }
  }, [eventSource, data.projectId])

  const addFileList = useCallback(async () => {
    try {
      const fileList = await api.createProjectFileList(data.projectId)
      setFileLists([...fileLists, fileList])
      setOpenedFileList(fileList)
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }
  }, [data.projectId, fileLists, messageApi])

  const deleteFileList = useCallback(async filesetId => {
    try {
      await api.deleteFileList(filesetId)
      setFileLists(fileLists.filter(fileset => fileset._id !== filesetId))
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }
  }, [messageApi, fileLists])

  const onCloseFileset = useCallback(async fileListId => {
    await api.closeFileList(fileListId)
    setOpenedFileList(null)
  }, [])

  return (
    <Section
      title={t('sectionTitle')}
      icon={FileOutlined}
      link={null}
      buttonLabel={hasFileLists ? t('addFileListButton') : null}
      onAdd={hasFileLists ? addFileList : null}
    >
      {contextHolder}

      {!hasFileLists && (
        <PlaceholderMessage
          buttonLabel={t('addFileListButton')}
          message={t('noFilePlaceholder')}
          imgSrc='/images/container-illustration.svg'
          imgAlt={t('noFileImgAlt')}
          onAdd={addFileList}
        />
      )}

      <Flex vertical gap='small'>
        {fileLists.map(fileset => (
          <FilesetCardController
            key={fileset._id}
            fileset={fileset}
            onOpen={setOpenedFileList}
            onCloseFileset={onCloseFileset}
            onDelete={deleteFileList}
          />
        ))}
      </Flex>

      {openedFileList && (
        <FilesetViewerModal
          fileset={openedFileList}
          onClose={() => setOpenedFileList(null)}
        />
      )}

      {filesetInstance && (
        <FilesetModal
          fileset={filesetInstance}
          onConfirm={() => onCloseFileset(openedFileList._id)}
          onClose={() => setOpenedFileList(null)}
        />
      )}
    </Section>
  )
}

export default FilesContainers
