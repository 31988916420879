import React, {useMemo} from 'react'

import {ApiOutlined, CrownOutlined, ToolOutlined} from '@ant-design/icons'
import {Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {Outlet, useNavigate} from 'react-router-dom'

import {getAccountSettingsUrl} from 'helpers/url.js'

import useSettingsTab from 'hooks/use-settings-tab.js'

import SettingsLayout from 'components/layouts/settings-layout.js'

function AccountSettingsLayout() {
  const {t} = useTranslation('translation', {keyPrefix: 'Settings'})

  const {selectedTab} = useSettingsTab('general')

  const navigate = useNavigate()

  const menuProps = useMemo(() => {
    const items = [
      {
        key: 'general',
        label: t('tab.general'),
        icon: <ToolOutlined/>
      },
      {
        key: 'plan',
        label: t('tab.plan'),
        icon: <CrownOutlined/>
      },
      {
        key: 'integrations',
        label: t('tab.integrations'),
        icon: <ApiOutlined/>
      }
    ]

    return {
      items,
      onClick: ({key}) => navigate(getAccountSettingsUrl(key)),
      selectedKeys: [selectedTab === 'settings' ? 'general' : selectedTab]
    }
  }, [navigate, selectedTab, t])

  return (
    <SettingsLayout
      menuProps={menuProps}
    >
      <Typography.Title level={2}>{t(`tab.${selectedTab}`)}</Typography.Title>
      <Outlet/>
    </SettingsLayout>
  )
}

export default React.memo(AccountSettingsLayout)
