import {Result} from 'antd'
import PropTypes from 'prop-types'

import RetryButton from 'containers/ui/button/retry-button.js'

function Fail({error, t, onClick}) {
  return (
    <Result
      status='error'
      title={t('failed')}
      subTitle={t(error.message)}
      extra={[
        <RetryButton key='retry' onClick={onClick}/>
      ]}
    />
  )
}

Fail.propTypes = {
  error: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Fail
