import {LockOutlined, UnlockOutlined} from '@ant-design/icons'
import {Tag} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

function VisibilityTag({visibility}) {
  const {t} = useTranslation('common')

  if (visibility === 'private') {
    return (
      <Tag icon={<LockOutlined/>} color='orange'>
        {t('visibility.private')}
      </Tag>
    )
  }

  return (
    <Tag icon={<UnlockOutlined/>} color='blue'>
      {t('visibility.public')}
    </Tag>
  )
}

VisibilityTag.propTypes = {
  visibility: PropTypes.oneOf(['private', 'public']).isRequired
}

export default VisibilityTag
