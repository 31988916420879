import {Result} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import RetryButton from 'containers/ui/button/retry-button.js'

function RequestFailed({error, onRetry}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Register.RequestFailed'})

  return (
    <Result
      status='error'
      title={t('title')}
      subTitle={t(`${error}`)}
      extra={[
        <RetryButton key='retry' onClick={onRetry}/>
      ]}
    />
  )
}

RequestFailed.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }).isRequired,
  onRetry: PropTypes.func.isRequired
}

export default RequestFailed
