import {useState} from 'react'

import {Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import DropdownWrapper from 'components/display/dropdown-wrapper.js'

import OperationProgress from 'containers/workflow-editor/operation-side/operation-progress.js'

const {Text} = Typography

function ExecutionProgressDropdown({progress}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.OperationSider.OperationResult'})

  const [isOpen, setIsOpen] = useState(true)

  return (
    <DropdownWrapper
      title={(
        <Flex align='center' justify='space-between'>
          <Text>{t('executionProgress')}</Text>
        </Flex>
      )}
      isOpen={isOpen}
      toggleOpen={() => setIsOpen(!isOpen)}
    >
      <OperationProgress {...progress}/>
    </DropdownWrapper>
  )
}

ExecutionProgressDropdown.propTypes = {
  progress: PropTypes.object,
  isFinished: PropTypes.bool
}

export default ExecutionProgressDropdown
