import React from 'react'

import {EllipsisOutlined, SettingOutlined, TeamOutlined} from '@ant-design/icons'
import {Avatar, Button, Dropdown, Flex, List, Space, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import api from 'services/api/index.js'

import {getOrganizationSettingsUrl, getOrganizationUrl} from 'helpers/url.js'

import UsersAvatarGroup from 'components/display/users-avatar-group.js'

const {Text} = Typography

function OrganizationListItem({organization, userId}) {
  const {t} = useTranslation('common')
  const avatar = api.getAvatarURL(organization)
  const isUserMember = organization.members.some(({_id}) => userId === _id)

  const items = [{
    key: 'settings',
    icon: <SettingOutlined/>,
    label: (
      <Link to={getOrganizationSettingsUrl(organization)}>
        {t('settings')}
      </Link>
    )
  }]

  return (
    <List.Item>
      <Flex flex={1} align='center' justify='space-between'>
        <Space>
          <Avatar
            className='avatar'
            src={avatar}
            icon={avatar ? null : <TeamOutlined/>}
          />
          <Link to={getOrganizationUrl(organization)}>
            <Text strong>{organization.displayName}</Text>
          </Link>
        </Space>

        <Space>
          <UsersAvatarGroup profiles={organization.members}/>
          {isUserMember && (
            <Dropdown menu={{items}} trigger='click'>
              <Button icon={<EllipsisOutlined/>}/>
            </Dropdown>
          )}
        </Space>

      </Flex>
    </List.Item>
  )
}

OrganizationListItem.propTypes = {
  organization: PropTypes.object.isRequired,
  userId: PropTypes.string
}

export default React.memo(OrganizationListItem)

