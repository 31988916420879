export function buildTree(files = []) {
  const root = []

  for (const file of files) {
    const parts = file.path.split('/')

    // Initialize 'currentLevel' to point to the root of the tree.
    let currentLevel = root

    // Iterate over each part of the path.
    for (const [index, part] of parts.filter(Boolean).entries()) {
      // Search for an existing path with the same title at the current level.
      let existingPath = currentLevel.find(p => p.title === part)

      // If the path part does not exist at the current level, create it.
      if (!existingPath) {
        const path = parts.slice(0, index + 1).join('/')
        const isLeaf = index === parts.length - 1

        existingPath = {
          title: part,
          key: path,
          path,
          // If this is the last part of the path, mark it as a leaf;
          // otherwise, initialize an empty array for children.
          ...file,
          ...(isLeaf
            ? { // File
              isLeaf: true
            }
            : { // Directory
              children: [],
              fullPath: path + '/'
            })
        }

        currentLevel.push(existingPath)
      }

      // If the current path is not a leaf, move to its children in the next iteration.
      if (!existingPath.isLeaf) {
        currentLevel = existingPath.children
      }
    }
  }

  return root
}

function getUploadedSize(file) {
  if (file.status === 'uploaded') {
    return file.size
  }

  if (file.status === 'uploading') {
    return file.size * (file.progress / 100)
  }

  return 0
}

// Helper function to update fileCount and totalSize for all nodes
function updateCounts(node) {
  if (node.children) {
    for (const child of node.children) {
      updateCounts(child)
    }

    node.uploadingSize = node.children.reduce((acc, child) => acc + (child.uploadingSize || 0) + getUploadedSize(child), 0)
    node.totalSize = node.children.reduce((acc, child) => acc + (child.totalSize || 0) + (child.isLeaf ? child.size : 0), 0)
  }
}

export function filesetToTree(files = []) {
  const tree = buildTree(
    files.map(file => ({
      ...file,
      path: file.path || file.fullPath
    })
    )
  )

  for (const node of tree) {
    updateCounts(node)
  }

  return tree
}

export function getAllPaths(tree) {
  const paths = []

  function extractPaths(items) {
    for (const item of items) {
      if (item.path) {
        paths.push(item.path)
      }

      if (item.children && item.children.length > 0) {
        extractPaths(item.children)
      }
    }
  }

  extractPaths(tree)
  return paths
}

export function getFailedUploadPaths(tree) {
  const failedPaths = []

  function extractFailedPaths(items) {
    for (const item of items) {
      if (item.status === 'failed') {
        failedPaths.push(item.fullPath)
      }

      if (item.children && item.children.length > 0) {
        extractFailedPaths(item.children)
      }
    }
  }

  extractFailedPaths(tree)
  return failedPaths
}
