import {CrownOutlined} from '@ant-design/icons'
import {Button, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import PlanBadge from 'components/ui/plan-badge.js'

import Container from 'containers/layout/container.js'

const {Title, Text, Paragraph} = Typography

const CenteredDiv = styled.div`
  margin: 0 auto;
  text-align: center;
`

const LightParagraph = styled(Paragraph)`
  font-weight: ${({theme}) => theme.antd.fontWeightLight};
  line-height: ${({theme}) => theme.antd.lineHeightLG};
  margin-top: ${({theme}) => theme.antd.margin}px;
`

function BasicPlan() {
  const {t} = useTranslation('translation', {keyPrefix: 'SuccessfullRegistration.planCard'})

  return (
    <Container>
      <PlanBadge plan='Basic'/>
      <Title level={5} align='center' style={{marginTop: 0}}>
        {t('title')}
      </Title>

      <div style={{margin: 'auto'}}>
        <CenteredDiv>
          <Text strong>
            {t('advantages')}
          </Text>
        </CenteredDiv>
        <LightParagraph>
          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Consectetur adipiscing elit Lorem ipsum dolor sit amet</li>
            <li>Sed do eiusmod tempor incididunt</li>
            <li>Sed do eiusmod tempor incididunt</li>
          </ul>
        </LightParagraph>
      </div>
      <div style={{textAlign: 'center'}}>
        <Button icon={<CrownOutlined/>}>
          {t('compare')}
        </Button>
      </div>
    </Container>
  )
}

export default BasicPlan
