import React from 'react'

import {ApartmentOutlined} from '@ant-design/icons'
import {Space} from 'antd'
import PropTypes from 'prop-types'

import WorkspaceState from 'containers/workspaces-manager/workspace-state.js'

function WorkspaceLeftButton({leftButton, hasControls, workspace, t}) {
  // Render a button to start a new workspace
  if (!workspace._id || !hasControls) {
    return React.cloneElement(leftButton, {
      children: (
        <Space>
          <ApartmentOutlined/>
          {t('Project.WorkspaceManager.startWorkspace')}
        </Space>
      ),
      onClick: async () => workspace.startWorkspace()
    })
  }

  return React.cloneElement(leftButton, {
    children: (
      <WorkspaceState
        workspaceId={workspace?._id}
        eventSource={workspace?.eventSource}
        t={t}
      />
    )
  }
  )
}

WorkspaceLeftButton.propTypes = {
  leftButton: PropTypes.object,
  workspace: PropTypes.object,
  t: PropTypes.func
}

export default WorkspaceLeftButton
