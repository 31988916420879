import {Result} from 'antd'
import {useTranslation} from 'react-i18next'

function RequestSucceeded() {
  const {t} = useTranslation('translation', {keyPrefix: 'Register.RequestSucceeded'})

  return (
    <Result
      status='success'
      title={t('title')}
      subTitle={t('subtitle')}
    />
  )
}

export default RequestSucceeded
