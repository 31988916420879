import {useCallback, useContext, useState} from 'react'

import {Flex} from 'antd'
import {orderBy} from 'lodash-es'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import api from 'services/api/index.js'

import WorkflowContext from 'contexts/workflow-context.js'

import WorkspaceModal from 'components/display/workflows/workspace-modal.js'

import WorkspaceSelector from 'containers/workspaces-manager/workspace-selector.js'

import WorkspaceControls from './workspaces-manager/workspace-controls.js'

const NO_WORKSPACE_ITEM_KEY = 'noCurrentWorkspace'

function WorkspaceManager({hasControls}) {
  const {t} = useTranslation(['translation', 'common'])

  const {workflow, workspace, setCurrentWorkspaceId} = useContext(WorkflowContext)
  const [workspaces, setWorkspaces] = useState([])
  const [isWorkspacesLoading, setIsWorkspacesLoading] = useState(false)
  const [error, setError] = useState(null)

  const getWorkflowWorkspaces = useCallback(async () => {
    setIsWorkspacesLoading(true)
    setError(null)

    try {
      const workspaces = await api.getWorkflowWorkspaces(workflow._id)
      const orderedWorkspaces = orderBy(workspaces, ['createdAt'], ['desc'])
      setWorkspaces(orderedWorkspaces)
    } catch (error) {
      setError(error)
    }

    setIsWorkspacesLoading(false)
  }, [workflow])

  const handleSelectWorkspace = workspaceId => {
    if (workspaceId === NO_WORKSPACE_ITEM_KEY) {
      setCurrentWorkspaceId(null)
    } else {
      setCurrentWorkspaceId(workspaceId)
    }
  }

  return (
    <>
      <Flex justify='center' gap='small'>
        {hasControls && <WorkspaceControls/>}

        <WorkspaceSelector
          workspaces={workspaces}
          isWorkspacesLoading={isWorkspacesLoading}
          workspace={workspace}
          error={error}
          getWorkflowWorkspaces={getWorkflowWorkspaces}
          handleSelectWorkspace={handleSelectWorkspace}
          hasControls={hasControls}
          t={t}
        />
      </Flex>

      <WorkspaceModal/>
    </>
  )
}

WorkspaceManager.propTypes = {
  hasControls: PropTypes.bool
}

export default WorkspaceManager
