import {useState, useEffect} from 'react'

import {Form} from 'antd'

const useIsValidForm = () => {
  const [form] = Form.useForm()

  const [isValid, setIsValid] = useState(false)

  const values = Form.useWatch([], form)

  useEffect(() => {
    form.validateFields({validateOnly: true}).then(
      () => {
        setIsValid(true)
      },
      () => {
        setIsValid(false)
      }
    )
  }, [form, values])

  return [form, isValid]
}

export default useIsValidForm
