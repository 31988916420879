/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import FilesSelector from 'containers/form/files-selector.js'
import PasswordInput from 'containers/form/password-input.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('archiveSelection')}
        checks={['archivePath', 'archiveFormat']}
      >
        <FilesSelector
          name='archivePath'
          files={inputContext?.files || []}
        />
      </ParamsStep>

      <ParamsStep
        title={t('security')}
      >
        <PasswordInput
          name='password'
          label={t('common:form.password')}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
